import React, { } from 'react'
import Modal from "@mui/material/Modal"


function Loader(props) {
    return (
        <div>
     <Modal
       open={props.loaderShow}
       onClose={props.loaderHide}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
       className='modal-dialog-centered'

    >
    <>
    <div className="loader2">
        <div className="outer"></div>
        <div className="middle"></div>
        <div className="inner"></div>
    </div>
    </>
     </Modal>
   </div>
    )
}

export default Loader