import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { RiFacebookLine, RiGoogleLine, RiLinkedinLine } from "react-icons/ri";
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
// import 'antd-country-phone-input/dist/index.css';
import axios from '../helper/Api';
import { toast } from "react-toastify";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import OtpTimer from 'otp-timer'
import moment from 'moment'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";

// import 'antd-country-phone-input/dist/index.css';
import { Input, Form, Radio, DatePicker } from 'antd';
import { createCookieInHour, COOKIE_NAME } from '../helper/Cookies';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    padding: '30px',
};

function UserAccountCreact() {
    const [form] = Form.useForm();
    const [value, setValue] = useState({ short: 'BD' });
    const [genderValue, setGenderValue] = useState(1);
    const [country, setCountry] = useState([]);
    const [countryId, setCountryId] = useState([]);
    const [fromValue, setFromValue] = useState([]);
    const [otp, setOtp] = useState([]);
    const [otpTime, setOtpTime] = useState(1);
    const [open, setOpen] = useState(false);
    const [otpTimeModal, setOtpTimeModal] = useState(false);
    const [buttonSubmit, setButtonSubmit] = useState(true);
    const [dathOfBath, setDathOfBath] = useState([]);
    const redirect = useNavigate();
    const handleClose = () => {
        setOpen(false)
        setOtpTime(0)
    }

    // Country List 
    useEffect(() => {
        axios.get("/common/country/active/all")
            .then(res => {
                setCountry(res.data.data.countries)
            })
    }, [])

    console.log(buttonSubmit)

    const [state, setState] = useState({
        country_id: [],
        phone_number: '',
        password: '',
    })
console.log(countryId)
    console.log(otpTime)
    const otpSubmit = () => {
        axios.post(`/customer/verify-otp?phone_number=${countryId.phone_number}&otp=${otp}`)
            .then((res) => {
                const obj = {
                    country_id: countryId.country_id,
                    phone_number: countryId.phone_number,
                    password: fromValue?.password,
                    gender: fromValue?.gender,
                    dob: dathOfBath,
                    first_name: fromValue?.first_name,
                    middle_name: fromValue?.middel_name,
                    last_name: fromValue?.last_name,
                }
                axios.post('/customer/create-account', obj )
                    .then((res) => {
                        console.log(res, "9999999")
                        if(res.data.code === 200){
                            toast.success(res.data.message || "Your Account Created Successfully. Please Log in" , {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            redirect("/login")
                        } 
                        if(res.data.code !== 200){
                            toast.error(res.data.message || "Something went wrong", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        } 
                        
                    }).catch(err => {
                        // console.log(err.res.data)
                        toast.error("Login Credentials Invalid", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    })
            }).catch(err => {
                // console.log(err.res.data)
                toast.error("Login Credentials Invalid", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            })
    }
    const onChange = (e) => {
        setGenderValue(e.target.value);
    };

    const handleChange = (otp) => {
        setOtp(otp)
    };
    const modalOpen = (value) => {
        console.log(value)
        setFromValue(value)
        setOpen(true)
        setOtpTimeModal(true)
        // SetTime Out
        setInterval(() => {
            setButtonSubmit(false)
        }, 120000);
        // Otp send User 
        axios.post(`/customer/generate-otp?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}`)
            .then((res) => {
                console.log(res)

            }).catch(err => {

            })

    };
    const submit = (e) => {
        console.log(e)
        setOtpTime(e)
        setButtonSubmit(true)
        setInterval(() => {
            setButtonSubmit(false)
        }, 120000);
        axios.post(`/customer/generate-otp?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}`)
            .then((res) => {
                console.log(res)
            }).catch(err => {

            })
    };
    const dateTimeFunction = (date) => {
        setDathOfBath(moment(date?._d).format('YYYY-MM-DD'))
    }
    console.log(value)
    return (
        <div className='login_area creact__account__user__BOx'>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <h3 className='account_otp_hdr__txt'> Enter OTP Code </h3>
                    <div className='account__otp_area'>
                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            placeholder="*"
                            numInputs={6}
                            separator={<span>-</span>}
                        />
                    </div>
                    {otpTimeModal === true ? <>
                        <div className='otp__Timer__resend_button'>
                            <OtpTimer
                                minutes={1}
                                seconds={60}
                                text="Time:"
                                ButtonText="Resend"
                                resend={(e) => submit(e)}
                            />
                        </div>
                    </> : <> </>}
                    {buttonSubmit === true ? <>
                        <div className='otp__acc_otp__btn'>
                            <button onClick={handleClose} className='acc_otp__btn'> Cancel </button>
                            <button onClick={() => otpSubmit()} style={{ marginRight: '0px', background: "#52c41a" }} className='acc_otp__btn'> Submit </button>
                        </div>
                    </> : <> </>}
                </Box>
            </Modal>
            <div className="container" id="container">
                
                <div style={{ width: '100%' }} className="form-container sign-in-container">
                    <Form
                        name="basic"
                        layout="vertical"
                        className='from_home_area'
                        form={form}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={modalOpen}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <h1 className='header__ac__name'>Account Creact</h1>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            max: 40,
                                            message: "Value should be less than 40 character",
                                        },
                                        {
                                            pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                            message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                        },
                                        {
                                            required: true,
                                            message: 'Please input First Name!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-3'>
                                <Form.Item
                                    label="Middel Name"
                                    name="middel_name"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            max: 40,
                                            message: "Value should be less than 40 character",
                                        },
                                        {
                                            pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                            message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                        },
                                        {
                                            required: true,
                                            message: 'Please input Last Name!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            max: 40,
                                            message: "Value should be less than 40 character",
                                        },
                                        {
                                            pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                            message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                        },
                                        {
                                            required: true,
                                            message: 'Please input User Name!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Contact No"
                                    name="phone_number"
                                    maxLength={11}
                                    className="form-group form-box"
                                >
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput
                                            value={value}
                                            onChange={(v) => {
                                                setValue(v);
                                                const countryId = country?.find(item => item?.iso === value?.short)
                                                console.log(countryId)
                                                setCountryId({ ...state, country_id: countryId?.id, phone_number: v?.phone })
                                            }} />
                                    </ConfigProvider>
                                </Form.Item>
                            </div>
                            <div className='col-lg-3'>
                                <Form.Item
                                    label="Dath of Bath"
                                    name="date_time"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input User Name!',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{padding: "0px 15px", width: '100%'}} onChange={dateTimeFunction} />
                                </Form.Item>
                            </div>
                            
                            <div className='col-lg-3'>
                                <Form.Item
                                    label="Gender"
                                    name="gender"
                                    style={{ float: 'left', border: 'none' }}
                                    className="form-group form-box form-box2 "
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Gender!',
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={onChange} value={genderValue}>
                                        <Radio value={1}>Male</Radio>
                                        <Radio value={2}>Female</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    className="form-group form-box"
                                    maxLength='5'
                                    rules={[
                                        {
                                            max: 15,
                                            message: "Please input max 15 digit",
                                        },
                                        {
                                            min: 6,
                                            message: "Please input 6 digit",
                                        },
                                        {
                                            pattern: /(?=.*[a-z])/,
                                            message: "Please input one small letter"
                                        },
                                        {
                                            pattern: /(?=.*[A-Z])/,
                                            message: "Please input one capital"
                                        },
                                        {
                                            pattern: /(?=.*\d)/,
                                            message: "Please input one number"
                                        },
                                        {
                                            pattern: /[a-zA-Z\d]+/,
                                            message: "Only letters and digits allowed "
                                        },
                                        {
                                            pattern: /(?=.*?[#?!@$%^&*-])/,
                                            message: 'At least one special character!',
                                        },
                                        {
                                            validator: (_, value) =>
                                                // Two Space
                                                !value.includes(" ")
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error("No spaces allowed"))
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password style={{ padding: "0px 15px", border: "none" }} className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    name="confirm_password"
                                    label="Confirm Password"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password style={{ padding: "0px 15px", border: "none" }} />
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn-md btn-theme regBTn">Submit</button>
                                <p className='from__bottom_txt'>Don't have an account? <Link to="/login" className="thembo"> Login here</Link></p>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default UserAccountCreact