import React, { useState, useEffect } from 'react'
import axios from '../../helper/Api';
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
import { Select, Form, Input, Modal } from 'antd';
import { getCookie } from '../../helper/Cookies';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    padding: '30px',
};

function AddresChanges() {
    const [open, setOpen] = useState(false);
    let usertoken = getCookie("token");
    const [divisionName, setDivisionName] = useState([]);
    const [divisionId, setDivisionId] = useState([]);
    const [districtName, setDistrictName] = useState([]);
    const [districtId, setDistrictId] = useState([]);
    const [thanaName, setThanaName] = useState([]);
    const [ThanaId, setThanaId] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const { Option } = Select;
    const country_id = Number(localStorage.getItem("country_id"))
    const handleClose = () => {
        setOpen(false)
    }

    const addressChangeFunction = () => {
        setOpen(true)
    }
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Division Function
    const divisionFunction = (e) => {
        const obj = {
            country_id: country_id
        }
        axios.post('/division/active/all', obj )
        .then((res) => {
            console.log(res)
            setDivisionName(res?.data?.data?.divisions)
        }).catch(err => {

        })
    }
    const divisionOnChangeFunction = (e) => {
        setDivisionId(e)
    }

    // District Function
    const districtFunction = () => {
        const obj = {
            division_id: divisionId
        }
        axios.post('/district/active/all', obj )
        .then((res) => {
            console.log(res)
            setDistrictName(res?.data?.data?.districts)
        }).catch(err => {

        })
    }

    const districtOnChangeFunction = (e) => {
        setDistrictId(e)
    }
    // districtId
    const thanaFunction = () => {
        const obj = {
            district_id: districtId
        }
        axios.post('/thana/active/all', obj )
        .then((res) => {
            console.log(res)
            setThanaName(res?.data?.data?.thanas)
        }).catch(err => {

        })
    }
    const thanaOnChangeFunction = (e) => {
        setThanaId(e)
    }
    const addressSubmit = (value) => {
        console.log(value)
        axios.post(`/customer/update-address?division_id=${divisionId}&district_id=${districtId}&thana_id=${ThanaId}&post_code=${value?.post_code}&detail_address=${value?.detail_address}`,{ headers: { 'Authorization': `bearer ${usertoken}` } })
        .then((res) => {
            console.log(res)
            if (res.data.code === 200) {
                toast.success(res?.data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                setIsModalOpen(false)
            }
            if (res.data.code !== 200) {
                toast.error(res?.data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                setIsModalOpen(false)
            }
            // setThanaName(res?.data?.data?.thanas)
        }).catch(err => {

        })
    }
    console.log(usertoken)
    return (
        <div>
            <p style={{ color: '#f00', fontWeight: 600, cursor: "pointer" }} onClick={showModal}>Click Here </p>
            <Modal className='address__change__from__area' title="Address From" visible={isModalOpen} onCancel={handleCancel}>
                {/* <Box sx={style}> */}
                <Form
                    name="basic"
                    layout="vertical"
                    className='from_home_area'
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={addressSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className='row'>

                        <div className='col-lg-6'>
                            <Form.Item
                                label="Division"
                                name="division_id"
                                className="form-group form-box"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Select className='select__width' onClick={(e) => divisionFunction(e)} onChange={(e) => divisionOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                    {divisionName?.map((item, i) =>
                                        <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className='col-lg-6'>
                            <Form.Item
                                label="District"
                                name="district_id"
                                className="form-group form-box"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Select className='select__width' onClick={() => districtFunction()} onChange={(e) => districtOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                    {districtName?.map((item, i) =>
                                        <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                    )}
                                </Select>
                                
                            </Form.Item>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Item
                                label="Thana"
                                name="thana_id"
                                className="form-group form-box"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Select className='select__width' onClick={() => thanaFunction()} onChange={(e) => thanaOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                    {thanaName?.map((item, i) =>
                                        <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Item
                                label="Post Code"
                                name="post_code"
                                className="form-group form-box"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Input className="form-control" />
                            </Form.Item>
                        </div>
                        <div className='col-lg-12'>
                            <Form.Item
                                label="Detail Address"
                                name="detail_address"
                                className="form-group form-box"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Input className="form-control" />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn-md btn-theme address_change_btn">Submit</button>
                            {/* <p className='from__bottom_txt'>Don't have an account? <Link to="/login" className="thembo"> Login here</Link></p> */}
                        </div>
                    </div>
                </Form>
                {/* </Box> */}
            </Modal>
        </div>
    )
}

export default AddresChanges