import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { Row, Col, Tooltip } from 'react-bootstrap'
import Tooltip from "@material-ui/core/Tooltip";
// import {
//     UncontrolledTooltip,
// } from 'reactstrap'
import { RiFacebookLine, RiGoogleLine, RiLinkedinLine } from "react-icons/ri";
import { VscAdd, VscEdit } from "react-icons/vsc";
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
// import 'antd-country-phone-input/dist/index.css';
import axios from '../../helper/Api';
import { toast } from "react-toastify";
import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import OtpTimer from 'otp-timer'
import moment from 'moment'
import OtpInput from 'react-otp-input';

// import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
// import en from 'world_countries_lists/data/countries/en/world.json';

// import 'antd-country-phone-input/dist/index.css';
import { Modal, Form, Input } from 'antd';
import { getCookie } from '../../helper/Cookies';

function ForGotPassword() {
    const [form] = Form.useForm();
    let usertoken = getCookie("token");
    const [count, setCount] = useState(0);
    // Country
    const [country, setCountry] = useState([]);
    const [value, setValue] = useState({ short: 'BD' });
    const [countryId, setCountryId] = useState([]);

    // Phone
    const [phoneOtp, setPhoneOtpSet] = useState([]);
    const [modalTimerOff, setModalTimerOff] = useState(true);
    const [otpTimeModal, setOtpTimeModal] = useState(false);
    const [otpBoxShow, setOtpBoxShow] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(false);

    // modal open
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const [otpVeryfyBox, setOtpVeryfyBox] = useState(false);
    const [formBox, setFormBox] = useState(false);

    const redirect = useNavigate();

    const modalOpen = () => {
        setIsModalOpen(true)
        setButtonSubmit(true)
        // OTP SEND
        axios.post(`/customer/forgot-password-otp?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}`)
            .then((res) => {
                if (res.data.code === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setOtpTimeModal(true)
                    setOtpVeryfyBox(true)
                }
                if (res.data.code !== 200) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setIsModalOpen(false)
                }
            }).catch(err => {

            })
    }
    const modalCancel = () => {
        setIsModalOpen(false)
    }
    useEffect(() => {
        // Country List
        axios.get("/common/country/active/all")
            .then(res => {
                setCountry(res.data.data.countries)
            })
    }, []);

    const phoneOtpSetFunction = (otp) => {
        setPhoneOtpSet(otp)
    };

    const phoneOtpResend = (e) => {
        setButtonSubmit(true)
        setModalTimerOff(true)
        setInterval(() => {
            setButtonSubmit(false)
            setSubmitBtn(true)
        }, 120000);
        // axios.post(`/customer/generate-otp?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}`)
        //     .then((res) => {
        //         console.log(res)
        //     }).catch(err => {

        //     })
    };


    const otpSubmit = () => {
        // setOtpVeryfyBox(true)
        // setOtpTimeModal(true)
        axios.post(`/customer/forgot-password-otp-verify?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}&otp=${phoneOtp}`)
            .then((res) => {
                if (res.data.code === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setIsModalOpen(false)
                    // setOtpTimeModal(true)
                    // setOtpVeryfyBox(true)
                }
                if (res.data.code !== 200) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }).catch(err => {

            })
    }

    console.log(countryId)
    return (
        <div className='login_area creact__account__user__BOx'>

            <div className="container" id="container">
                {/* PHONE MODAL  */}
                <Modal className='euser_profile_modal_area' visible={isModalOpen} onCancel={modalCancel} >
                    <h3 className='account_otp_hdr__txt'> Number Change OTP Code </h3>

                    {otpVeryfyBox === true ? <>
                            <div className='otp__box__area'>
                                <div className='account__otp_area'>
                                    <OtpInput
                                        value={phoneOtp}
                                        onChange={phoneOtpSetFunction}
                                        numInputs={6}
                                        separator={<span>-</span>}
                                    />
                                </div>
                                {otpTimeModal === true ? <>
                                    <div className='otp__Timer__resend_button'>
                                        <OtpTimer
                                            minutes={1}
                                            seconds={60}
                                            text="Time:"
                                            ButtonText="Resend"
                                            resend={(e) => phoneOtpResend(e)}
                                        />
                                    </div>
                                </> : <> </>}
                                {buttonSubmit === true ? <>
                                    <div className='otp__acc_otp__btn'>
                                        <button className='acc_otp__btn'> Cancel </button>
                                        <button onClick={otpSubmit} style={{ marginRight: '0px', background: "#52c41a" }} className='acc_otp__btn'> Submit </button>
                                    </div>
                                </> : <> </>}
                            </div>
                        </> : <>

                        </>}

                </Modal>
                <div style={{ width: '100%' }} className="form-container sign-in-container">
                    <Form
                        name="basic"
                        layout="vertical"
                        className='from_home_area'
                    >
                        <h1 className='header__ac__name'>Forgot Password</h1>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <Form.Item
                                    label="Phone Number"
                                    name="phone_number"
                                    maxLength={11}
                                    className="form-group form-box"
                                >
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput
                                            value={value}
                                            onChange={(v) => {
                                                setValue(v);
                                                let phoneno = /^\d{10}$/;
                                                if (v.phone.match(phoneno)) {
                                                    setSubmitBtn(true)
                                                } else {
                                                    setSubmitBtn(false)
                                                }
                                                console.log(v.phone_number?.count())
                                                const countryId = country?.find(item => item?.iso === value?.short)
                                                setCountryId({ country_id: countryId?.id, phone_number: v?.phone })
                                            }} />

                                    </ConfigProvider>
                                </Form.Item>
                            </div>
                            {submitBtn === true ? <>
                                <div className="form-group">
                                    <button type="submit" onClick={modalOpen} className="btn-md btn-theme regBTn">Submit</button>
                                </div>
                            </> : <>
                                <div className="form-group">
                                    <button style={{ background: '#2222', color: '#222', border: 'none' }} className="btn-md btn-theme regBTn">Submit</button>
                                </div>
                            </>}
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ForGotPassword