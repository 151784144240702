import axios from 'axios';
// const BASE_URL = 'http://103.231.238.45/eonecmsbackendv1-dev/public/api';
const BASE_URL = 'http://e-onebd.com/eonecmsbackendv1-dev/public/api';
// const BASE_URL = 'http://localhost/eonecmsbackendv1/public/api';
export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL
});