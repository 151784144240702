import React from 'react'
import { Tabs } from 'antd';
import SellerHomeContant from "./SellerHomeContant"
import SellerFlashSeal from "./SellerFlashSeal"
import JustForYou from "../../Components/SellerComponents/JustForYou"
import SellerAllProduct from "../../Components/SellerComponents/SellerAllProduct"
const { TabPane } = Tabs;
function SellerTab(props) {
    console.log(props, "props 11111")

    return (
        <div className='seller_body_width'>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Home Pages" key="1">
                    <SellerHomeContant />
                    <SellerFlashSeal />
                    <JustForYou sellerData={props?.sellerData} />
                </TabPane>
                <TabPane tab="All Products" key="2">
                    <SellerAllProduct />
                </TabPane>
                {/* <TabPane tab="Profile" key="3">
                    Content of Tab Pane 3
                </TabPane> */}
            </Tabs>
        </div>
    )
}

export default SellerTab