import React from 'react'
import { Modal, Form, Input } from 'antd';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import axios from '../helper/Api';
function PasswordChange() {
    const [form] = Form.useForm();
    const redirect = useNavigate();
    
    const phoneDataSubmit = (value) => {
        console.log(value, "value value")
        axios.post(`/customer/change-password?currentPassword=${value.currentPassword}&newPassword=${value.confirm_password}&confirmPassword=${value.password}`)
            .then((res) => {
                console.log(res)
                if (res.data.code === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    redirect("/")
                }
                if (res.data.code !== 200) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }).catch(err => {

            })
    }

    return (
        <div className='forget___area'>
            <Form
                name="basic"
                layout="vertical"
                className='from_home_area'
                form={form}
                onFinish={phoneDataSubmit}
                initialValues={{
                    remember: true,
                }}
                // onFinish={profileUpdate}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className='row'>
                    <div className='col-lg-12'>
                        <Form.Item
                            label="Current Password"
                            name="currentPassword"
                            className="form-group form-box"
                            maxLength='5'
                            rules={[
                                {
                                    max: 15,
                                    message: "Please input max 15 digit",
                                },
                                {
                                    min: 6,
                                    message: "Please input 6 digit",
                                },
                                {
                                    validator: (_, value) =>
                                        // Two Space
                                        !value.includes(" ")
                                            ? Promise.resolve()
                                            : Promise.reject(new Error("No spaces allowed"))
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password className="form-control" />
                        </Form.Item>
                    </div>

                    <div className='col-lg-12'>
                        <Form.Item
                            name="password"
                            label="Password"
                            className="form-group form-box"
                            maxLength='5'
                            rules={[
                                {
                                    max: 15,
                                    message: "Please input max 15 digit",
                                },
                                {
                                    min: 6,
                                    message: "Please input 6 digit",
                                },
                                {
                                    validator: (_, value) =>
                                        // Two Space
                                        !value.includes(" ")
                                            ? Promise.resolve()
                                            : Promise.reject(new Error("No spaces allowed"))
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password className="form-control" />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item
                            name="confirm_password"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </div>

                    <div className="form-group" style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <button type="submit" style={{ marginRight: '0px', marginTop: '0px', background: "#52c41a" }} className='acc_otp__btn'>Submit</button>
                    </div>

                </div>
            </Form>
        </div>
    )
}

export default PasswordChange