import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Input } from 'antd';
import { VscHome, VscListFilter, VscAccount, VscHeart, VscDashboard } from "react-icons/vsc";
import { TiSocialFacebook, TiSocialLinkedin, TiSocialTwitter, TiSocialInstagram, TiSocialYoutube } from "react-icons/ti";
import Envelap from "../../assets/images/env.png"
import Prayment from "../../assets/images/prayment.png"
// Img
import LogoFooter from "../../assets/images/logo.png"
import LogoIcon from "../../assets/images/logoIcon.png"
import MenuCategoriesDrawer from "./MenuCategoriesDrawer"
import CartComponents from "../CartComponents"
import { getCookie } from '../../helper/Cookies';

const { Search } = Input;

function Footer() {
  let usertoken = getCookie("token");
  return (
    <>
      <div className='subcrub_area'>
        <Container>
          <Row>
            <Col md={6} lg={6}>
              <img src={Envelap} alt="" />
              <div className='subcrub_txt'>
                <p> Sale Up To 20% Off For  </p>
                <h3> join our newsletter </h3>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <Search className='newsletter_input' placeholder="Email address" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='footer_area'>
        <Container >
          <div className='footer_design'>
            <Row>
              <Col md={6} lg={3}>
                <div className='footer_area_meddil_box'>
                  <img src={LogoFooter} alt="" />
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece.</p>
                  <div className='social_icon_area'>
                    <TiSocialFacebook />
                    <TiSocialLinkedin />
                    <TiSocialYoutube />
                    <TiSocialTwitter />
                    <TiSocialInstagram />
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className='footer_area_meddil_box'>
                  <h3>Informations </h3>
                  <ul>
                    <li> <Link to="/login"> My Account </Link> </li>
                    <li> <Link to="/about-us"> About us </Link> </li>
                    <li> <Link to="/login"> Login </Link> </li>
                    <li> <Link to="/privacy-policy"> Privacy policy </Link> </li>
                    <li> <Link to="/terms-conditions"> Terms & condition </Link> </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className='footer_area_meddil_box'>
                  <h3>Quick Shop </h3>
                  <ul>
                    <li> <Link to="/new-arrival"> New Arrivals  </Link> </li>
                    <li> <Link to="/weekly-deals">  Weekly Deals  </Link> </li>
                    <li> <Link to="/top-ranked-product">   Top-ranked Products   </Link> </li>
                    <li> <Link to="/all-seller-pages"> Saller Pages </Link> </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className='footer_area_meddil_box'>
                  <h3>Customer Services </h3>
                  <ul>
                    <li> <Link to="/"> Request Personal Data </Link> </li>
                    <li> <Link to="/faq"> FAQ's </Link> </li>
                    {/* <li> <Link to="/"> Contact Us </Link> </li> */}
                    <li> <Link to="/orders-returns"> Orders and Returns </Link> </li>
                    <li> <Link to="/support-center"> Support Center </Link> </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className='subfooter footer-border'>
        <Container>
          <Row>
            <Col lg={12}>
              <p> 2022 Copy Right by Eone Powered by Infinity infotech </p>
              <img src={Prayment} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='mobile__footer_area'>
        <Container>
          <Row>
            <Col lg={12}>
              <div className='logo__icon__footer__new'>
                <Link to="/">
                  <img src={LogoIcon} alt="logo icon" />
                </Link>
              </div>
              <div className='d-flex justify-content-between'>
                <div className=' footer__fixt__box'>
                  <Link to="/">
                    <VscHome />
                    <h6> Home </h6>
                  </Link>
                </div>
                <div className=' footer__fixt__box'>
                  <MenuCategoriesDrawer />
                  {/* <Link to="/"> */}
                  {/* <VscListFilter /> */}
                  {/* <h6> Categories </h6> */}
                  {/* </Link> */}
                </div>
                <div className=' footer__fixt__box'>
                  <CartComponents />
                  {/* <Link to="/">
                    <VscClippy />
                    <h6> Cart </h6>
                  </Link> */}
                </div>
                {Number(usertoken) === 0 ? <>
                  <div className=' footer__fixt__box'>
                    <Link to="/wish-list">
                      <VscHeart />
                      <h6> Wishlist </h6>
                    </Link>
                  </div>
                </> : <>
                  <div className=' footer__fixt__box'>
                    <Link to="/dashboard-2">
                      <VscDashboard />
                      <h6> Dashboard </h6>
                    </Link>
                  </div>
                </>}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Footer