import { Tabs } from 'antd';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
// import MUIDataTable from "mui-datatables"
import ManageAccount from "../Components/Dashboard/ManageAccount"
import MyProfile from "../Components/Dashboard/MyProfile"
import AddressBook from "../Components/Dashboard/AddressBook"
import Valied from "../assets/images/valid.png"
const { TabPane } = Tabs;

// const List = ({ complete_data }) => {
//     return (
//         <ul className="list-disc">
//             {complete_data?.map(x => x.fullname)}
//         </ul>
//     )
// }
// const Itemddd = ({ data }) => {
//     console.log(data, "data datadatadatadatadatadatadatadata")
//     return (
//         <li className="ml-10">
//             {data?.fullname}
//             {data?.children.length > 0 && (
//                 <ul className="list-disc">
//                     {data?.children.map((x) => <Itemddd key={x?.id} data={x} />)}
//                 </ul>
//             )}
//         </li>
//     )
// }


// function NestedThing({ items, level = 0 }) {

//     return (
//       <div>
//         {items?.map(item => {
//           if (Array.isArray(item)) {
//             return <NestedThing items={item} level={level + 1} />;
//           } else {
//             return <p style={{ marginLeft: 16 * level }}>{item}</p>;
//           }
//         })}
//       </div>
//     );
//   }

function Dashboard() {
    const categories = [
        {
            title: "Electronics", path: "Electronics", id: 1, subCat: [
                {
                    title: "Computers", path: "Electronics->Computers", id: 11, subCat: [
                        {
                            title: "Desktop", path: "Electronics->Computers->Desktop", id: 111, subCat: [
                                { title: "Gaming", path: "Electronics->Computers->Desktop->Gaming", id: 1111, subCat: null },
                                { title: "Office", path: "Electronics->Computers->Desktop->Office", id: 1112, subCat: null }
                            ]
                        },
                        {
                            title: "Laptops", path: "Electronics->Computers->Laptops", id: 112, subCat: [
                                { title: "Gaming", path: "Electronics->Computers->Laptops->Gaming", id: 1121, subCat: null },
                                { title: "Office", path: "Electronics->Computers->Laptops->Office", id: 1122, subCat: null }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Cars", path: "Cars", id: 2, subCat: [
                {
                    title: "Parts & Accessories", path: "Cars->Parts & Accessories", id: 21, subCat: [
                        { title: "Car Parts", path: "Cars->Parts & Accessories->Car Parts", id: 211, subCat: null },
                        { title: "Car Accessories", path: "Cars->Parts & Accessories->Car Accessories", id: 211, subCat: null },
                        { title: "Parts", path: "Cars->Parts & Accessories->Parts", id: 212, subCat: null },
                        { title: "Paintwork", path: "Cars->Parts & Accessories->Paintwork", id: 213, subCat: null },
                        {
                            title: "Tyres & Rims", path: "Cars->Parts & Accessories->Tyres & Rims", id: 214, subCat: [
                                { title: "Tyres", path: "Cars->Parts & Accessories->Tyres & Rims->Tyres", id: 2141, subCat: null },
                                { title: "Rims", path: "Cars->Parts & Accessories->Tyres & Rims->Rims", id: 2142, subCat: null },
                                { title: "Trims", path: "Cars->Parts & Accessories->Tyres & Rims->Trims", id: 2143, subCat: null },
                                {
                                    title: "Accessories", path: "Cars->Parts & Accessories->Tyres & Rims->Accessories", id: 2144, subCat: [
                                        { title: "Hub Centre Caps", path: "Cars->Parts & Accessories->Tyres & Rims->Accessories->Hub Centre Caps", id: 21441, subCat: null },
                                        { title: "Valve Caps", path: "Cars->Parts & Accessories->Tyres & Rims->Accessories->Valve Caps", id: 21442, subCat: null },
                                        { title: "Tyre Bags", path: "Cars->Parts & Accessories->Tyres & Rims->Accessories->Tyre Bags", id: 21443, subCat: null },
                                        { title: "Bolts & Nut Covers", path: "Cars->Parts & Accessories->Tyres & Rims->Accessories->Bolts & Nut Covers", id: 21444, subCat: null }
                                    ]
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    ]
    // console.log(dataaaa)
    return (
        <div className='dashboard_area'>
            <Container>
                <Row>
                    <Col lg="12">
                        <div className='dashboard_valid_'>
                            {/* <h5> Hello, Customar Name </h5> */}
                            {/* <NestedThing items={items} /> */}
                            <ul className="list-disc">
                                {categories?.map(data =>
                                    <>
                                        <li> {data?.title} </li>
                                        {data?.subCat?.length > 0 && (
                                            <ul className="list-disc">
                                                {data?.subCat.map((x) =>
                                                    <>
                                                        <li> {x.title} </li>
                                                        {x?.subCat.map((x) =>
                                                            <li> {x.title} 3 </li>
                                                        )}
                                                    </>
                                                )}
                                            </ul>
                                        )}
                                    </>
                                )}
                            </ul>
                        </div>
                    </Col>
                    <Col lg="2">
                        <div className='dashboard_valid_'>
                            <h5> Hello, Customar Name </h5>
                            <img className='img-fit' src={Valied} alt="Okk" />
                        </div>
                    </Col>
                    <Col lg="10">
                        <div className='dashboard_valid_offer'>
                            <h5> Best offer for you... <button> Click Here </button>  </h5>
                        </div>
                    </Col>
                    <Col lg="12">
                        <h3> Manage My Account </h3>
                        <Tabs tabPosition="left">
                            <TabPane tab="Manage Account" key="1">
                                <ManageAccount />
                            </TabPane>
                            <TabPane tab="My Profile" key="2">
                                <MyProfile />
                            </TabPane>
                            <TabPane tab="Address Book" key="3">
                                <AddressBook />
                            </TabPane>
                            <TabPane tab="My Payment Options" key="4">
                                Content of Tab 3
                            </TabPane>
                            <TabPane tab="Vouchers" key="5">
                                Content of Tab 4
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Dashboard