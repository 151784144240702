import React from 'react'
import HomeBanner from "../Components/HomeBanner"
import ThreeCategoriesBox from "../Components/ThreeCategoriesBox"
import EoneMail from "../Components/EoneMail"
import JustForYou from "../Components/JustForYou"
import VirtulTry from "../Components/VirtualTry"
function HomePages() {
  return (
    <div>
      <HomeBanner />
      <ThreeCategoriesBox />
      <EoneMail />
      <VirtulTry />
      <JustForYou />
    </div>
  )
}

export default HomePages