import React, { useState, useContext, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from "react-router-dom";
import { Input, Select, Space, Dropdown, Menu } from 'antd';
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import axios from '../../helper/Api';
import TopHeaderAnimishon from "./TopHeaderAnimishon"
// import MenuCategories from "./MenuCategories"
import CartComponents from "../CartComponents"
import { ADD_ITEM } from "../../reduxServices/actions/CartActions";
import { setSearchData } from "../../reduxServices/actions/searchDataAction";
import { getCookie } from '../../helper/Cookies';
import { axiosPrivate } from '../../helper/useAxiosPrivate';
import { RiHeartLine, RiMailLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { VscGear, VscCallIncoming, VscDiffRenamed, VscHeart, VscUnlock } from "react-icons/vsc";
// Select Lang
import { withTranslation } from 'react-i18next';
import LanguageSelect from "../../LanguageSelect";



// Img
import Logo from "../../assets/images/logo.png"
import BarzilLogo from "../../assets/images/barzilLogo.png"

// icons
import { VscChevronDown, VscListSelection } from "react-icons/vsc";
import LanguageContext from "../../context/LanguageProvider";


import MenuCategoriesDrawer from "./MenuCategoriesDrawer"
import AuthService from "../../helper/Auth.Services"
import { VscAccount } from "react-icons/vsc";
import { toast } from "react-toastify";
const { Option } = Select;
const selectBefore = (
    <Select defaultValue="http://" className="select-before">
        <Option value="http://">Products</Option>
        <Option value="https://">Suppliers</Option>
    </Select>
);

let usertoken = getCookie("token");
console.log(usertoken, "usertokenusertoken")


// BiCaretUp
function DefaultHeader() {
    const redirect = useNavigate()
    // All CONTEXT DATA

    const { language } = useContext(LanguageContext);
    const [isMouse, toggleMouse] = useState(false);
    const [cartslist, setCartslist] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [useData, setUseData] = useState([]);
    // const [count, setCount] = useState(0);


    const location = useLocation()
    const dispatch = useDispatch()
    var pCategore = location.pathname.includes("product-categories");
    var pDetails = location.pathname.includes("product-details");
    const toggleMouseMenu = () => {
        toggleMouse(!isMouse);
    };
    const cashRemove = () => {
        localStorage.removeItem("brand_id");
        localStorage.removeItem("locationDefault");
        localStorage.removeItem("country_id");
        localStorage.removeItem("setIsColor");
        localStorage.removeItem("setIsSize");
        localStorage.removeItem("tempColorColorCollection");
        localStorage.removeItem("warrenty_types_id");
    }


    useEffect(() => {
        axios.get("/customer/cart/list-cart", {
            headers: {
                'Authorization': `bearer ${usertoken}`
            }
        })
            .then(res => {
                console.log(res)
                setCartslist(res?.data?.data?.carts)
                dispatch(ADD_ITEM(res?.data?.data?.carts));
            })
        axios.get("/customer/get-user-details", {
            headers: {
                'Authorization': `bearer ${usertoken}`
            }
        })
            .then(res => {
                console.log(res)
                setUseData(res?.data?.data?.user_details)
                // setCartslist(res?.data?.data?.carts)
            })
    }, [])
    
    if (pCategore === true || pDetails === true) {
    } else {
        // Cash Remove
        cashRemove()

    }



    const [isMousecatagory, toggleMousecatagory] = React.useState(false);
    const toggleMouseMenuCatagori = () => {
        toggleMousecatagory(!isMousecatagory);
    };
    const subMenuAnimate = {
        enter: {
            opacity: 1,
            rotateX: 0,
            transition: {
                duration: 0.5
            },
            display: "block"
        },
        exit: {
            opacity: 0,
            rotateX: -15,
            transition: {
                duration: 0.5,
                delay: 0.3
            },
            transitionEnd: {
                display: "none"
            }
        }
    };

    const logoutFunction = () => {
        axios.post("/customer/logout", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {

                if (res?.data?.code !== 200) {
                    toast.error(res?.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    AuthService.logout()
                    setTimeout(() => {
                        window.location.reload(true)
                    }, 1000)
                    // redirect("/")
                    // setCount(count + 1)
                }
                if (res?.data?.code === 200) {
                    toast.success(res?.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    AuthService.logout()
                    window.location.reload(true)
                    // redirect("/")
                    setTimeout(() => {
                        window.location.reload(true)
                    }, 1000)
                    // setCount(count + 1)
                }
            })
    }

    const searchData = (e) => {
        setSearchText(e.target.value)
    }

    const searchDataSubmit = () => {
        axios.get(`/frontend/public-api/search-products?page=1&per_page=10&search=${searchText}`)
            .then(res => {
                console.log(res, "res res res res")
                // if (res?.data?.code !== 200) {
                //     toast.error(res?.data.message, {
                //         position: toast.POSITION.TOP_CENTER
                //     })
                // }
                if (res?.data?.code === 200) {
                    localStorage.setItem("search_data", searchText);
                    dispatch(setSearchData(res?.data?.data?.products));
                    redirect("/search-pages")
                }
            })
    }

    const selectAfter = (
        <span onClick={searchDataSubmit}> Search </span>
        // <span onClick={searchDataSubmit}> <Link style={{color: '#fff'}} to="/search-pages"> Search </Link>  </span>
    );

    const sellerRedirect = () => {
        const url = 'http://e-onebd.com/eonecmsfrontendv1-dev/seller-register';
        window.open(url, '_blank');
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div className="sub-menu-container">
                            <p> Welcome back! </p>
                            <Link to="login" style={{ color: "#fff" }}>
                                <button className='header_sing_in_btn'> Sing in </button>
                            </Link>
                            {/* <button className='header_sing_in_btn header_sing_in_btnjoin'> Join Free </button> */}
                            <div className='sc-hd-ms-login-title'>
                                <span className="sc-hd-ms-login-title-line"></span>
                                Continue with:
                                <span className="sc-hd-ms-login-title-line"></span>
                            </div>
                        </div>
                    ),
                }
            ]}
        />
    );
    const userMenu = (
        <Menu
            className='drop__area'
            items={[
                {
                    key: '1',
                    label: (
                        <div className="sub-menu-container">
                            {/* <p> Welcome back! </p> */}
                            <Link to="/user-profile" style={{ color: "#222" }}>
                                <span> <VscGear className="header_dropdown_list" /> Profile Setting </span>
                            </Link>
                        </div>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <div className="sub-menu-container">
                            {/* <p> Welcome back! </p> */}
                            <Link to="/wish-list" style={{ color: "#222" }}>
                                <span> <VscHeart className="header_dropdown_list" /> WishList </span>
                            </Link>
                        </div>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <div className="sub-menu-container">
                            {/* <p> Welcome back! </p> */}
                            <Link style={{ color: '#222' }} to="/password-change" >
                                <span> <VscUnlock className="header_dropdown_list" /> Password Change </span>
                            </Link>
                        </div>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <div className="sub-menu-container">
                            {/* <p> Welcome back! </p> */}
                            <Link style={{ color: '#222' }} to="" onClick={logoutFunction}>
                                <span> <VscDiffRenamed className="header_dropdown_list" /> Logout </span>
                            </Link>
                        </div>
                    ),
                }
            ]}
        />
    );

    // console.log(allData, "allData allDataallData allData allData")
    return (
        <div className='header__area'>
            <TopHeaderAnimishon />
            <div className='hedaer_medial_area pt-2 pb-2'>
            
                <Container className='justify-content-center'>
                    <Row >
                        <div className='movile__rspnsive__Header_view'>
                            <Row>
                                <Col lg={6} sm={6} xs={6}>
                                    <div style={{ display: 'block' }} className='header_right_area_item'>

                                        {Number(usertoken) === 0 ? <>
                                            <VscAccount className='r__svg' />
                                            {/* <div className='login-icon login-icon-padding login-icon-unsignavatar'></div> */}
                                            <Dropdown overlay={menu} placement="bottomLeft">
                                                <p className='login__h_'> <span className='down__icon'> Sign In <DownOutlined style={{ float: 'right', fontSize: '15px' }} /> </span>  </p>
                                            </Dropdown>
                                        </> : <>
                                            <h3 className='drop__area'>
                                                <Dropdown overlay={userMenu} placement="bottomLeft">
                                                    <p className='login__h_'> <span className='down__icon'> <img style={{ width: "40px" }} src={BarzilLogo} alt="ok" /> {useData?.first_name} {useData?.last_name}
                                                    </span>  </p>
                                                </Dropdown>
                                            </h3>
                                        </>}

                                    </div>
                                </Col>
                                <Col lg={6} sm={6} xs={6}>
                                    <div style={{ display: 'block' }}>
                                        <LanguageSelect />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Col lg={2} sm={6}>
                            <Link to="/"> <img className='img-fit logo_header' src={Logo} alt="ok" /> </Link>
                        </Col>
                        <Col md={12} lg={7}>
                        
                            <div className='header_search'>
                                <Space direction="vertical">
                                    <Input type='text' onChange={(e)=> searchData(e)} addonAfter={selectAfter} placeholder="What are you looking for..." />
                                </Space>
                            </div>
                            <div className='header_search movlile__rasponsive'>
                                <Space direction="vertical">
                                    <Input addonAfter={selectAfter} placeholder="What are you looking for..." />
                                </Space>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='header_right_area'>
                                <div className='header_right_area_item'>

                                    {Number(usertoken) === 0 ? <>
                                        <div className='login-icon login-icon-padding login-icon-unsignavatar'></div>
                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <p className='login__h_'> Hello <br></br> <span className='down__icon'> Sign In <DownOutlined style={{ float: 'right', fontSize: '15px' }} /> </span>  </p>
                                        </Dropdown>
                                    </> : <>
                                        <h3 className='drop__area'>
                                            <Dropdown overlay={userMenu} placement="bottomLeft">
                                                <p className='login__h_'> <span className='down__icon'>
                                                    <img style={{ width: "40px" }} src={BarzilLogo} alt="ok" /> {useData?.first_name} {useData?.last_name}
                                                </span>  </p>
                                            </Dropdown>
                                        </h3>
                                    </>}

                                </div>{" "}
                                {/* <Link to="/wish-list">
                                    <div className='header_right_area_item header_right_area_item_order'>
                                        <RiHeartLine />
                                        <p>  Wish List </p>
                                    </div>
                                </Link> */}
                                <CartComponents />

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='header_bottom_area'>
                <Container>
                    <Row>
                        <Col lg={2}>
                            <div className='header_b_left'>
                                <MenuCategoriesDrawer />
                                {/* <div className='header_right_area_item'>
                                    <motion.div
                                        className="menu-item"
                                        onMouseEnter={toggleMouseMenuCatagori}
                                        onMouseLeave={toggleMouseMenuCatagori}
                                    >
                                        
                                        <motion.div
                                            className="sub-menu"
                                            initial="exit"
                                            animate={isMousecatagory ? "enter" : "exit"}
                                            variants={subMenuAnimate}
                                        >
                                            <div className="sub-menu-background" />
                                            <div className="sub-menu-container">
                                                <MenuCategories />
                                            </div>
                                        </motion.div>
                                    </motion.div>

                                </div>{" "} */}
                            </div>
                        </Col>
                        <Col md={8} lg={8}>
                            <div className='header_b_midell'>
                                <ul>
                                    <li> <Link to="/ready-to-ship"> Ready to Ship </Link> </li>
                                    {/* <li> <Link to="/"> Trade Shows </Link> </li> */}
                                    <li> <Link onClick={sellerRedirect} to=""> Sell on Eone </Link> </li>
                                    <li> <Link to="/help"> Help </Link> </li>
                                    {/* <li> <Link to="/dashboard"> Dashboard02 </Link> </li> */}
                                    {Number(usertoken) === 0 ? <>
                                    </> : <>
                                        <li> <Link to="/dashboard-2"> Dashboard </Link> </li>
                                    </>}

                                </ul>
                            </div>
                        </Col>
                        <Col md={4} lg={2}>
                            <div className='header_b_midell'>
                                <ul>
                                    <li> <LanguageSelect /> </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default withTranslation()(DefaultHeader)