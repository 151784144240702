import React from 'react'

function TopHeaderAnimishon() {
    return (
        <div className='top_header_animishon'>
            <div id="container_animishon">
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
            </div>
        </div>
    )
}

export default TopHeaderAnimishon