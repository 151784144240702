import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Checkbox, Empty, Radio } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { setProducts } from "../reduxServices/actions/productsActions";
// import { VscChromeRestore } from "react-icons/vsc";
import data from "../data.json"
import { Select, InputNumber, Pagination } from 'antd';
import axios from '../helper/Api';
import { imagesUrl } from '../helper/Url';
import { getCookie } from '../helper/Cookies';
import LanguageContext from "../context/LanguageProvider";
import { RiHeartLine } from "react-icons/ri";
// import { ADD } from '../reduxServices/actions/CartActions';
import Slider from '@material-ui/core/Slider';
import { VscDiffRenamed } from "react-icons/vsc";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { toast } from "react-toastify";
import Swal from "sweetalert2"
// import axios from '../helper/Api';
import LoginPages from "./LoginPages";
const { Option } = Select;
const handleChange = (value) => {
    console.log(`selected ${value}`);
};
let tempColorColorCollection = [];
const tempColorColorCollection2 = [];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    boxShadow: 24
};

function WeeklyDealsPages() {
    const [visible, setVisible] = useState(8);
    let usertoken = getCookie("token");
    const [page, setpage] = useState(1);
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState(0);
    const [count, setCount] = useState(0);

    const [isCheck, setIsCheck] = useState(localStorage.getItem("warrenty_types_id") || '');
    const [isBrandCheck, setIsBrandCheck] = useState(localStorage.getItem("brand_id") || '');

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);


    const allProduct = useSelector((state) => state?.allProducts?.products);
    const warrenty_types = useSelector((state) => state?.allProducts?.products?.warrenty_types);
    const category_primary_attributes = useSelector((state) => state?.allProducts?.products?.category_primary_attributes);
    const brands = useSelector((state) => state?.allProducts?.products?.brands);
    const singleData = useSelector((state) => state?.allProducts?.products?.products?.data);
    const dispatch = useDispatch();
    const { language } = useContext(LanguageContext);
    const { slug } = useParams()

    useEffect(() => {
        const min_price = localStorage.getItem("min_price") || "";
        const max_price = localStorage.getItem("max_price") || "";
        const brand_id = localStorage.getItem("brand_id") || "";
        const warrenty_types_id = localStorage.getItem("warrenty_types_id") || "";
        const tempColorColorCollection = localStorage.getItem("tempColorColorCollection") || "";

        axios.get(`/frontend/public-api/weekly-deals-products?active_language=${language}&price_start=${min_price}&price_end=${max_price}&warrenty_type=${warrenty_types_id}&attribute_value_ids=${tempColorColorCollection}&brand_id=${brand_id}&per_page=${visible}&page=${page}`, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                console.log(res)
                dispatch(setProducts(res?.data?.data))
            })
    }, [language, count, slug])

    // Price range 
    const rangeSelector = (event, newValue) => {
        // setValue(newValue);
        const min_price = minPrice
        const max_price = maxPrice
        // Local Set
        localStorage.setItem("min_price", min_price);
        localStorage.setItem("max_price", max_price);
        axios.get(`/frontend/public-api/weekly-deals-products?active_language=${language}&price_start=${min_price}&price_end=${max_price}&per_page=${visible}`)
            .then(res => {
                dispatch(setProducts(res?.data?.data))
            })
    };
    // Brand 
    const brandFunction = (e) => {
        const brand_id = e.target.value
        console.log(brand_id)
        localStorage.setItem("brand_id", brand_id);
        const min_price = localStorage.getItem("min_price");
        const max_price = localStorage.getItem("max_price");
        const warrenty_types_id = localStorage.getItem("warrenty_types_id");
        if (e.target.checked) {
            axios.get(`/frontend/public-api/weekly-deals-products?active_language=${language}&price_start=${min_price}&price_end=${max_price}&warrenty_type=${warrenty_types_id}&brand_id=${brand_id}&per_page=${visible}`)
                .then(res => {
                    dispatch(setProducts(res?.data?.data))
                    setIsBrandCheck(brand_id)
                })
        } else {
            // setIsBrandCheck(false)
            localStorage.removeItem("brand_id");
            axios.get(`/frontend/public-api/weekly-deals-products?active_language=${language}&price_start=${min_price}&price_end=${max_price}&warrenty_type=${warrenty_types_id}&per_page=${visible}`)
                .then(res => {
                    dispatch(setProducts(res?.data?.data))
                    setIsBrandCheck(false)
                })
        }
    }

    const warrentyTypesFunction = (e) => {
        console.log(e.target.checked)
        const warrenty_types_id = e.target.value
        localStorage.setItem("warrenty_types_id", warrenty_types_id);

        const min_price = localStorage.getItem("min_price");
        const max_price = localStorage.getItem("max_price");
        const brand_id = localStorage.getItem("brand_id");

        if (e.target.checked) {
            axios.get(`/frontend/public-api/weekly-deals-products?active_language=${language}&price_start=${min_price}&price_end=${max_price}&brand_id=${brand_id}&warrenty_type=${warrenty_types_id}&per_page=${visible}`)
                .then(res => {
                    console.log(res, "res res")
                    // res?.data?.data
                    dispatch(setProducts(res?.data?.data))
                    setIsCheck(warrenty_types_id)
                })
        } else {
            localStorage.removeItem("warrenty_types_id");
            axios.get(`/frontend/public-api/weekly-deals-products?active_language=${language}&price_start=${min_price}&price_end=${max_price}&brand_id=${brand_id}&per_page=${visible}`)
                .then(res => {
                    console.log(res, "res res")
                    dispatch(setProducts(res?.data?.data))
                    setIsCheck(false)
                })
        }

    }

    const onShowSizeChange = (current, pageSize) => {
        setVisible(pageSize)
        setCount(count + 1)
    };
    const pagesChange = (e) => {
        setpage(e)
        setCount(count + 1)
    };

    const wishlistAdd = (p_id) => {
        if (Number(usertoken) === 0) {
            setOpen(true)
        } else {
            const obj = {
                product_id: p_id
            }
            axios.post('/customer/wishlist/add-wishlist', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
                .then(res => {
                    if (res.data.code === 200) {
                        toast.success(res?.data?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        setCount(count + 1)
                    }
                    if (res.data.code === 404) {
                        toast.error(res?.data?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                })
        }

    }
    const wishlistRemove = (p_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const obj = {
                    product_id: p_id
                }
                axios.post('/customer/wishlist/single-remove', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
                    .then(res => {
                        if (res.data.code === 200) {
                            // toast.success(, {
                            //     position: toast.POSITION.BOTTOM_RIGHT,
                            // });
                            Swal.fire(
                                'Deleted!',
                                `${res?.data?.message}`,
                                'success'
                            )
                            setCount(count + 1)
                        }
                        if (res.data.code === 404) {
                            toast.error(res?.data?.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                    })

            }
        })

    }
    // console.log(category_primary_attributes[0]?.single_attribute?.attribute?.values, "allProduct allProduct")
    return (
        <div className='product_categories_area'>
            <Container>
                <Row>
                    <Col lg={3}>
                        <div className='product_categories_left_sidbar'>
                            {/* <h3> Related Categories </h3>
                            <p> Women's Dresses </p> */}
                            {/* Price range  */}
                            <div className='categori__l priceRange__color'>
                                <h3 style={{ paddingBottom: '10px' }}> Price range  </h3>
                                <div className='categori_details_list'>
                                    <div style={{
                                        margin: 'auto',
                                        display: 'block',
                                        width: "100%"
                                    }}>

                                        <InputNumber placeholder='Min' style={{ width: '40%', marginRight: '10px' }} min={0} onChange={(e) => setMinPrice(e)} />
                                        <InputNumber placeholder='Max' style={{ width: '40%' }} min={0} onChange={(e) => setMaxPrice(e)} />
                                        <button className='s_range__btn' onClick={rangeSelector}> <VscDiffRenamed /> </button>
                                    </div>
                                </div>
                            </div>
                            {/* Brand */}
                            {brands?.length > 0 ? <>
                                <div className='checkbox__area'>
                                    <h5> Brand </h5>
                                    <ul>
                                        {brands?.map((item, i) =>
                                            <li key={i}>
                                                <Checkbox
                                                    onChange={(e) => brandFunction(e)}
                                                    checked={item?.id === Number(isBrandCheck) ? true : false}
                                                    value={item?.active_translation[0]?.brand_id} >
                                                    {item?.active_translation[0]?.name}
                                                </Checkbox>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </> : <> </>}

                            {/* Warrenty Types  */}
                            {warrenty_types?.length > 0 ? <>
                                <div className='checkbox__area'>
                                    <h5> Warrenty Types </h5>
                                    <ul>
                                        {warrenty_types?.map((item, i) =>
                                            <li key={i}> <Checkbox
                                                value={item?.id}
                                                checked={item?.id === Number(isCheck) ? true : false}
                                                onChange={(e) => warrentyTypesFunction(e)}
                                            >{item?.name}</Checkbox> </li>
                                        )}
                                    </ul>
                                </div>
                            </> : <> </>}
                        </div>
                    </Col>

                    <Col lg={9}>
                        <Row>
                            {singleData?.length > 0 ? <>
                                <Col lg={12}>
                                    <div className='three_catagori_box'>
                                        {/* <p> <VscChromeRestore /> Just For You <Link to="/"> View All </Link> </p> */}
                                        <Row>
                                            {singleData?.slice(0, visible).map((item, i) =>
                                                <Col lg={3} className="d-flex align-items-stretch mt-3" key={i}>
                                                    <div className='card'>
                                                        <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                                            {/* <Link to="/product-details">slug */}
                                                            <Link to={`/product-details/${item?.slug}`}>
                                                                <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                                            </Link>
                                                            <Link to={`/product-details/${item?.slug}`} className='product_hone_box_text'>
                                                                <p> {item?.active_translation[0]?.name?.substring(0, 50)}</p>
                                                            </Link>
                                                            <div className='price__'>
                                                                {item?.feature_item[0]?.discount_price > 0 ? <>
                                                                    ৳{item?.feature_item[0]?.discount_price}
                                                                    <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                                                </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}

                                                                {item?.wishlist === null ? <>
                                                                    <button style={{ background: '#2222', color: '#222' }} className='price__cart__btn' onClick={() => wishlistAdd(item?.feature_item[0]?.product_id)}> <RiHeartLine /> </button>
                                                                </> : <>
                                                                    <button className='price__cart__btn' onClick={() => wishlistRemove(item?.feature_item[0]?.product_id)}> <RiHeartLine /> </button>
                                                                </>}
                                                            </div>

                                                            <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                                        </div>
                                                    </div>

                                                </Col>
                                            )}
                                            {allProduct?.products?.total > 11 ? <>
                                                <Col lg={12} style={{ marginTop: '20px' }}>
                                                    <Pagination
                                                        showSizeChanger
                                                        onShowSizeChange={onShowSizeChange}
                                                        onChange={(e) => pagesChange(e)}
                                                        defaultCurrent={page}
                                                        total={allProduct?.products?.total}
                                                    />
                                                </Col>
                                            </> : <> </>}
                                            {/* <Col lg={12}>
                                                {singleData?.length > 0 ? <>
                                                    <button className="loadMore__btn" type="button" onClick={loadmore}> Load More </button> </> :
                                                    <>  </>}
                                            </Col> */}
                                        </Row>
                                    </div>
                                </Col>
                            </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                        </Row>
                    </Col>

                    <Modal
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <Box sx={style}>
                            <LoginPages />
                        </Box>
                    </Modal>
                </Row>
            </Container>
        </div>
    )
}

export default WeeklyDealsPages