import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RiFacebookLine, RiGoogleLine, RiLinkedinLine } from "react-icons/ri";
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
// import 'antd-country-phone-input/dist/index.css';
import axios from '../helper/Api';
import { toast } from "react-toastify";

// import 'antd-country-phone-input/dist/index.css';
import { Form } from 'antd';
import { createCookieInHour, COOKIE_NAME } from '../helper/Cookies';
function LoginPages() {
  const [value, setValue] = useState({ short: 'BD' });
  const [country, setCountry] = useState([]);
    // Country List 
    useEffect(() => {
      axios.get("/common/country/active/all")
          .then(res => {
            setCountry(res.data.data.countries)
            // const countryId = res.data.data.countries?.find(item => item?.iso === value?.short)
            // setCountry(countryId?.id)
          })
  }, [])
  
  const [state, setState] = useState({
    country_id: [],
    phone_number: '',
    password: '',
  })

  console.log(state)
  const dataSubmit = () => {
    localStorage.setItem("country_id", state?.country_id)
    axios.post('/customer/login', state, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((res) => {
        createCookieInHour(COOKIE_NAME, res.data.data.token)
        window.location.reload(true)
      }).catch(err => {
        // console.log(err.res.data)
        toast.error("Login Credentials Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
      });
      })
  }
console.log(value)
  return (
    <div className='login_area'>
      <div className="container" id="container">
        <div className="form-container sign-up-container">
          {/* <Form
            onFinish={dataSubmit}
            autoComplete="off"
          > */}
          <h1>Create Account</h1>
          <div className="social-container">
            <Link to="/"> <RiFacebookLine />  </Link>
            <Link to="/"> <RiGoogleLine />  </Link>
            <Link to="/"> <RiLinkedinLine />  </Link>
          </div>
          <span>or use your email for registration</span>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <button>Sign Up</button>
          {/* </Form> */}
        </div>
        <div className="form-container sign-in-container">
          <Form
            onFinish={dataSubmit}
            autoComplete="off"
          >
            <h1>Sign in</h1>
            <div className="social-container">
              <Link to="/"> <RiFacebookLine />  </Link>
              <Link to="/"> <RiGoogleLine />  </Link>
              <Link to="/"> <RiLinkedinLine />  </Link>
            </div>
            <span>or use your account</span>
            <ConfigProvider locale={en}>
              <CountryPhoneInput
                value={value}
                onChange={(v) => {
                  setValue(v);
                  const countryId = country?.find(item => item?.iso === value?.short)
                  console.log(countryId)
                  setState({ ...state, country_id: countryId?.id, phone_number: v?.phone })
                }} />
            </ConfigProvider>
            <input style={{border: "1px solid #2222"}} type="password" onChange={(e) => setState({ ...state, password: e.target.value })} placeholder="Password" />
            <Link to="/forgot-password">Forgot your password?</Link>
            <button >Sign In</button>
            <Link className='creact__new__account__btn' to="/user-account-creact">Create New Account</Link>
          </Form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Welcome Back!</h1>
              <p>To keep connected with us please login with your personal info</p>
              <button className="ghost" id="signIn">Sign In</button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1>Creact Account</h1>
              <p>Enter your personal details and start journey with Eone</p>
              <Link to="/user-account-creact">
              <button className="ghost" id="signUp">Sign Up</button>

              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPages