import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import HeroSlider, { Slide, Nav } from "hero-slider";
import CatagoryCompon from "./Layout/MenuCategories"
import BannerAdd from "../assets/images/banneradd.png"
import axios from '../helper/Api';
// Images
import Banner01 from "../assets/images/banner/01.png"
import Banner02 from "../assets/images/banner/02.png"
import Banner03 from "../assets/images/banner/03.png"
import Banner04 from "../assets/images/banner/04.png"
import LanguageContext from "../context/LanguageProvider";
function HomeBanner() {
  const { language } = useContext(LanguageContext);
  const [categoryList, setCategory] = useState([]);
  console.log(language, "language language")
  useEffect(() => {
    axios.get(`/frontend/public-api/category/active/all?active_language=${language}`)
      .then(res => {
        setCategory(res?.data?.data?.categories)
        console.log()
      })
  }, [language])
  console.log(categoryList, "categoryList categoryList")
  return (
    <div className='home_banner_area'>
      <Container>
        <img className='home_banner__add' src={BannerAdd} alt="" />
        <div className='slider__area'>
          <Row>
            <Col lg={3}>
              <div className='catagori__height__area catagore__Box_banner catagore__Box_banner_menu'>
                <h3> MY MARKETS  </h3>
                <CatagoryCompon category={categoryList} />
              </div>
            </Col>
            <Col lg={9}>
              <div className='catagore__Box_banner'>
                <HeroSlider
                  orientation="horizontal"
                  initialSlide={1}
                  // onBeforeChange={(previousSlide, nextSlide) =>
                  //   // {previousSlide, nextSlide}
                  //   // console.log("onBeforeChange", previousSlide, nextSlide)
                  // }
                  // onChange={nextSlide => console.log("onChange", nextSlide)}
                  // onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
                  style={{
                    backgroundColor: "#000"
                  }}
                  settings={{
                    slidingDuration: 500,
                    slidingDelay: 100,
                    shouldAutoplay: true,
                    shouldDisplayButtons: true,
                    autoplayDuration: 5000,
                    // height: "60vh"
                  }}
                >

                  <Slide
                    background={{
                      backgroundImage: Banner01,
                      backgroundAnimation: "zoom"
                    }}
                  />

                  <Slide
                    background={{
                      backgroundImage: Banner02,
                      backgroundAnimation: "zoom"
                    }}
                  />

                  <Slide
                    background={{
                      backgroundImage: Banner03,
                      backgroundAnimation: "zoom"
                    }}
                  />

                  <Slide
                    background={{
                      backgroundImage: Banner04,
                      backgroundAnimation: "zoom"
                    }}
                  />
                  <Nav />
                </HeroSlider>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default HomeBanner