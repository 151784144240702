import React from 'react'
import { Link } from 'react-router-dom'
import Simg from "../../assets/images/s1.png"
function Sunglasses() {
    return (
        <div>
            <div className='collection-banner-main banner-1  p-right'>
                <Link to="/sunglasses">
                    <div className='collection-img bg-size'>
                        <img className='img-fit' src={Simg} alt="" />
                        <div className='collection-banner-contain'>
                            <div>
                                <h3>Eye Glasses</h3>
                                <h4>Offers</h4>
                                <div className="shop"> shop now </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Sunglasses