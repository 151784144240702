import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
// import { VscChromeClose } from "react-icons/vsc";
// import { useSelector, useDispatch } from "react-redux";
import { Empty } from 'antd';
// import { toast } from "react-toastify";
// import { imagesUrl } from '../helper/Url';
import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
// import { ADD_ITEM, getCartTotal } from "../reduxServices/actions/CartActions";
// import Swal from "sweetalert2"
import moment from 'moment'
import Badge from 'react-bootstrap/Badge';

// import { increase, decrease, remove, getCartTotal } from "../reduxServices/actions/CartActions";
function MyOrder() {
    let usertoken = getCookie("token");
    const [orderDetails, setOrderDetails] = useState([]);
    useEffect(() => {
        // Country List
        axios.get(`/customer/order/my-order`, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                console.log(res)
                setOrderDetails(res?.data?.data?.orders?.data)
            })
        
    }, []);
    console.log(orderDetails)

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={12} style={{ marginBottom: "30px" }}>
                        <div className="card table-card  contact-page">
                            {/* <div className="card-header">
                                <h5>Add To Cart</h5>
                            </div> */}
                            <Row>
                                <Col lg={12}>
                                    {orderDetails?.length > 0 ? <>
                                        <div className="card-block theme-form">
                                            <h5 className='add_to_cart__title'>My Order List</h5>
                                            <div className="table-responsive">
                                                <table className="table table-hover m-b-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Order</th>
                                                            <th>Placed On</th>
                                                            <th>Quantity</th>
                                                            <th>Total</th>
                                                            <th>Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderDetails?.map((item, i) =>
                                                            <tr key={i} >
                                                                <td>
                                                                    <Badge bg="success" style={{marginRight: '10px'}}>#</Badge>
                                                                    {item?.order_id}
                                                                </td>
                                                                <td > {moment(item?.created_at).format('YYYY-MM-DD')}</td>
                                                                {/* <td><img src={imagesUrl + item?.product_item?.image_path} alt="" className="img-fluid img__width" /></td> */}
                                                                <td> <Badge bg="primary">{item?.total_quantity}</Badge></td>
                                                                <td>৳ {item?.total_item_amount}</td>
                                                                <td>
                                                                    <Badge bg="warning" text="dark">
                                                                        <Link style={{color: '#222'}} to={`/order-details/${item?.order_id}`}> View </Link>
                                                                    </Badge>
                                                                </td>

                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyOrder