import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Badge } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import SliderImage from 'react-zoom-slider';
import Pdetails from "../assets/images/pdetails1.png";
import { BiMinus, BiPlus } from "react-icons/bi";
import { RiMapPin2Line, RiBikeFill, RiMoneyDollarCircleLine, RiSendPlaneLine, RiFocus2Line, RiFocusLine } from "react-icons/ri";
import dataProduct from "../data.json"
import { useSelector, useDispatch } from "react-redux";
import axios from '../helper/Api';
import { imagesUrl } from '../helper/Url';
import LanguageContext from "../context/LanguageProvider";
import { ADD_ITEM } from "../reduxServices/actions/CartActions";
import { setProducts } from "../reduxServices/actions/productsActions";
import { getCookie } from '../helper/Cookies';
import { toast } from "react-toastify";
import { Button } from 'antd';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoginPages from "./LoginPages";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Swal from "sweetalert2"
import { buynow } from "../reduxServices/actions/CartActions";
import AddresChanges from "../Components/UserInformation/AddresChanges"
import Loader from './loader'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  boxShadow: 24
};
const editModeal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: "30px 30px",
  borderRadius: "5px"
};

let defaultID = []
function ProductDetails() {
  const { slug } = useParams()
  // buynow
  let usertoken = getCookie("token");
  const datalist = useSelector((state) => state?.allProducts?.products);
  // const cartslist = useSelector((state) => state.cartItems.carts);
  const product_details = useSelector((state) => state?.allProducts?.products?.product_details);
  const { language } = useContext(LanguageContext);
  const [visible, setVisible] = useState(12);
  const [productDetails, setProductDetails] = useState();
  const [sliderImg, setSliderImg] = useState();
  const [defaultData, setdefaultData] = useState([]);
  const [cartslist, setCartslist] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [addrssData, setAddrssData] = useState([]);

  const [count, setCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [sizeDefaultValue, setSizeDefaultValue] = useState();
  const [colorDefaultValue, setColorDefaultValue] = useState();
  const [attraviewCount, setAttraviewCount] = useState(1);
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const handleEditClose = () => setOpenBuyNow(false);

  const [checkId, setCheckId] = useState("0");
  const [loaderShow, setLoaderShow] = useState(false)

  const loadmore = () => { setVisible((prevValue) => prevValue + 6) }
  const dispatch = useDispatch()
  let dataImg = []
  useEffect(() => {
    apidataRequesNew()
  }, [language, count, slug,])

  // ITEM CARD SET
  useEffect(() => {
    setLoaderShow(true)
    axios.get("/customer/cart/list-cart", { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        setCartslist(res?.data?.data?.carts)
        dispatch(ADD_ITEM(res?.data?.data?.carts))
        setLoaderShow(false)
      }).catch(() => {
        setLoaderShow(false)
      })
    axios.get("/customer/get-user-details", { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        setUserDetails(res?.data?.data?.user_details)
        setLoaderShow(false)
      }).catch(() => {
        setLoaderShow(false)
      })
    // Address
    axios.get("/customer/get-address", { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        console.log(res, "99999999999999")
        setAddrssData(res?.data?.data?.customerProfileAddressData)
        setLoaderShow(false)
      }).catch(() => {
        setLoaderShow(false)
      })
  }, [itemCount, addrssData?.detail_address])


  // useEffect(() => {
  //   console.log(productDetails, "productDetails productDetails")
  //   const isSize = localStorage.getItem("setIsSize");
  //   const isColor = localStorage.getItem("setIsColor");
  //   if (isSize > 0 & isColor > 0) {

  //     let items = [];
  //     productDetails?.primary_attributes?.find(obj => {
  //       const arr = obj?.item_values
  //       let sizeStatus = 0
  //       let colorStatus = 0
  //       arr?.map((check) => {
  //         if (check?.attribute_value_id === Number(isSize) && sizeStatus === 0) {
  //           sizeStatus = 1
  //         }
  //         if (check?.attribute_value_id === Number(isColor) && colorStatus === 0) {
  //           colorStatus = 1
  //         }
  //       })
  //       if (arr?.length > 1) {
  //         if (sizeStatus === 1 && colorStatus === 1) {
  //           items.push({ ...obj, qnty: 1 })
  //         }
  //       } else {
  //         if (sizeStatus === 1 || colorStatus === 1) {
  //           items.push({ ...obj, qnty: 1 })
  //         }
  //       }
  //       setdefaultData(items[0])
  //       console.log(items[0])
  //     })
  //   }
  // }, [attraviewCount])

  const apidataRequesNew = () => {
    setLoaderShow(true)
    axios.get(`/frontend/public-api/product-details/${slug}?active_language=${language}`, { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        console.log(res)
        const temp = { ...res?.data?.data?.product_details, qnty: 1 }
        const temp2 = { ...res?.data?.data?.product_details.feature_item[0], qnty: 1 }
        dispatch(setProducts(res?.data?.data))
        setProductDetails(temp)
        setSliderImg(dataImg)
        setdefaultData(temp2)
        res?.data?.data?.product_details?.galleries?.forEach(data => {
          dataImg.push({ image: imagesUrl + data?.path });
        });
        // Default Atrraview Set
        temp?.feature_item[0]?.item_values?.forEach((elim) => {
          res?.data?.data?.all_attributes?.forEach((allArr) => {
            if (allArr.id === elim?.category_primary_id) {

              allArr?.attribute_values?.forEach(value => {
                if (value.id === elim.attribute_value_id) {
                  value.default = true;
                  setLoaderShow(false)
                } else {
                  value.default = false;
                  setLoaderShow(false)
                }
              })
            }
          });
        })

      }).catch((err) => {
        console.log(err)
        setLoaderShow(false)
      })
  }

  console.log(defaultID, "defaultIDdefaultID")
  // console.log(attraviewCount)
  const sizearAtraview = (id) => {
    localStorage.setItem("setIsSize", id);
    setSizeDefaultValue(id)
    setAttraviewCount(attraviewCount + 1)
  }

  const coloarAtraview = (id) => {
    setColorDefaultValue(id)
    localStorage.setItem("setIsColor", id);
    setAttraviewCount(attraviewCount + 1)
  }

  const attraviewChages = (id) => {
    // commonGetselectAttrId()
    setLoaderShow(true)
    let selectAttId = []
    let items = [];
    datalist.all_attributes?.forEach((allArr) => {
      if (allArr.id === id.p) {
        allArr?.attribute_values?.forEach(value => {
          if (value.id === id.v) {
            value.default = true;
            setAttraviewCount(attraviewCount + 1)
            setLoaderShow(false)
          } else {
            value.default = false;
            setAttraviewCount(attraviewCount + 1)
            setLoaderShow(false)
          }
        })
      }
    });
    datalist.all_attributes?.forEach((allArr) => {
      allArr?.attribute_values?.forEach(value => {
        if (value.default) {
          console.log(value.id)
          selectAttId.push(value.id)
          setLoaderShow(false)
        }
      })
    });
    console.log(selectAttId)
    productDetails?.primary_attributes?.forEach(obj => {
      const arr = obj?.item_values

      let dataMatch = 0
      arr?.map((check) => {
        const foundId = selectAttId.find(list => list === check.attribute_value_id)
        if (foundId) {
          dataMatch += 1
        }
      })
      if (dataMatch === selectAttId.length) {
        console.log(obj)
        items.push({ ...obj, qnty: 1 })
      }
      setdefaultData(items[0])
      setLoaderShow(false)
    })
  }


  const getDataCart = (e) => {
    if (Number(usertoken) === 0) {
      setOpen(true)
      console.log(usertoken, "usertoken usertoken")
    } else {
      setLoaderShow(true)
      const obj = {
        product_id: e?.product_id,
        product_item_id: e?.item_values[0]?.product_item_id,
        quantity: e?.qnty,
      }
      const check = cartslist?.find((item) =>
        item?.product_id === e?.product_id ? true : false
      );
      console.log(check)
      if (check) {
        toast.error("Products Allready Exis...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoaderShow(false)
      } else {
        axios.post('/customer/cart/add-cart', obj, {
          headers: {
            'Authorization': `bearer ${usertoken}`
          }
        })
          .then(res => {
            console.log(res)
            toast.success("Add Successfully...", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setItemCount(itemCount + 1)
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }).catch(() => {
            setLoaderShow(false)
          })

      }
    }
  }

  // Increnent
  const dataIncriment = (e) => {
    const cartItem = defaultData
    // setLoaderShow(true)
    if (cartItem.id === e & cartItem?.available_quantity > defaultData?.qnty) {
      const data = { ...cartItem, qnty: cartItem.qnty += 1 }
      setdefaultData(data)
      // setLoaderShow(false)
    }
  }
  // Decriment
  const dataDecriment = (e) => {
    const cartItem = defaultData
    // setLoaderShow(true)
    if (cartItem.id === e & cartItem?.qnty > 1) {
      const data = { ...cartItem, qnty: cartItem.qnty - 1 }
      setdefaultData(data)
      // setLoaderShow(false)
    }
  }

  const getBuyNow = (value) => {
    setLoaderShow(true)
    if (Number(usertoken) === 0) {
      setOpen(true)
      setLoaderShow(false)
    } else {
      setOpenBuyNow(true)
      setLoaderShow(false)
    }
  }

  const oderSubmitBuyNow = () => {
    const obj = {
      product_id: defaultData?.product_id,
      product_item_id: defaultData?.item_values[0]?.product_item_id,
      quantity: defaultData?.qnty,
      payment_by: checkId,
    }
    setLoaderShow(true)
    axios.post("/customer/order/direct-order", obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        console.log(res)
        toast.success(res?.data?.message || "Add Successfully...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setOpenBuyNow(false)
        setLoaderShow(false)
      }).catch(() => {
        setLoaderShow(false)
      })
  }

  const wishlistAdd = (p_id) => {
    if (Number(usertoken) === 0) {
      setOpen(true)
    } else {
      const obj = {
        product_id: p_id
      }
      setLoaderShow(true)
      axios.post('/customer/wishlist/add-wishlist', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
        .then(res => {
          if (res.data.code === 200) {
            toast.success(res?.data?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setCount(count + 1)
            setLoaderShow(false)
          }
          if (res.data.code === 404) {
            toast.error(res?.data?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoaderShow(false)
          }
        })
    }
  }
  const wishlistRemove = (p_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = {
          product_id: p_id
        }
        setLoaderShow(true)

        axios.post('/customer/wishlist/single-remove', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
          .then(res => {
            if (res.data.code === 200) {
              // toast.success(, {
              //     position: toast.POSITION.BOTTOM_RIGHT,
              // });
              Swal.fire(
                'Remove!',
                `${res?.data?.message}`,
                'success'
              )
              setCount(count + 1)
              setLoaderShow(false)
            }
            if (res.data.code === 404) {
              toast.error(res?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setLoaderShow(false)
            }
          })

        setTimeout(() => {
          setLoaderShow(false)
        }, 3000)

      }
    })

  }

  console.log(datalist)
  console.log(defaultData)
  console.log(defaultID)
  console.log(colorDefaultValue, "colorDefaultValue colorDefaultValue")


  // console.log(addrssData?.detail_address)
  // console.log(productDetails)
  // console.log(defaultData)
  // console.log(userDetails)
  // console.log(addrssData)


  return (
    <div>
      <Container>
        <Row>
          {datalist?.breadcrumbs?.length > 0 ? <>
            <Col lg={12}>
              <div className='brack_cumes_area brack_cumes_details'>
                <p> {datalist?.breadcrumbs?.map((item, i) =>
                  <Link key={i} onClick={() => setCount(count + 1)} to={`/product-categories/${item?.slug}`}> {item?.name} <span> / </span> </Link>
                )}  </p>
              </div>
            </Col>
          </> : <> </>}
        </Row>
      </Container>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <LoginPages />
        </Box>
      </Modal>

      {/* // Data  */}
      <Modal
        keepMounted
        open={openBuyNow}
        onClose={handleEditClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={editModeal}>

          <div className='content__buy__block'>
            <p> Deliver to: {userDetails?.first_name} {userDetails?.middle_name} {userDetails?.last_name} </p>
            <div className='content__buy__block__box_one'>
              <Badge bg="info" style={{ marginRight: '5px', width: 'auto', float: 'left' }}>Home</Badge>
              <p style={{ marginRight: '5px', fontWeight: 600 }} >{userDetails?.phone_number}</p>
              <p>{addrssData?.detail_address} <br />  <AddresChanges /> </p>
            </div>
            <div className='content__buy__block__box_two'>
              <p style={{ marginRight: '5px', fontWeight: 600 }} >Bill to the same address</p>
              <p>Email to: {userDetails?.email}</p>
            </div>
          </div>
          <div className='content__buy__block mt-2'>
            {productDetails?.active_translation?.length > 0 ? <>
              <p> {productDetails?.active_translation[0]?.name} </p>
            </> : <> </>}
            <div className='content__buy__block__box_one'>
              <div className='d-flex'>
                <div style={{ width: '33%' }}>
                  {/* <img src={imagesUrl + buyNowProducts?.image_path } alt="okk" /> */}
                  <img style={{ width: '30%' }} src={imagesUrl + defaultData?.image_path} alt="images" />
                </div>
                <div style={{ width: '33%' }}>
                  <h3 style={{ fontSize: '14px', textAlign: 'center', paddingTop: '15px' }}> Qnt: {defaultData?.qnty}  </h3>
                </div>
                <div style={{ width: '33%' }}>
                  <h3 style={{ fontSize: '14px', textAlign: 'right', paddingTop: '15px' }}>
                    {defaultData?.discount_price > 0 ? <>
                      ৳{defaultData?.discount_price}
                      <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{defaultData?.selling_price} </del>
                    </> : <>  ৳{defaultData?.selling_price} </>}
                  </h3>
                </div>
              </div>
            </div>
            <div className='content__buy__block__box_two'>
              <div className="payment-options">
                <ul className="sub-total">
                  <li className='payment_options_title'> Payment </li>
                  <li>
                    <div className="shipping">
                      <div className="shopping-option">
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="0"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel value="0" onClick={() => setCheckId("0")} control={<Radio />} label="Cash on delivery" />
                          <FormControlLabel value="1" onClick={() => setCheckId("1")} control={<Radio />} label="Online payment" />
                        </RadioGroup>
                      </div>
                    </div>
                  </li>
                </ul>
                {Number(userDetails?.is_profile_complete) === 1 ? <>
                  <button onClick={oderSubmitBuyNow} className='buy_now_order_submit__btn'> Order Place </button>
                </> : <>
                  <p style={{ fontWeight: 600, color: "#f00", paddingTop: '20px' }}> Please complited your profile </p>
                </>}
              </div>
            </div>
          </div>

        </Box>
      </Modal>

      <Container>
        <div className='product_details_box_area mb-4'>
          <Row>
            <Col lg={5}>
              {defaultData?.image_path?.length > 0 ? <> <img style={{ width: '90%' }} src={imagesUrl + defaultData?.image_path} alt="images" />  </> :
                <>
                  {sliderImg?.length > 0 ? <>
                    <SliderImage
                      data={sliderImg}
                      width="100%"
                      showDescription={true}
                      responsive={true}
                      direction="right"
                    />
                  </> : <> </>}
                </>
              }

            </Col>
            <Col lg={4}>
              <div className='product_details_contant'>
                {productDetails?.active_translation?.length > 0 ? <>
                  <h3> {productDetails?.active_translation[0]?.name} </h3>
                  <span style={{ width: '100%', float: 'left' }}>  Brand:
                    {productDetails?.brand?.active_translation?.map((item, i) =>
                      <b style={{ marginLeft: '5px' }} key={i}>
                        {item?.name}
                      </b>
                    )}
                  </span>
                </> : <> </>}

                <span style={{ width: '100%' }}>  SKU: <b style={{ marginLeft: '5px' }}> {defaultData?.seller_sku}  </b> </span>
                {/* <img src={Pdetails} alt="" /> */}
                {productDetails?.wishlist === null ? <>
                  <button onClick={() => wishlistAdd(defaultData?.product_id)} className='wishlist__add__btnnn'> Add Wishlist </button>
                </> : <>
                  <button onClick={() => wishlistRemove(defaultData?.product_id)} style={{ background: '#b61b20', color: '#fff' }} className='wishlist__add__btnnn'> Wishlist </button>
                </>}

                <p className='availability__'> Availability : {defaultData?.available_quantity > 0 ? <>
                  <b style={{ color: "green" }}> In Stock </b></> : <> <b style={{ color: "red" }}> Out Of Stock </b> </>}</p>
                <div className='price'>
                  {defaultData?.discount_price > 0 ? <>
                    ৳{defaultData?.discount_price}
                    <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{defaultData?.selling_price} </del>
                  </> : <>  ৳{defaultData?.selling_price} </>}
                </div>
                <div className='size_area'>
                  {datalist?.all_attributes?.length > 0 ? <>
                    {datalist?.all_attributes?.map((item, i) =>
                      <div key={i}>
                        <p style={{ width: 'auto' }}> {item?.active_translation[0]?.name} : </p>

                        {item?.attribute_values?.map((color, i) =>
                          <label key={i} className="aiz-megabox pl-0 mr-2">
                            {item?.attribute_type_id === 1 ? <>
                              <input type="radio" onClick={() => attraviewChages({ p: item.id, v: color?.id, })} value={color?.id} checked={color?.default} name={`attribute_id_2${item.id}`} />
                              <span className="aiz-megabox-elem rounded d-flex align-items-center justify-content-center p-1 mb-2">
                                <span className="size-30px d-inline-block rounded" style={{ background: `${color?.color_code}` }}></span>
                              </span>
                            </> : <>
                              <input type="radio" value={color?.id} onClick={() => attraviewChages({ p: item.id, v: color?.id, })} checked={color?.default} name={`attribute_id_2${item.id}`} />
                              <span className="aiz-megabox-elem rounded d-flex align-items-center justify-content-center py-2 px-3 mb-2">
                                {color?.active_translation[0]?.name}
                              </span>
                            </>}
                          </label>
                          // )}
                        )}
                      </div>
                    )}
                  </> : <> </>}

                </div>
                <div className='size_area'>
                  <p> Quantity : </p>
                  <div className='price_qantity'>
                    <button onClick={() => dataDecriment(defaultData?.id)} > <BiMinus /> </button>
                    {/* <form> */}
                    <input type="text" name="quantity" className="col bg-transparent border-0 text-center flex-grow-1 fs-16 input-number" value={defaultData?.qnty} />
                    {/* </form> */}
                    <button onClick={() => dataIncriment(defaultData?.id)}> <BiPlus /> </button>
                  </div>
                  <h6> ({defaultData?.available_quantity} available)  </h6>
                </div>
                <div className='order_button'>
                  {/* <button onClick={() => getBuyNow(defaultData)}>  </button> */}
                  {defaultData?.available_quantity > 0 ? <>
                    <button onClick={() => getBuyNow(datalist)}>
                      <span className='button_login'>Buy Now </span>
                    </button>

                    <button disabled={loading} onClick={() => getDataCart(defaultData)} style={{ background: "#b61b20" }}>
                      {loading && <span className='disabled__ '> Submit...</span>}
                      {!loading && <span className='button_login'>Add to Cart </span>}
                    </button>
                  </> : <> </>}
                </div>
              </div>
            </Col>
            <Col lg={3}>
              {/* {addrssData?.detail_address === null ? <> </> : <> </>} */}
              <div className='delivery_ch_box'>
                <h5> Delivery </h5>
                {/* <div className='delivery_charg_box_list'>
                  {addrssData?.detail_address === undefined ? <>
                    <RiMapPin2Line />
                    <h5> Please Set You address <br /> <span>  <AddresChanges />  </span> </h5>
                  </> : <>
                    <RiMapPin2Line />
                    <p> {addrssData?.detail_address} <br /> <span>  <AddresChanges /> </span> </p>
                  </>}

                </div> */}
                <div className='delivery_charg_box_list delivery_charg_box_list_two'>
                  <RiBikeFill />
                  <h3> Home Delivery <br></br> <span> 2 - 4 day(s) <b> ৳ 50 </b> </span> </h3>
                </div>
                <div className='delivery_charg_box_list delivery_charg_box_list_two'>
                  <RiMoneyDollarCircleLine />
                  <h3 className='pt-2'> Cash on Delivery Available </h3>
                </div>
                <h5 className='mt-3'> Services </h5>
                <div className='delivery_charg_box_list delivery_charg_box_list_two'>
                  <RiFocus2Line />
                  <h3> 7 Days Returns <br></br> <span> Change of mind is not applicable </span> </h3>
                </div>
                <div className='delivery_charg_box_list delivery_charg_box_list_two'>
                  <RiSendPlaneLine />
                  <h3 className='pt-2'> Warranty not available </h3>
                </div>
              </div>
              <div className='delivery_ch_box mt-3'>
                <h6> Sold by </h6>
                <Link to=""> Seller Name </Link>
                <Link className='visit_store_link' to="/"> Visit Store </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container>
        <div className=' mb-4'>
          <Row>
            <Col lg={12}>
              <div className='brack_cumes_area brack_cumes_details' style={{ marginTop: "0px" }}>
                <h5 className='mb-3' style={{ background: "#f5f5f5", padding: "15px" }}> {productDetails?.inside_box} </h5>
                <div className='product__descrip_list' dangerouslySetInnerHTML={{ __html: productDetails?.long_description }} />
              </div>
            </Col>
            {datalist?.related_products?.data?.length > 0 ? <>
              {datalist?.related_products?.data.slice(0, visible).map((item, i) =>
                <Col lg={2} className="d-flex align-items-stretch mt-3" key={i}>
                  <Link className='card' to={`/product-details/${item?.slug}`}>
                    <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                      <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                      <div className='product_hone_box_text'>
                        <p> {item?.active_translation[0]?.name.substring(0, 50)}</p>
                      </div>
                      <div className='price__'> ৳ {item?.feature_item[0]?.discount_price > 0 ? <>
                        ৳{item?.feature_item[0]?.discount_price}
                        <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                      </> : <>  ৳{item?.feature_item[0]?.selling_price} </>} </div>
                      <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                    </div>
                  </Link>
                </Col>
              )}
              <Col lg={12}>
                {datalist?.related_products?.data?.length > visible ? <>
                  <button className="loadMore__btn" type="button"> Load More </button> </> :
                  <>  </>}
              </Col>
            </> : <>

            </>}
            <Loader
              loaderShow={loaderShow}
              loaderHide={loaderShow}
            />
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default ProductDetails