import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import LanguageContext from "../context/LanguageProvider";
import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
import { imagesUrl } from '../helper/Url';
import { VscTrash } from "react-icons/vsc";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Empty } from 'antd';

function WishlistPages() {
    const { language } = useContext(LanguageContext);
    const [data, setData] = useState([])
    const [count, setCount] = useState(0)
    let usertoken = getCookie("token");
    useEffect(() => {
        axios.get(`/customer/wishlist/list-wishlist?active_language=${language}`, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                setData(res.data.data.wishlists.data)
            })
    }, [count])
    // Single Wishlist
    const wishlistRemove = (p_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const obj = {
                    product_id: p_id
                }
                axios.post('/customer/wishlist/single-remove', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
                    .then(res => {
                        if (res.data.code === 200) {
                            // toast.success(, {
                            //     position: toast.POSITION.BOTTOM_RIGHT,
                            // });
                            Swal.fire(
                                'Deleted!',
                                `${res?.data?.message}`,
                                'success'
                            )
                            setCount(count + 1)
                        }
                        if (res.data.code === 404) {
                            toast.error(res?.data?.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                    })

            }
        })

    }
    // Single Wishlist
    const multypleWishlistRemove = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/customer/wishlist/clear-my-wishlist', { headers: { 'Authorization': `bearer ${usertoken}` } })
                    .then(res => {
                        if (res.data.code === 200) {
                            // toast.success(, {
                            //     position: toast.POSITION.BOTTOM_RIGHT,
                            // });
                            Swal.fire(
                                'Deleted!',
                                `${res?.data?.message}`,
                                'success'
                            )
                            setCount(count + 1)
                        }
                        if (res.data.code === 404) {
                            toast.error(res?.data?.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                        if (res.data.code === 401) {
                            toast.error(res?.data?.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                    })

            } else {

            }
        })

    }
    return (
        <div>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="card-block theme-form theme_form__wishlist">
                            <h5 className='add_to_cart__title add_to_cart__title__wishlist'>My Wichlist
                                {data?.length > 0 ? <>
                                    <button onClick={multypleWishlistRemove} className='add_to_cart__title__wishlist_button'> Clear Wishlist </button>
                                </> : <> </>}
                            </h5>
                            <Row>
                                {data?.length > 0 ? <>
                                    {data?.map((item, i) =>
                                        <Col lg={2} className="d-flex align-items-stretch mt-3" key={i}>
                                            <div className='card'>
                                                <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                                    {/* <Link to="/product-details">slug */}
                                                    <Link to={`/product-details/${item?.product?.slug}`}>
                                                        <img className='img-fit' src={imagesUrl + item?.product?.thumbnail_image} alt="" />
                                                    </Link>
                                                    <Link to={`/product-details/${item?.product?.slug}`} className='product_hone_box_text'>
                                                        <p> {item?.product?.active_translation[0]?.name?.substring(0, 50)}</p>
                                                    </Link>
                                                    <div className='price__'>
                                                        {item?.product?.feature_item[0]?.discount_price > 0 ? <>
                                                            ৳{item?.product?.feature_item[0]?.discount_price}
                                                            <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.product?.feature_item[0]?.selling_price} </del>
                                                        </> : <>  ৳{item?.product?.feature_item[0]?.selling_price} </>}
                                                    </div>
                                                    <button onClick={() => wishlistRemove(item?.product_id)} className='price__cart__btn'> <VscTrash /> </button>
                                                    <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                                </div>
                                            </div>

                                        </Col>
                                    )}
                                </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WishlistPages