import React, { useEffect, useContext, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { VscChromeClose } from "react-icons/vsc";
import { useSelector, useDispatch } from "react-redux";
import { Empty } from 'antd';
import { toast } from "react-toastify";
import { imagesUrl } from '../helper/Url';
import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
import { ADD_ITEM, getCartTotal } from "../reduxServices/actions/CartActions";
import LanguageContext from "../context/LanguageProvider";
import Swal from "sweetalert2"
import Loader from './loader'

// import { increase, decrease, remove, getCartTotal } from "../reduxServices/actions/CartActions";
function ViewCart() {
    const dispatch = useDispatch()
    let usertoken = getCookie("token");
    const { language } = useContext(LanguageContext);
    const cartslist = useSelector((state) => state.cartItems.carts);
    const totalAmount = useSelector((state) => state.cartItems?.totalAmount?.totalAmount);
    const [count, setCount] = useState(0);
    const [loaderShow, setLoaderShow] = useState(false)
    console.log(cartslist, "cartslistcartslist")
    // Incriment
    const productInc = (pId) => {
        const cartItem = cartslist?.find(data => data.product_id === pId)
        if (cartItem.product_id === pId & cartItem?.product_item?.available_quantity > cartItem?.quantity) {
            const qnty = cartItem.quantity += 1
            const obj = {
                cart_id: Number(cartItem.id),
                quantity: Number(qnty)
            }
            setLoaderShow(true)
            axios.post("/customer/cart/edit-cart", obj, {
                headers: {
                    'Authorization': `bearer ${usertoken}`
                }
            })
                .then(res => {
                    axios.get("/customer/cart/list-cart", { headers: { 'Authorization': `bearer ${usertoken}` } })
                        .then(res => {
                            dispatch(ADD_ITEM(res?.data?.data?.carts))
                        })
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setLoaderShow(false)
                }).catch(()=>{
                    setLoaderShow(false)
                })
        }
    }
    // Decriment
    const productDnc = (pId) => {
        const cartItem = cartslist?.find(data => data.product_id === pId)
        if (cartItem.product_id === pId & cartItem?.product_item?.available_quantity > cartItem?.quantity) {
            const qnty = cartItem.quantity - 1
            const obj = {
                cart_id: Number(cartItem.id),
                quantity: Number(qnty)
            }
            setLoaderShow(true)
            axios.post("/customer/cart/edit-cart", obj, {
                headers: {
                    'Authorization': `bearer ${usertoken}`
                }
            })
                .then(res => {
                    axios.get("/customer/cart/list-cart", { headers: { 'Authorization': `bearer ${usertoken}` } })
                        .then(res => {
                            dispatch(ADD_ITEM(res?.data?.data?.carts))
                        })
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setLoaderShow(false)
                }).catch(()=>{
                    setLoaderShow(false)
                })
        }
    }
    // Item Remove
    const itemDelect = (e) => {
        console.log('e')
        // alert(`İşlemde  : ${props.message}`)
        Swal.fire({
            title: `Are you sure to want to delete this item `,
            type: "warning",
            icon: 'warning',
            footer: "",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            }
        }).then((result) => {
            console.log(result, "result")
            if (result.isConfirmed === true) {
                const obj = {
                    cart_ids: [e]
                }
                setLoaderShow(true)
                setTimeout(() => {
                    setLoaderShow(false)
                }, 3000)
                // axios.post(`/customer/cart/remove-cart?cart_ids=${e}`, { headers: { 'Authorization': `bearer ${usertoken}` } })
                axios.post('/customer/cart/remove-cart', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
                    .then(res => {
                        console.log(res)
                        if (res?.data?.code === 401) {
                            toast.error(res?.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            })
                            setLoaderShow(false)
                        }
                        if (res?.data?.code === 200) {
                            toast.success(res?.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            })
                            setCount(count + 1)
                            setLoaderShow(false)
                        }
                    })
            }
        })
    }
    // Total Amount
    useEffect(() => {
        dispatch(getCartTotal());
    }, [cartslist]);

    useEffect(() => {
        // axios.get("/customer/cart/list-cart", { headers: { 'Authorization': `bearer ${usertoken}` } })
        setLoaderShow(true)
        axios.get(`/customer/cart/list-cart?active_language=${language}`, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                dispatch(ADD_ITEM(res?.data?.data?.carts))
                setLoaderShow(false)
            })
    }, [count]);

    return (
        <div>
            {/* <Container>
                <Row>
                    <Col lg={12}>
                        <div className='brack_cumes_area brack_cumes_details'>
                            <p> <Link to="/"> Cart </Link> / <Link to="/">Home </Link> / <span> Cart </span>  </p>
                        </div>
                    </Col>
                </Row>
            </Container> */}
            <Container>
                <Row>
                    <Col lg={12} style={{ marginBottom: "30px" }}>
                        <div className="card table-card  contact-page">
                            {/* <div className="card-header">
                                <h5>Add To Cart</h5>
                            </div> */}
                            <Row>
                                <Col lg={8}>
                                    <div className="card-block theme-form">
                                        <h5 className='add_to_cart__title'>Add To Cart List</h5>
                                        <div className="table-responsive">
                                            {cartslist?.length > 0 ? <>
                                                <table className="table table-hover m-b-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Sl</th>
                                                            <th>Product Name</th>
                                                            <th>Image</th>
                                                            <th>Price</th>
                                                            <th>Quantity</th>
                                                            <th>Remove</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {cartslist?.map((item, i) =>
                                                            <tr key={i}>
                                                                <td style={{ textAlign: 'center' }}>{i + 1}</td>
                                                                <td style={{ width: '220px' }}>{item?.product?.active_translation[0]?.name}</td>
                                                                <td><img src={imagesUrl + item?.product_item?.image_path} alt="" className="img-fluid img__width" /></td>
                                                                <td>
                                                                    {item?.product_item?.discount_price > 0 ? <>
                                                                        ৳{item?.product_item?.discount_price}
                                                                        <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.product_item?.selling_price} </del>
                                                                    </> : <>  ৳{item?.product_item?.selling_price} </>}
                                                                </td>
                                                                <td>
                                                                    <div className="addit-box">
                                                                        <div className="qty-box">
                                                                            <div className="input-group">
                                                                                <button onClick={() => productDnc(item?.product_id)} className="qty-minus">-</button>
                                                                                <input className="qty-adj form-control" type="number" value={item?.quantity} />
                                                                                <button onClick={() => productInc(item?.product_id)} className="qty-plus">+</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}><VscChromeClose onClick={() => itemDelect(item?.id)} /></td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    {cartslist?.length > 0 ? <>
                                        <div className='orderSummary__cart theme-form'>
                                            <h3> Order Summary </h3>
                                            <p> Preferred Delivery Option </p>
                                            <p> Availability and promotions will be shown here </p>
                                            <h3 style={{ paddingTop: '10px' }}> Total: ৳ {new Intl.NumberFormat().format(totalAmount)} </h3>
                                            <Link to="/checkout">PROCEED TO CHECKOUT</Link>
                                        </div>
                                    </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                </Col>
                            </Row>
                            <Loader
                                loaderShow={loaderShow}
                                loaderHide={loaderShow}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ViewCart