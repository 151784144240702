import React, { useRef, useState, useEffect } from 'react'
import { Drawer } from 'antd';
// import { BiMinus, BiPlus } from "react-icons/bi";
import ImgOne from "../assets/images/1.jpg"
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Empty } from 'antd';
import { imagesUrl } from '../helper/Url';
import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
import { toast } from "react-toastify";
import { ADD_ITEM, getCartTotal } from "../reduxServices/actions/CartActions";
import { VscTrash } from "react-icons/vsc";
import Swal from "sweetalert2"
import { VscClippy } from "react-icons/vsc";
import Loader from '../Pages/loader'
import { TramRounded } from '@mui/icons-material';
function CartComponents() {
    const dispatch = useDispatch()
    let usertoken = getCookie("token");
    const [count, setCount] = useState(0);
    const [visible, setVisible] = useState(false);
    const [loaderShow, setLoaderShow] = useState(false)
    // const [cartslist, setCartslist] = useState([]);
    const showDrawer = () => {
        setVisible(true);
    };

    console.log(useRef)

    const onClose = () => {
        setVisible(false);
    };
    const drawerClose = () => {
        setVisible(false);
    };
    useEffect(() => {
        // const cartslist = useSelector((state) => state.cartItems.carts);
        // console.log(cartslist, "cartslist cartslist")
    }, [])
    const cartslist = useSelector((state) => state.cartItems.carts);
    const totalAmount = useSelector((state) => state.cartItems?.totalAmount?.totalAmount);

    const productInc = (pId) => {
        const cartItem = cartslist?.find(data => data.product_id === pId)
        if (cartItem.product_id === pId & cartItem?.product_item?.available_quantity > cartItem?.quantity) {
            const qnty = cartItem.quantity += 1
            const obj = {
                cart_id: Number(cartItem.id),
                quantity: Number(qnty)
            }
            setLoaderShow(true)
            axios.post("/customer/cart/edit-cart", obj, {
                headers: {
                    'Authorization': `bearer ${usertoken}`
                }
            })
                .then(res => {
                    axios.get("/customer/cart/list-cart", { headers: { 'Authorization': `bearer ${usertoken}` } })
                        .then(res => {
                            dispatch(ADD_ITEM(res?.data?.data?.carts))
                        })
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setLoaderShow(false)
                }).catch(()=>{
                    setLoaderShow(false)
                })
        }
    }
    const productDnc = (pId) => {
        const cartItem = cartslist?.find(data => data.product_id === pId)
        if (cartItem.product_id === pId & cartItem?.product_item?.available_quantity > cartItem?.quantity) {
            const qnty = cartItem.quantity - 1
            const obj = {
                cart_id: Number(cartItem.id),
                quantity: Number(qnty)
            }
            setLoaderShow(true)
            axios.post("/customer/cart/edit-cart", obj, {
                headers: {
                    'Authorization': `bearer ${usertoken}`
                }
            })
                .then(res => {
                    axios.get("/customer/cart/list-cart", { headers: { 'Authorization': `bearer ${usertoken}` } })
                        .then(res => {
                            dispatch(ADD_ITEM(res?.data?.data?.carts))
                        })
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setLoaderShow(false)
                }).catch(()=>{
                    setLoaderShow(false)
                })
        }
    }
    useEffect(() => {
        dispatch(getCartTotal());
    }, [cartslist]);

    useEffect(() => {
        setLoaderShow(true)
        axios.get("/customer/cart/list-cart", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                dispatch(ADD_ITEM(res?.data?.data?.carts))
                setLoaderShow(false)
            }).catch(()=>{
                setLoaderShow(false)
            })
    }, [count]);


    const allDataCleare = () => {
        setLoaderShow(TramRounded)
        const config = {
            headers: { Authorization: `bearer ${usertoken}` }
        };
        axios.post("/customer/cart/clear-my-cart", config)
            .then(res => {
                console.log(res)
                if (res?.data?.code === 401) {
                    toast.error(res?.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    setLoaderShow(false)
                }
                if (res?.data?.code === 200) {
                    toast.success(res?.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    setCount(count + 1)
                    setLoaderShow(false)
                }
            })
    }

    const itemDelect = (e) => {
        console.log('e')
        // alert(`İşlemde  : ${props.message}`)
        Swal.fire({
            title: `Are you sure to want to delete this item `,
            type: "warning",
            icon: 'warning',
            footer: "",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            }
        }).then((result) => {
            console.log(result, "result")
            if (result.isConfirmed === true) {
                const obj = {
                    cart_ids: [e]
                }
                setLoaderShow(true)
                setTimeout(() => {
                    setLoaderShow(false)
                }, 3000)
                console.log(obj, "90909090")
                // axios.post(`/customer/cart/remove-cart?cart_ids=${e}`, { headers: { 'Authorization': `bearer ${usertoken}` } })
                axios.post('/customer/cart/remove-cart', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
                    .then(res => {
                        console.log(res)
                        if (res?.data?.code === 200) {
                            toast.success(res?.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            })
                            setCount(count + 1)
                            setLoaderShow(false)
                        } else {
                            toast.error(res?.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            })
                            setLoaderShow(false)
                        }
                    })
            }
        })
    }
    console.log(cartslist, "cartslist cartslist")
    console.log(totalAmount, "cartslist cartslist")
    console.log(usertoken, "cartslist cartslist")

    return (
        <div>
            <div onClick={showDrawer} className='header_right_area_item'>
                <div className='login-icon cart-icon-unsignavatar'></div>
                <p>  Cart {cartslist?.length > 0 ? <> <span className='cart_span_number'> {cartslist?.length} </span> </> : <> </>} </p>
            </div>
            <div onClick={showDrawer} style={{position: 'relative'}} className=' footer__fixt__box'>
                <Link to="/">
                    <VscClippy />
                    <h6> Cart {cartslist?.length > 0 ? <> <span className='cart_span_number'> {cartslist?.length} </span> </> : <> </>} </h6>
                </Link>
            </div>
            <Drawer title="My Cart" placement="right" onClose={onClose} visible={visible}>
                <button className='clear__btn' onClick={() => allDataCleare()}> Clear All  </button>
                <div id="cart_side" className="add_to_cart right open-side">
                    <div className="cart-inner">
                        <div className="cart_media">
                            {cartslist?.length > 0 ? <>
                                <ul className="cart_product">
                                    {cartslist?.map((item, i) =>
                                        <li key={i}>
                                            <div className="media">
                                                <img alt="megastore1" className="me-3" src={imagesUrl + item?.product_item?.image_path} />
                                                <div className="media-body">
                                                    <h4>{item?.product?.active_translation[0]?.name}</h4>
                                                    <h6>{item?.product_item?.discount_price > 0 ? <>
                                                        ৳{item?.product_item?.discount_price}
                                                        <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.product_item?.selling_price} </del>
                                                    </> : <>  ৳{item?.product_item?.selling_price} </>}
                                                    </h6>
                                                    <div className="addit-box">
                                                        <div className="qty-box">
                                                            <div className="input-group">
                                                                <button onClick={() => productDnc(item?.product_id)} className="qty-minus">-</button>
                                                                <input className="qty-adj form-control" type="number" value={item?.quantity} />
                                                                <button onClick={() => productInc(item?.product_id)} className="qty-plus">+</button>
                                                            </div>
                                                            <VscTrash onClick={() => itemDelect(item?.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                                <ul className="cart_total">
                                    <li>
                                        <div className="total">
                                            total<span>৳ {new Intl.NumberFormat().format(totalAmount)}/=</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="buttons">
                                            <Link onClick={drawerClose} to="/cart" className="btn btn-solid btn-sm"> View cart </Link>
                                            <Link onClick={drawerClose} to="/checkout" className="btn btn-solid btn-sm">Checkout</Link>
                                        </div>
                                    </li>
                                </ul>
                            </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                            <Loader
                                loaderShow={loaderShow}
                                loaderHide={loaderShow}
                            />
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default CartComponents