import React, { useEffect, useState, useContext } from 'react'
import axios from '../../helper/Api';
import { Link, useParams, useLocation } from 'react-router-dom'
import { imagesUrl } from '../../helper/Url';
import SellerBanner from "../../Components/SellerComponents/SellerBanner"
import SellerLogo from "../../Components/SellerComponents/SellerLogo"
import SellerTab from "../../Components/SellerComponents/SellerTab"

import LanguageContext from "../../context/LanguageProvider";
// template list 
import SellerThemOne from "./SellerThemOne"
import SellerThemTwo from "./SellerThemTwo"
import SellerThemThree from "./SellerThemThree"
import SellerThemFour from "./SellerThemFour"

function SellerHome() {
  const { slug } = useParams()
  const { language } = useContext(LanguageContext);
  const [sellerList, setSellerList] = useState([])
  useEffect(() => {
    axios.get(`/frontend/public-api/top-seller-product/${slug}?active_language=${language}`)
      .then(res => {
        setSellerList(res?.data?.data)
        console.log(res, "999999999")
      })
  }, [language])
  console.log(sellerList)
  return (
    <div>
      {sellerList?.seller_details?.seller_template === 1 ? <SellerThemOne allData={sellerList} /> : sellerList?.seller_details?.seller_template === 2 ? <SellerThemTwo allData={sellerList} /> : sellerList?.seller_details?.seller_template === 3 ? <SellerThemThree allData={sellerList} /> : sellerList?.seller_details?.seller_template === 4 ? <SellerThemFour allData={sellerList} /> : sellerList?.seller_details?.seller_template === 5 ? <SellerThemOne allData={sellerList} /> :<> </>  }
    </div>
  )
}

export default SellerHome 