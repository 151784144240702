import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Checkbox } from 'antd'
import { Link } from 'react-router-dom'
// import { VscChromeRestore } from "react-icons/vsc";
import data from "../data.json"
import { Select, Modal } from 'antd';
import Iframe from 'react-iframe'
const { Option } = Select;
const handleChange = (value) => {
    console.log(`selected ${value}`);
};

function MakeupAll() {
    const [visible, setVisible] = useState(8);
    const loadmore = () => {
        setVisible((prevValue) => prevValue + 4);
    }
    const [visibleModel, setVisibleModel] = useState(false);
    const modelClose = () => {
        setVisibleModel(false)
        window.location.reload(false)
    }

    return (
        <div className='product_categories_area'>
            <Container>
                <Row>
                    <Col lg={3}>
                        <div className='product_categories_left_sidbar'>
                            <h3> Related Categories </h3>
                            <p> Women's Dresses </p>
                            <div className='checkbox__area'>
                                <h5> Brand </h5>
                                <ul>
                                    <li> <Checkbox >Stone Rose</Checkbox> </li>
                                    <li> <Checkbox >City Boy</Checkbox> </li>
                                    <li> <Checkbox >Fabrilife</Checkbox> </li>
                                    <li> <Checkbox >Goodman</Checkbox> </li>
                                    <li> <Checkbox >LEEBAS Clothing</Checkbox> </li>
                                    <li> <Checkbox >CITIZEN GLAMOUR</Checkbox> </li>
                                </ul>
                            </div>
                            <div className='checkbox__area'>
                                <h5> Service </h5>
                                <ul>
                                    <li> <Checkbox >Installment</Checkbox> </li>
                                    <li> <Checkbox >Cash On Delivery</Checkbox> </li>
                                    <li> <Checkbox >Free Shipping</Checkbox> </li>
                                </ul>
                            </div>
                            <div className='checkbox__area'>
                                <h5> Location </h5>
                                <ul>
                                    <li> <Checkbox >Bangladesh</Checkbox> </li>
                                </ul>
                            </div>
                            <div className='checkbox__area'>
                                <h5> Size </h5>
                                <ul>
                                    <li> <Checkbox >S</Checkbox> </li>
                                    <li> <Checkbox >M</Checkbox> </li>
                                    <li> <Checkbox >L</Checkbox> </li>
                                    <li> <Checkbox >XL</Checkbox> </li>
                                    <li> <Checkbox >XXL</Checkbox> </li>
                                </ul>
                            </div>
                            <div className='checkbox__area'>
                                <h5> Color Family </h5>
                                <ul>
                                    <li> <Checkbox >Black</Checkbox> </li>
                                    <li> <Checkbox >White</Checkbox> </li>
                                    <li> <Checkbox >Navy Blue</Checkbox> </li>
                                    <li> <Checkbox >Multicolor</Checkbox> </li>
                                    <li> <Checkbox >Red</Checkbox> </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <Col lg={8}>
                                <div className='brack_cumes_area'>
                                    <h3> Eid Festival Jewelry  </h3>
                                    <p> <Link to="/"> Home </Link> / <Link to="/"> All Categories </Link> / <span> Eid Festival Shirt </span>  </p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className='brack_cumes_area'>
                                    <Select defaultValue="Sort by" style={{ width: 200, float: "right", marginRight: "15px" }} onChange={handleChange}>
                                        <Option value="Newest">Newest</Option>
                                        <Option value="low">Price low to high</Option>
                                        <Option value="high">Price high to low</Option>
                                    </Select>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className='three_catagori_box'>
                                    {/* <p> <VscChromeRestore /> Just For You <Link to="/"> View All </Link> </p> */}
                                    <Row>
                                        {data.mackeup.slice(0, visible).map((item, i) =>
                                            <Col lg={3} className="d-flex align-items-stretch mt-3" key={i}>
                                                {/* <Link className='card' to="/product-details" onClick={() => setVisibleModel(true)}> */}
                                                <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>

                                                    <img onClick={() => setVisibleModel(true)} className='img-fit' src={item.images} alt="" />
                                                    <div className='product_hone_box_text'>
                                                        <p> {item.description}</p>
                                                    </div>
                                                    <div className='price__'> ৳ {item.price} </div>
                                                    <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                                </div>
                                                {/* </Link> */}
                                            </Col>
                                        )}
                                        <Col lg={12}>
                                            {data.jewelry.length > visible ? <>
                                                <button className="loadMore__btn" type="button" onClick={loadmore}> Load More </button> </> :
                                                <>  </>}
                                        </Col>
                                        <Modal
                                            title="Modal 1000px width"
                                            centered
                                            visible={visibleModel}
                                            onOk={() => setVisibleModel(false)}
                                            onCancel={() => modelClose()}
                                            width={1100}
                                        >
                                            <Iframe url="http://localhost/makeup"
                                                width="100%"
                                                height="500px"
                                                id="myId"
                                                allow="camera; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                className="myClassname"
                                                display="initial"
                                                allowfullscreen
                                                position="relative" />
                                        </Modal>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MakeupAll
