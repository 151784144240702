import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./i18nextInit";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Route } from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
import { LanguageProvider } from './context/LanguageProvider';
import Root from './reduxServices/Root';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback="...">
    <LanguageProvider>
        <Root>
          <Route>
            <ScrollToTop />
            <App />
          </Route>
        </Root>
    </LanguageProvider>
  </Suspense>
);

reportWebVitals();
