import React from 'react'
import MUIDataTable from "mui-datatables";
import { Row, Col } from 'react-bootstrap'
const columns = [
    {
        name: "order",
        label: "Order #",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "placedon",
        label: "Placed On",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "items",
        label: "Items",
        options: {
            customBodyRender: () => {
                return (
                    <img style={{width: "50px", height: "50px"}} src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80" alt="" />
                )
            }
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const data = [
    { order: "629348309910456", placedon: "03/07/2022", items: "Yonkers", total: "৳ 340" },
    { order: "628999369910456", placedon: "25/06/2022", items: "Yonkers", total: "৳ 86" },
    { order: "628995180110456", placedon: "25/06/2022", items: "Yonkers", total: "৳ 100" }
]

const options = {
    filterType: 'checkbox',
    // responsive: 'stacked',
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30, 50, 100]
}
function ManageAccount() {
    return (
        <div>
            <Row>
                <Col lg="4" className='d-flex align-items-stretch'>
                    <div className='card personal_profile_area'>
                        <h3> Personal Profile <button> Edit </button> </h3>
                        <p> Customar Name </p>
                        <p> cunsomer@gmail.com </p>
                        <h6> Subscribe to our Newsletter </h6>
                    </div>
                </Col>
                <Col lg="8">
                    <div className='card personal_profile_area'>
                        <h3> Address Book <button> Edit </button> </h3>
                        <div className='default_shipping_address'>
                            <span> DEFAULT SHIPPING ADDRESS </span>
                            <h5> Customar Name </h5>
                            <p> House#07, Road#01, Block#B, Gulshan-Niketon , Dhaka
                                Dhaka - Dhaka - North - Niketon
                                (+880) 1985834220 </p>
                        </div>
                        <div className='default_shipping_address'>
                            <span> DEFAULT BILLING ADDRESS </span>
                            <h5> Customar Name </h5>
                            <p> House#07, Road#01, Block#B, Gulshan-Niketon , Dhaka
                                Dhaka - Dhaka - North - Niketon
                                (+880) 1985834220 </p>
                        </div>
                    </div>
                </Col>
                <Col lg="12" className='mt-4'>
                    <MUIDataTable
                        title={"Recent Orders"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ManageAccount