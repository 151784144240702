import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
import { imagesUrl } from '../helper/Url';
import moment from 'moment'
import Badge from 'react-bootstrap/Badge';
function OrderDetails() {
    const { slug } = useParams()
    let usertoken = getCookie("token");
    const [orderDetails, setOrderDetails] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [userFullDetails, setUserFullDetails] = useState([]);
    useEffect(() => {
        // Country List
        axios.get(`/customer/order/my-order?page=1`, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                // console.log(res)
                // res?.data?.data?.orders?.data
                const filtered = res?.data?.data?.orders?.data.filter(obj => {
                    return obj.order_id === slug;
                });
                setOrderDetails(filtered)
            })
        axios.get("/customer/get-address", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                setUserDetails(res?.data?.data?.customerProfileAddressData)
            })

        axios.get("/customer/get-user-details", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                setUserFullDetails(res?.data?.data?.user_details)
            })
    }, []);
    console.log(orderDetails)
    console.log(userDetails)
    console.log(userFullDetails)
    return (
        <div className='order__details__area'>
            <Container>
                <Row>
                    <Col lg={12} className="mb-2">
                        <div className='box__one__order__details'>
                            <p> Order : {slug}   <br /> Placed on {moment(orderDetails[0]?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                            <h3> Total: ৳ {orderDetails[0]?.total_payable_amount} </h3>
                        </div>
                    </Col>
                    {orderDetails[0]?.items?.map((item, i) =>
                        <Col lg={12} className="mt-2">
                            <div className='box__one__order__details'>
                                <img className='order__imgesss' style={{ float: 'left' }} src={imagesUrl + item?.product_item?.image_path} alt="" />
                                <h5> Name: {item?.product?.active_translation[0].name} </h5>
                                <h5 className='box__one__order__details_h2'> Price:

                                    {item?.product_item?.discount_price > 0 ? <>
                                        ৳{item?.product_item?.discount_price}
                                        <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.product_item?.selling_price} </del>
                                    </> : <>  ৳{item?.product_item?.selling_price} </>}
                                </h5>
                                <h5 className='box__one__order__details_h5_10'> Qnty: {item?.quantity} </h5>
                                {/* {item?.product_item?.item_values?.leanth > 0 ? <> */}
                                {item?.product_item?.item_values?.map((attr, i) =>
                                    <h5 key={i} className="box__one__order__details_h5_15"> {attr?.category_primary?.active_translation[0].name}: <Badge bg="light" text="dark">
                                        {attr?.attribute_value?.active_translation[0].name}
                                    </Badge>  </h5>
                                )}
                                {/* </> : <> </>} */}
                            </div>
                        </Col>
                    )}
                </Row>
                <div className='dddddddd'>
                <Row>
                    <Col lg={6} className="mt-4">
                        <div style={{ width: '100%' }} className='box__one__order__details'>
                            <div style={{ background: '#fff' }} className='box__one__order__details__last new_box_order____data'>
                                <p> <span style={{ fontWeight: '600' }}> Shipping Address </span>  <br /> {userFullDetails?.first_name} {userFullDetails?.middle_name} {userFullDetails?.last_name} <br /> {userFullDetails?.email} <br /> {userDetails?.detail_address} <br /> {userFullDetails?.phone_number} </p>
                            </div>

                        </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                        <div style={{ width: '100%' }} className='box__one__order__details'>
                            <div style={{ background: '#fff', marginRight: '15px' }} className='box__one__order__details__last'>
                                <p style={{ width: '100%' }}> <span style={{ fontWeight: '600', width: '100%' }}> Total Summary </span>  </p>
                                <h5 style={{ width: '100%', padding: '0px', marginTop: '5px' }}>  Subtotal : <span> ৳ {orderDetails[0]?.total_item_amount} </span> </h5>
                                <h5 style={{ width: '100%', padding: '0px', marginTop: '5px' }}>  Delivery Fee : <span> 50 </span> </h5>
                                {/* <h5 className='box__one__order__details__last_h5'>  Total : <span> ৳ {orderDetails[0]?.total_payable_amount} + {50 } </span> </h5> */}
                                <h5 className='box__one__order__details__last_h5'>  Total : <span> ৳ {Number(orderDetails[0]?.total_payable_amount) + 50} </span> </h5>
                                <p style={{ width: '100%' }}> Paid by Cash on Delivery </p>
                            </div>

                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
        </div>
    )
}

export default OrderDetails