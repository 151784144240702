import { Button, Form, Input, DatePicker, Radio } from 'antd';
import React from 'react';
import { Row, Col } from 'react-bootstrap'
function MyProfile() {
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const [form] = Form.useForm();
    form.setFieldsValue({
        fullname: "Cutomer Name",
        email: "customer@gmail.com",
        mobile: "01985834220",
    });
    return (
        <div className='myProfile___area'>
            <Form
                name="basic"
                layout="vertical"
                form={form}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row>
                    <Col lg={6}>
                        <Form.Item
                            label="Full name"
                            name="fullname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Full name!',
                                },
                            ]}
                        >
                            <Input value="Cutomer Name" />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="Mobile"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Mobile!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="Birthday"
                            name="birthday"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Birthday!',
                                },
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Gender!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={1}>Male</Radio>
                                <Radio value={2}>Female</Radio>
                                <Radio value={3}>Others</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item>
                            <Button className='mt-4' type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button className='mt-4' type="primary" htmlType="submit">
                                Change Password
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default MyProfile