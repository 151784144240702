import { Container, Row, Col } from 'react-bootstrap'
import React from 'react'
import { VscChromeRestore } from "react-icons/vsc";
import Sunglasses from "./Sunglasses"
import Jewelry from "./Jewelry"
import Mackup from "./Mackup"
function VirtulTry() {
  return (
    <div className='mt-4'>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='three_catagori_box'>
                            <p> <VscChromeRestore /> Virtual Try Services  </p>
                            <Row>
                                <Col lg={4} className="mt-2">
                                    <Sunglasses />
                                </Col>
                                <Col lg={4} className="mt-2">
                                    <Jewelry />
                                </Col>
                                <Col lg={4} className="mt-2">
                                    <Mackup />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
  )
}

export default VirtulTry