import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import LanguageContext from "../context/LanguageProvider";
import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
import { imagesUrl } from '../helper/Url';
import { VscTrash } from "react-icons/vsc";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Empty, Pagination } from 'antd';
import { setSearchData } from "../reduxServices/actions/searchDataAction";
import Loader from './loader'

function SearchDataPages() {

  const data = useSelector((state) => state?.searchAllData?.products?.data);
  const dataTotal = useSelector((state) => state?.searchAllData?.products);
  const [loaderShow, setLoaderShow] = useState(false)

  const dispatch = useDispatch();
  const [visible, setVisible] = useState('');
  const [page, setpage] = useState(1);
  const [count, setCount] = useState(0);

  const onShowSizeChange = (current, pageSize) => {
    setVisible(pageSize)
    setLoaderShow(true)
    const search_data = localStorage.getItem("search_data")
    axios.get(`/frontend/public-api/search-products?page=${page}&per_page=${pageSize}&search=${search_data}`)
      .then(res => {
        dispatch(setSearchData(res?.data?.data?.products));
        setLoaderShow(false)
      }).catch(() => {
        setLoaderShow(false)
      })
  };

  const pagesChange = (e) => {
    setpage(e)
    setLoaderShow(true)
    const search_data = localStorage.getItem("search_data")
    axios.get(`/frontend/public-api/search-products?page=${e}&per_page=${visible}&search=${search_data}`)
      .then(res => {
        dispatch(setSearchData(res?.data?.data?.products));
        setLoaderShow(false)
      }).catch(() => {
        setLoaderShow(false)
      })
  };

  console.log(data, "datalist datalist")
  console.log(dataTotal, "data22 data22")
  return (
    <div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="card-block theme-form theme_form__wishlist">
              <Row>
                {data?.length > 0 ? <>
                  {data?.map((item, i) =>
                    <Col lg={2} className="d-flex align-items-stretch mt-3" key={i}>
                      <div className='card'>
                        <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                          {/* <Link to="/product-details">slug */}
                          <Link to={`/product-details/${item?.slug}`}>
                            <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                          </Link>
                          <Link to={`/product-details/${item?.slug}`} className='product_hone_box_text'>
                            <p> {item?.active_translation[0]?.name?.substring(0, 50)}</p>
                          </Link>
                          <div className='price__'>
                            {item?.feature_item[0]?.discount_price > 0 ? <>
                              ৳{item?.feature_item[0]?.discount_price}
                              <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                            </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                          </div>
                          {/* <button onClick={() => wishlistRemove(item?.product_id)} className='price__cart__btn'> <VscTrash /> </button> */}
                          <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                        </div>
                      </div>

                    </Col>
                  )}
                </> : <> <div className='dataEmtey'> <Empty /> </div> </>}

                {dataTotal?.total > 0 ? <>
                  <Col lg={12} style={{ marginTop: '20px' }}>
                    <Pagination
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      onChange={(e) => pagesChange(e)}
                      defaultCurrent={page}
                      total={dataTotal?.total}
                    />
                  </Col>
                </> : <> </>}

              </Row>
            </div>

            <Loader
              loaderShow={loaderShow}
              loaderHide={loaderShow}
            />

          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SearchDataPages