import React from 'react'
import { useLocation } from "react-router-dom";

import DefaultHeader from "./DefaultHeader"
import SellerHeader from "./SellerHeader"
// BiCaretUp
function Header() {
  const location = useLocation()
  var string = location.pathname.includes("seller");

  return (
    <div className='header__area'>
      <DefaultHeader />
      {/* {string === true ? <> <SellerHeader /> </> : <> <DefaultHeader /> </>} */}
    </div>
  )
}
export default Header