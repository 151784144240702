import React from 'react'
import { Container, Row, Col, Badge } from 'react-bootstrap'
import SellerBannerImg from "../../assets/images/seller/seller_banner_one-1.png"
import SellerBannerLeftImg from "../../assets/images/seller/seller_banner_one_left-1.png"
import SellerTab from "../../Components/SellerComponents/SellerTab"

import { imagesUrl } from '../../helper/Url';
import { Tabs, Empty } from 'antd';
import { Link } from 'react-router-dom'
// import DateCountdown from "react-date-countdown-timer";
import data from "./sellerData.json"
import { VscChromeRestore } from "react-icons/vsc";
import DiscountImg from "../../assets/images/seller/discountImg.png"

function SellerThemFour(props) {
    const { TabPane } = Tabs;
    return (
        <div>
            <div className='banner banner-color'>
                <Container>
                    <Row>
                        <Col lg={2}>
                            <img src={imagesUrl + props?.allData?.seller_details?.seller_logo} alt="Okk" className="image_" />
                        </Col>
                        <Col className='page-content' lg={7}>
                            <h3 style={{ textTransform: 'capitalize' }} class="mb-1">Welcome back! {props?.allData?.seller_details?.shop_name} <Badge style={{ marginLeft: '7px', fontSize: '13px', fontWeight: '500' }} className='btn-primary'>11103 Followers</Badge>  </h3>
                            <p class="mb-0 fs-16">83% Positive Seller Ratings</p>
                        </Col>
                        <Col className='text-right' lg={3}>
                            <button style={{ float: 'right' }} className='btn btn-primary'> Follow  </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='seller_body_width'>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Home Pages" key="1">
                        <div >
                            <img className='img-fit' src={DiscountImg} alt="" />
                        </div>
                        <div className='mt-4 d-none'>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className='seller_profile__header'>
                                            <h3> FlashSale </h3>
                                            {/* <DateCountdown
                                                dateTo="December 31, 2022 00:00:00 GMT-5"
                                                callback={() => alert("Hello")}
                                            /> */}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className='three_catagori_box'>
                                            <Row>
                                                {data.products.map((item, i) =>
                                                    <Col lg={3} className="d-flex align-items-stretch mt-3" key={i}>
                                                        <Link className='card' to="/product-details">
                                                            <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                                                <img className='img-fit' src={item.images} alt="" />
                                                                <div className='product_hone_box_text'>
                                                                    <p> {item.description}</p>
                                                                </div>
                                                                <div className='price__'> ৳ {item.price} </div>
                                                                <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='mt-4'>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className='three_catagori_box'>
                                            <p> <VscChromeRestore /> Just For You </p>
                                            <Row>
                                                {props?.allData?.products?.data?.length > 0 ? <>
                                                    {props?.allData?.products?.data?.map((item, i) =>
                                                        <Col lg={2} className="d-flex align-items-stretch mt-3" key={i}>
                                                            <Link className='card' to={`/product-details/${item?.slug}`}>
                                                                <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                                                    <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                                                    <div className='product_hone_box_text'>
                                                                        <p> {item?.active_translation[0]?.name?.substring(0, 50)}</p>
                                                                    </div>
                                                                    <div className='price__'>
                                                                        {item?.feature_item[0]?.discount_price > 0 ? <>
                                                                            ৳{item?.feature_item[0]?.discount_price}
                                                                            <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                                                        </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                                                    </div>
                                                                    <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    )}
                                                </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                    </TabPane>
                    <TabPane tab="All Products" key="2">
                        <div className='mt-4'>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className='three_catagori_box'>
                                            {/* <p> <VscChromeRestore /> Just For You </p> */}
                                            <Row>
                                                {props?.allData?.products?.data?.length > 0 ? <>
                                                    {props?.allData?.products?.data?.map((item, i) =>
                                                        <Col lg={2} className="d-flex align-items-stretch mt-3" key={i}>
                                                            <Link className='card' to={`/product-details/${item?.slug}`}>
                                                                <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                                                    <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                                                    <div className='product_hone_box_text'>
                                                                        <p> {item?.active_translation[0]?.name?.substring(0, 50)}</p>
                                                                    </div>
                                                                    <div className='price__'>
                                                                        {item?.feature_item[0]?.discount_price > 0 ? <>
                                                                            ৳{item?.feature_item[0]?.discount_price}
                                                                            <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                                                        </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                                                    </div>
                                                                    <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    )}
                                                </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default SellerThemFour