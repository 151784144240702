import React from 'react'
import { Container, Row, Col, Badge } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import { imagesUrl } from '../helper/Url';

function BuyNowShipping() {
    const buyNowProducts = useSelector((state) => state?.cartItems?.buyNowProduct);
    console.log(buyNowProducts, "buyNowProducts buyNowProducts")
    return (
        <div>
            <Container >
                <Row>
                    <Col lg={8}>
                        <div className='content__buy__block mt-4'>
                            <p> Deliver to: Dulal Hossain </p>
                            <div className='content__buy__block__box_one'>
                                <Badge bg="info" style={{ marginRight: '5px' }}>Home</Badge>
                                <p style={{ marginRight: '5px', fontWeight: 600 }} >01985834220</p>
                                <p>House#07, Road#01, Block#B, Gulshan-Niketon , Dhaka, Niketon, Dhaka - North, Dhaka</p>
                            </div>
                            <div className='content__buy__block__box_two'>
                                <p style={{ marginRight: '5px', fontWeight: 600 }} >Bill to the same address</p>
                                <p>Email todulalhossain34220@gmail.com</p>
                            </div>
                        </div>
                        <div className='content__buy__block mt-2'>
                            <p> Product name </p>
                            <div className='content__buy__block__box_one'>
                                <div className='d-flex justify-content-start'>
                                    <div className=''>
                                        <img src={imagesUrl + buyNowProducts?.image_path } alt="okk" />
                                    </div>
                                    <div className=''>
                                        <h3> Name </h3>
                                    </div>
                                    <div className=''>
                                        <h3> Name </h3>
                                    </div>
                                    <div className=''>
                                        <h3> Name </h3>
                                    </div>
                                </div>
                            </div>
                            <div className='content__buy__block__box_two'>
                                <p style={{ marginRight: '5px', fontWeight: 600 }} >Bill to the same address</p>
                                <p>Email todulalhossain34220@gmail.com</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <h3>  Hello </h3>
                    </Col>
                </Row>
            </Container>
            {/* BuyNowShipping */}
        </div>
    )
}

export default BuyNowShipping