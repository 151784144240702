import { ActionTypes } from "./action-types";

export const ADD_ITEM = (item) => ({
    type: ActionTypes.ADD_ITEM_CART,
    payload: item,
});
export const buynow = (item) => ({
    type: ActionTypes.BUY_NOW,
    payload: item,
});

export const ADD = (item) => ({
    type: ActionTypes.ADD_CART,
    payload: item,
});

export const getCartTotal = () => ({
    type: ActionTypes.GET_TOTALS,
});

export const increase = (id) => ({
    type: ActionTypes.INCREASE,
    payload: id,
});

export const decrease = (id) => ({
    type: ActionTypes.DECREASE,
    payload: id,
});
export const remove = (id) => ({
    type: ActionTypes.REMOVE,
    payload: id,
});
