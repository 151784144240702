import React, { useContext, useState } from 'react'
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FcHome } from "react-icons/fc";
import { menu } from "./MenuData";
import { hasChildren } from "./utils";

function MenuCategories(props) {

  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const SingleLevel = ({ item }) => {
    return (
      <Menu>
          <Menu.Item><Link to={`/product-categories/${item?.slug}`}>{item?.active_translation[0]?.name}</Link></Menu.Item>
      </Menu>
    );
  };

  const MultiLevel = ({ item }) => {
    const { children: children } = item;
    return (
      <Menu>
        <Menu.SubMenu title={item?.active_translation[0]?.name}>
          {children?.map((child, key) => (
            <Link to={`/product-categories/${child?.slug}`}>
              <MenuItem key={key} item={child} />
            </Link>
          ))}
        </Menu.SubMenu>
      </Menu>
    )
  };
  return props?.category?.map((item, key) => <MenuItem key={key} item={item} />)
}

export default MenuCategories