import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import { VscChromeRestore } from "react-icons/vsc";
import { Pagination } from 'antd';

import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
import { imagesUrl } from '../helper/Url';
import data from "../data.json"
function JustForYou() {
    const [visible, setVisible] = useState(12);
    const [page, setpage] = useState(1);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    let usertoken = getCookie("token");
    const loadmore = () => {
        setVisible((prevValue) => prevValue + 6);
    }
    useEffect(() => {
        // Country List
        axios.get(`/frontend/public-api/just-for-you-products?per_page=${visible}&page=${page}`, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data.products)
            })

    }, [count]);

    const onShowSizeChange = (current, pageSize) => {
        setVisible(pageSize)
        setCount(count + 1)
    };

    const pagesChange = (e) => {
        setpage(e)
        setCount(count + 1)
    };

    return (
        <div className='mt-4'>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='three_catagori_box'>
                            <p> <VscChromeRestore /> Just For You  </p>
                            <Row>
                                {data?.data?.map((item, i) =>
                                    <Col md={3} lg={3} xl={2} className="d-flex align-items-stretch mt-3" key={i}>
                                        <Link className='card' to={`/product-details/${item?.slug}`}>
                                            <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                                <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                                <div className='product_hone_box_text'>
                                                    <p> {item?.active_translation[0]?.name.substring(0, 50)}</p>
                                                </div>
                                                <div className='price__'>
                                                    {item?.feature_item[0]?.discount_price > 0 ? <>
                                                        ৳{item?.feature_item[0]?.discount_price}
                                                        <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                                    </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                                </div>
                                                <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                            </div>
                                        </Link>
                                    </Col>
                                )}
                                {data?.total > 0 ? <>
                                    <Col lg={12} style={{ marginTop: '20px' }}>
                                        <Pagination
                                            showSizeChanger
                                            onShowSizeChange={onShowSizeChange}
                                            onChange={(e) => pagesChange(e)}
                                            defaultCurrent={page}
                                            total={data?.total}
                                        />
                                    </Col>
                                </> : <> </>}
                                {/* <Col lg={12}>
                                    {data.products.length > visible ? <>
                                        <button className="loadMore__btn" type="button" onClick={loadmore}> Load More </button> </> :
                                        <>  </>}
                                </Col> */}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default JustForYou