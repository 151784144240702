import React from 'react'
import { Link } from 'react-router-dom'
import Mimg from "../../assets/images/m1.png"
function Mackup() {
    return (
        <div>
            <div className='collection-banner-main banner-1  p-right'>
            <Link to="/mackeup">
                <div className='collection-img bg-size'>
                    <img className='img-fit' src={Mimg} alt="" />
                    <div className='collection-banner-contain'>
                        <div>
                            <h3>Skin Makeup</h3>
                            <h4>Offers</h4>
                            <div className="shop">
                                    shop now
                            </div>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
        </div>
    )
}

export default Mackup