import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import { Row, Col, Tooltip } from 'react-bootstrap'
import Tooltip from "@material-ui/core/Tooltip";
// import {
//     UncontrolledTooltip,
// } from 'reactstrap'
import { RiFacebookLine, RiGoogleLine, RiLinkedinLine } from "react-icons/ri";
import { VscAdd, VscEdit } from "react-icons/vsc";
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
// import 'antd-country-phone-input/dist/index.css';
import axios from '../../helper/Api';
import { toast } from "react-toastify";
import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import OtpTimer from 'otp-timer'
import moment from 'moment'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";

// import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
// import en from 'world_countries_lists/data/countries/en/world.json';

// import 'antd-country-phone-input/dist/index.css';
import { Input, Form, Radio, DatePicker, Modal } from 'antd';
import { createCookieInHour, COOKIE_NAME, getCookie } from '../../helper/Cookies';
import { ContentPasteSearchOutlined } from '@mui/icons-material';

function UserProfile() {
    const [form] = Form.useForm();
    let usertoken = getCookie("token");
    const [count, setCount] = useState(0);
    const [countryId, setCountryId] = useState([]);
    const [userData, setUserData] = useState([]);
    const [country, setCountry] = useState([]);
    const [phoneOtp, setPhoneOtpSet] = useState([]);
    const [otpTimeModal, setOtpTimeModal] = useState(false);
    const [buttonSubmit, setButtonSubmit] = useState(true);
    const [emailButtonSubmit, setEmailButtonSubmit] = useState(true);
    const [modalTimerOff, setModalTimerOff] = useState(true);
    const [emailValitshon, setEmailValitshon] = useState(false);
    const [phoneEditButton, setPhoneEditButton] = useState(false);
    const [value, setValue] = useState('');

    // Email Otp 
    const [isEmailModalOpen, setEmailIsModalOpen] = useState(false);
    const [emailotpTimeModal, setEmailotpTimeModal] = useState(true);
    const [emailmodalTimerOff, setEmailModalTimerOff] = useState(true);
    const [userEmailOtp, setUserEmailOtp] = useState([]);
    const [userEmail, setEmailUser] = useState('');

    console.log(userData?.phone_number)
    // modal open
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        axios.get("/customer/get-user-details", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                console.log(res)
                setUserData(res?.data?.data?.user_details)
                form.setFieldsValue({
                    first_name: res?.data?.data?.user_details?.first_name,
                    middel_name: res?.data?.data?.user_details?.middle_name,
                    last_name: res?.data?.data?.user_details?.last_name,
                    email_name: res?.data?.data?.user_details?.email,
                    gender: Number(res?.data?.data?.user_details?.gender),
                    date_time: moment(`${res?.data?.data?.user_details?.dob}`, 'YYYY-MM-DD'),
                })
                setValue({ short: 'BD', phone: `${res?.data?.data?.user_details?.phone_number}` })
            })
        // Country List
        axios.get("/common/country/active/all")
            .then(res => {
                setCountry(res.data.data.countries)

            })
    }, [count]);

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const phoneOtpSetFunction = (otp) => {
        setPhoneOtpSet(otp)
    };

    const phoneOtpResend = (e) => {
        setButtonSubmit(true)
        setModalTimerOff(true)
        setInterval(() => {
            setButtonSubmit(false)
        }, 120000);
        // axios.post(`/customer/generate-otp?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}`)
        //     .then((res) => {
        //         console.log(res)
        //     }).catch(err => {

        //     })
    };
    const addPhoneNumber = () => {
        setIsModalOpen(true);
        // const countryId = country?.find(item => item?.iso === value?.short)
        console.log(countryId)
        // setCountryId({ country_id: countryId?.id, phone_number: userData?.phone_number })
        setOtpTimeModal(true)
        {
            modalTimerOff === true ?
                setInterval(() => {
                    setButtonSubmit(false)
                }, 120000) : <> </>
        }
        console.log(countryId, "countryId")
        axios.post(`/customer/generate-phone-number-change-otp?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}`, { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then((res) => {
                console.log(res)

            }).catch(err => {

            })
    };
    const phoneDataSubmit = () => {
        axios.post(`/customer/verify-phone-number-change-otp?country_id=${countryId.country_id}&phone_number=${countryId.phone_number}&otp=${phoneOtp}`, { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then((res) => {
                console.log(res)
                setIsModalOpen(false);
                if (res.data.code === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setCount(count + 1)
                }
                if (res.data.code !== 200) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }).catch(err => {

            })
    }

    // Email Function
    const emailModalCancel = () => {
        setEmailIsModalOpen(false);
    };
    const emailOnChangeFunction = (inputText) => {
        // console.log(inputText.target.value)
        setEmailUser(inputText.target.value)
        let regex = RegExp(/([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/);
        if (inputText.target.value.match(regex)) {
            setEmailValitshon(true)
        } else {
            setEmailValitshon(false)
        }
    }
    const emailModalOpen = () => {
        setEmailIsModalOpen(true);
        setEmailotpTimeModal(true)
        {
            emailmodalTimerOff === true ?
                setInterval(() => {
                    setButtonSubmit(false)
                }, 120000) : <> </>
        }
        const obj = {
            email: userEmail
        }
        axios.post('/customer/generate-email-otp', obj , { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then((res) => {
                console.log(res)
                setIsModalOpen(false);
                if (res.data.code === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                if (res.data.code !== 200) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                setCount(count + 1)
            }).catch(err => {

            })
    }
    const emailOtpResend = () => {
        setEmailButtonSubmit(true)
        setEmailotpTimeModal(true)
        {
            emailmodalTimerOff === true ?
                setInterval(() => {
                    setEmailButtonSubmit(false)
                }, 120000) : <> </>
        }
    }
    const emailOtpChange = (otp) => {
        setUserEmailOtp(otp)
    }

    const emailDataSubmit = () => {
        const obj = {
            email: userEmail,
            otp: userEmailOtp
        }
        axios.post('/customer/verify-email-otp', obj, { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then((res) => {
                console.log(res)
                if (res.data.code === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setEmailIsModalOpen(false);
                    setCount(count + 1)
                }
                if (res.data.code !== 200) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }

            }).catch(err => {

            })
    }

    const profileUpdate = (value) => {
        // console.log(value)
        // console.log( moment(value.date_time._d).format('YYYY-MM-DD'))
        axios.post(`/customer/update-profile?first_name=${value?.first_name}&middle_name=${value?.middel_name}&last_name=${value?.last_name}&gender=${Number(value?.gender)}&dob=${moment(value.date_time._d).format('YYYY-MM-DD')}`, { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then((res) => {
                console.log(res)
                if (res.data.code === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setCount(count + 1)
                }
                if (res.data.code !== 200) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }

            }).catch(err => {

            })
    }

    console.log(userData, "defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}")
    console.log(value)
    console.log(userEmail)
    return (
        <div className='login_area creact__account__user__BOx'>

            <div className="container" id="container">
                <div className="form-container sign-up-container">
                    <h1>User Account Setting</h1>
                    <div className="social-container">
                        <Link to="/"> <RiFacebookLine />  </Link>
                        <Link to="/"> <RiGoogleLine />  </Link>
                        <Link to="/"> <RiLinkedinLine />  </Link>
                    </div>
                    <span>or use your email for registration</span>
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <button>Sign Up</button>
                    {/* </Form> */}
                </div>
                {/* Email Modal  */}
                <Modal className='euser_profile_modal_area' visible={isEmailModalOpen} onOk={handleOk} onCancel={emailModalCancel}>
                    <h3 className='account_otp_hdr__txt'> Enter OTP Code </h3>
                    <div className='account__otp_area'>
                        <OtpInput

                            value={userEmailOtp}
                            onChange={emailOtpChange}
                            numInputs={6}
                            separator={<span>-</span>}
                        />
                    </div>
                    {emailotpTimeModal === true ? <>
                        <div className='otp__Timer__resend_button'>
                            <OtpTimer
                                minutes={1}
                                seconds={60}
                                text="Time:"
                                ButtonText="Resend"
                                resend={(e) => emailOtpResend(e)}
                            />
                        </div>
                    </> : <> </>}
                    {emailButtonSubmit === true ? <>
                        <div className='otp__acc_otp__btn'>
                            <button className='acc_otp__btn'> Cancel </button>
                            <button onClick={emailDataSubmit} style={{ marginRight: '0px', background: "#52c41a" }} className='acc_otp__btn'> Submit </button>
                        </div>
                    </> : <> </>}
                </Modal>
                {/* PHONE MODAL  */}
                <Modal className='euser_profile_modal_area' visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <h3 className='account_otp_hdr__txt'> Number Change OTP Code </h3>
                    <div className='account__otp_area'>
                        <OtpInput

                            value={phoneOtp}
                            onChange={phoneOtpSetFunction}
                            numInputs={6}
                            separator={<span>-</span>}
                        />
                    </div>
                    {buttonSubmit === true ? <>
                        <h5 className='error__data__phone'> Please check this phone number {userData?.phone_number}  </h5>
                    </> : <> </>}
                    {otpTimeModal === true ? <>
                        <div className='otp__Timer__resend_button'>
                            <OtpTimer
                                minutes={1}
                                seconds={60}
                                text="Time:"
                                ButtonText="Resend"
                                resend={(e) => phoneOtpResend(e)}
                            />
                        </div>
                    </> : <> </>}
                    {buttonSubmit === true ? <>
                        <div className='otp__acc_otp__btn'>
                            <button className='acc_otp__btn'> Cancel </button>
                            <button onClick={phoneDataSubmit} style={{ marginRight: '0px', background: "#52c41a" }} className='acc_otp__btn'> Submit </button>
                        </div>
                    </> : <> </>}
                </Modal>
                <div style={{ width: '100%' }} className="form-container sign-in-container">
                    <Form
                        name="basic"
                        layout="vertical"
                        className='from_home_area'
                        form={form}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={profileUpdate}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <h1 className='header__ac__name'>User Profile Setting</h1>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            max: 40,
                                            message: "Value should be less than 40 character",
                                        },
                                        {
                                            pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                            message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                        },
                                        {
                                            required: true,
                                            message: 'Please input First Name!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-3'>
                                <Form.Item
                                    label="Middel Name"
                                    name="middel_name"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            max: 40,
                                            message: "Value should be less than 40 character",
                                        },
                                        {
                                            pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                            message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                        },
                                        {
                                            required: true,
                                            message: 'Please input Last Name!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>

                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            max: 40,
                                            message: "Value should be less than 40 character",
                                        },
                                        {
                                            pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                            message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                        },
                                        {
                                            required: true,
                                            message: 'Please input User Name!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <div style={{ position: 'relative' }}>
                                    <Form.Item
                                        label="Email"
                                        name="email_name"
                                        className="form-group form-box exta_input_fill"
                                    >
                                        <Input onChange={(e) => emailOnChangeFunction(e)} style={{ width: '85%', float: 'left' }} className="form-control" />

                                    </Form.Item>
                                    {emailValitshon === false ? <> <VscAdd className='phone__add_icon phone__add_icon_email' /> </> : <> <VscAdd onClick={emailModalOpen} style={{ background: '#1890ff', color: '#fff' }} className='phone__add_icon phone__add_icon_email' /> </>}
                                </div>

                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Contact No"
                                    name="phone_number"
                                    maxLength={11}
                                    className="form-group form-box exta_input_fill"
                                >
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput
                                            value={value}
                                            onChange={(v) => {
                                                setValue(v);
                                                const countryId = country?.find(item => item?.iso === value?.short)
                                                // console.log(countryId)
                                                setCountryId({ country_id: countryId?.id, phone_number: v?.phone })
                                                setPhoneEditButton(true)
                                            }} />
                                        <Tooltip
                                            title="Tooltip for the register button"
                                            placement="top"
                                        >
                                            {phoneEditButton === false ? <>
                                                <VscEdit className='phone__add_icon' />
                                            </> : <>
                                                <VscEdit style={{ background: "#d61c22", color: '#fff' }} onClick={addPhoneNumber} className='phone__add_icon' />
                                            </>}
                                        </Tooltip>
                                    </ConfigProvider>
                                </Form.Item>

                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Dath of Bath"
                                    name="date_time"
                                    defaultValue="1994-6-15"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input User Name!',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ padding: "0px 15px", width: '100%' }} />
                                </Form.Item>
                            </div>

                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Gender"
                                    name="gender"
                                    style={{ float: 'left', border: 'none' }}
                                    className="form-group form-box form-box2 "
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Gender!',
                                        },
                                    ]}
                                >
                                    {/* <Radio.Group onChange={onChange} value={genderValue}> */}
                                    <Radio.Group>
                                        <Radio value={1}>Male</Radio>
                                        <Radio value={2}>Female</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn-md btn-theme regBTn">Submit</button>
                                {/* <p className='from__bottom_txt'>Don't have an account? <Link to="/login" className="thembo"> Login here</Link></p> */}
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default UserProfile