import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import axios from '../helper/Api';
import { getCookie } from '../helper/Cookies';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { useSelector, useDispatch } from "react-redux";
import { Empty } from 'antd';
import Swal from "sweetalert2"
import RadioGroup from '@mui/material/RadioGroup';
import { ADD_ITEM } from "../reduxServices/actions/CartActions";

function Checkout() {
    let usertoken = getCookie("token");
    const dispatch = useDispatch()
    const cartslist = useSelector((state) => state.cartItems.carts);
    const totalAmount = useSelector((state) => state.cartItems?.totalAmount?.totalAmount);
    const [data, setData] = useState([]);
    const [checkId, setCheckId] = useState("0");
    useEffect(() => {
        axios.get("/customer/get-user-details", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                console.log(res)
                setData(res?.data?.data?.user_details)
            })
    }, []);
    console.log(cartslist, "cartslist cartslist cartslist")
    const orderSubmit = () => {

        let cartId = []
        cartslist?.forEach(item => {
            const data = item.id
            cartId.push(data)
        })

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Order Submit!'
        }).then((result) => {
            if (result.isConfirmed) {
                const obj = {
                    cart_ids: cartId,
                    payment_by: checkId
                }
                axios.post("/customer/order/order-from-cart", obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
                    .then(res => {
                        // Cart data get request
                        axios.get("/customer/cart/list-cart", {
                            headers: {
                                'Authorization': `bearer ${usertoken}`
                            }
                        })
                            .then(res => {
                                dispatch(ADD_ITEM(res?.data?.data?.carts));
                            })
                            console.log(res, "res res resresresresresresresresres")
                        if(res.data.code === 200 ) {
                            Swal.fire(
                                `${res.data.message}`,
                                `${res.data.message}`,
                                'success'
                            )
                        } else {
                            Swal.fire(
                                `${res.data.message}`,
                                'success'
                            )
                        }
                    })

            }
        })
    }

    return (
        <div>
            {/* <Container>
                <Row>
                    <Col lg={12}>
                        <div className='brack_cumes_area brack_cumes_details'>
                            <p> <Link to="/"> Checkout </Link> / <Link to="/">Home </Link> / <span> Checkout </span>  </p>
                        </div>
                    </Col>
                </Row>
            </Container> */}
            <Container>
                <section className="section-big-py-space b-g-light">
                    <div className="custom-container">
                        <div className="checkout-page contact-page">
                            <div className="checkout-form">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div className="checkout-title">
                                                <h3>Billing Details</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12">

                                            {/* {data?.length > 0 ? <> */}
                                            <div className="theme-form">
                                                <div className="row check-out ">

                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label>First Name</label>
                                                        <input type="text" value={data?.first_name} />
                                                    </div>
                                                    {/* <div className="form-group col-md-3 col-sm-3 col-xs-12">
                                                            <label>Middle Name</label>
                                                            <input type="text" value={data?.middle_name} />
                                                        </div> */}
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label>Last Name</label>
                                                        <input type="text" value={data?.last_name} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                        <label className="field-label">Email Address</label>
                                                        <input type="text" value={data?.email} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label className="field-label">Phone</label>
                                                        <input type="text" value={data?.phone_number} />
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <label className="field-label">Address</label>
                                                        <textarea className='address_T_txt'></textarea>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* </> : <> </>} */}
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            {cartslist?.length > 0 ? <>
                                                <div style={{ marginTop: "0px" }} className="checkout-details theme-form  section-big-mt-space">
                                                    <div className="order-box">
                                                        <div className="title-box">
                                                            <div style={{ fontSize: '16px' }}>Product <span>Price</span></div>
                                                        </div>
                                                        {/* {cartslist?.length > 0 ? <> */}
                                                        <ul className="qty">
                                                            {cartslist?.map((item, i) =>
                                                                <li key={i} style={{ width: '100%', float: 'left' }}>
                                                                    <p style={{ float: 'left', fontSize: '15px', width: '50%' }}> {item?.product?.active_translation[0]?.name}  </p>
                                                                    <span>
                                                                        {item?.product_item?.discount_price > 0 ? <>
                                                                            ৳{item?.product_item?.discount_price}
                                                                            <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.product_item?.selling_price} </del>
                                                                        </> : <>  ৳{item?.product_item?.selling_price} </>}
                                                                    </span>
                                                                </li>
                                                            )}
                                                        </ul>
                                                        {/* </> : <> <div className='dataEmtey'> <Empty /> </div> </>} */}
                                                        <ul className="sub-total">
                                                            {/* <li>Subtotal <span className="count">$380.10</span></li> */}
                                                            <li>Delivery
                                                                <div className="shipping">
                                                                    <div className="shopping-option">
                                                                        {/* <FormControlLabel control={<Checkbox />} label="Outside in dhaka" />
                                                                        <FormControlLabel control={<Checkbox />} label="Outside in dhaka" /> */}
                                                                        <RadioGroup
                                                                            row
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue="0"
                                                                            name="row-radio-buttons-group"
                                                                        >
                                                                            <FormControlLabel value="0" control={<Radio />} label="Inside in dhaka" />
                                                                            <FormControlLabel value="1" control={<Radio />} label="Outside in dhaka" />
                                                                        </RadioGroup>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        {cartslist?.length > 0 ? <>
                                                            <ul className="total">
                                                                <li>Total <span className="count"> ৳ {new Intl.NumberFormat().format(totalAmount)} </span></li>
                                                            </ul>
                                                        </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                                    </div>
                                                    <div className="payment-box">
                                                        <div className="upper-box">
                                                            <div className="payment-options">
                                                                <ul className="sub-total">
                                                                    <li className='payment_options_title'> Payment </li>
                                                                    <li>
                                                                        <div className="shipping">
                                                                            <div className="shopping-option">
                                                                                <RadioGroup
                                                                                    row
                                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                                    defaultValue="0"
                                                                                    name="row-radio-buttons-group"
                                                                                >
                                                                                    <FormControlLabel value="0" onClick={() => setCheckId("0")} control={<Radio />} label="Cash on delivery" />
                                                                                    <FormControlLabel value="1" onClick={() => setCheckId("1")} control={<Radio />} label="Online payment" />
                                                                                </RadioGroup>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {data?.is_profile_complete === "1" ? <>
                                                            <div className="text-right">
                                                                <Link className='place__btn__box' to="" onClick={() => orderSubmit()}>Place Order</Link>
                                                            </div>
                                                        </> : <> <p style={{ color: '#f00', fontWeight: '600' }}> Please Complited You Profile </p>  </>}

                                                    </div>
                                                </div>
                                            </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>

        </div>
    )
}

export default Checkout