import React, { useState, useContext, useEffect } from 'react'
import { Drawer, Menu } from 'antd';
import { Link } from "react-router-dom";

import LanguageContext from "../../context/LanguageProvider";
import axios from '../../helper/Api';
import { hasChildren } from "./utils";
import { VscListFilter } from "react-icons/vsc";
function MenuCategoriesDrawer() {
    const [open, setOpen] = useState(false);
    const { language } = useContext(LanguageContext);
    const [categoryList, setCategory] = useState([]);
    const { SubMenu } = Menu;
    const showDrawer = () => { setOpen(true) };


    const onClose = () => { setOpen(false) };
    useEffect(() => {
        axios.get(`/frontend/public-api/category/active/all?active_language=${language}`)
            .then(res => {
                setCategory(res?.data?.data?.categories)
                console.log()
            })
    }, [language])
    console.log(categoryList)
    const MenuItem = ({ item }) => {
        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item} />;
    };

    const SingleLevel = ({ item }) => {
        console.log(item, "SingleLevel")
        return (
            <Menu className='drawer__menu__rasponsive__area drawer__menu__rasponsive__area_2'>
                <Link style={{ color: '#222' }} onClick={onClose} to={`/product-categories/${item?.slug}`}>
                    <Menu.Item style={{ color: '#222' }}>{item?.active_translation[0]?.name}</Menu.Item>

                </Link>
                {/* <Menu.Item><Link to={`/product-categories/${item?.slug}`}>{item?.active_translation[0]?.name}</Link></Menu.Item> */}
            </Menu>
        );
    };

    const MultiLevel = ({ item }) => {
        const { children: children } = item;
        console.log(children)
        return (
            <Menu className='drawer__menu__rasponsive__area' style={{ width: "100%" }} mode="inline">
                <SubMenu title={item?.active_translation[0]?.name}>
                    {children?.map((child, key) => (
                        <>
                            <MenuItem key={key} item={child} />
                        </>
                    ))}
                </SubMenu>
            </Menu>
        )
    };
    return (
        <div className='header__exta__class'>
            <h5 className='catagories__hdr__nme' onClick={showDrawer}>Catagories </h5>
            <div onClick={showDrawer} className=' footer__fixt__box'>
                {/* <Link to="/"> */}
                <VscListFilter />
                <h6> Categories </h6>
                {/* </Link> */}
            </div>
            <Drawer title="MY MARKETS " placement="left" onClose={onClose} visible={open}>
                <>
                    {categoryList?.map((item, key) => <MenuItem key={key} item={item} />)}
                </>
            </Drawer>
        </div>
    )
}

export default MenuCategoriesDrawer