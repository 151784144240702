import React from 'react'
import { Link } from 'react-router-dom'
import Jimg from "../../assets/images/j1.png"
function Jewelry() {
    return (
        <div>
            <div className='collection-banner-main banner-1  p-right'>
                {/* <div className='collection-img bg-size'>
                    <img className='img-fit' src={Jimg} alt="" />
                    <div className='collection-banner-contain'>
                        <div>
                            <h3>Fine Jewelry</h3>
                            <h4>Offers</h4>
                            <div className="shop">
                                <Link to="/">
                                    shop now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Link to="/jewelry">
                    <div className='collection-img bg-size'>
                        <img className='img-fit' src={Jimg} alt="" />
                        <div className='collection-banner-contain'>
                            <div>
                                <h3>Fine Jewelry</h3>
                                <h4>Offers</h4>
                                <div className="shop"> shop now </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Jewelry