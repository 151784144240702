import { ActionTypes } from "../actions/action-types";
const initState = {
    products: []
}
export const SearchProductsReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SEARCH_DATA:
            return { ...state, products: payload }
        default:
            return state;
    }
}
