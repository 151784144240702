import React, { useState, useEffect } from 'react'

import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import './App.css';
import './assets/style/AnimishonTopHeader.scss';
// Components
// import ScrollToTop from "./ScrollToTop";
import Layout from './Components/Layout/Layout';
import PrivateRoute from "./helper/PrivateRoute"
import AuthService from "./helper/Auth.Services"

// Pages
import Home from "./Pages/HomePages";
import ProductCategories from "./Pages/ProductCategories";
import ProductDetails from "./Pages/ProductDetails";
import LoginPages from "./Pages/LoginPages";
import UserAccountCreact from "./Pages/UserAccountCreact";
import SunglassesAll from "./Pages/SunglassesAll";
import JewelryAll from "./Pages/JewelryAll";
import MakeupAll from "./Pages/makeupAll";
import ViewCart from "./Pages/ViewCart";
import Checkout from "./Pages/Checkout";
import Dashboard from "./Pages/Dashboard";
import Dashboard2 from "./Pages/Dashboard2";
import BuyNowShipping from "./Pages/BuyNowShipping";
import WishlistPages from "./Pages/WishlistPages";
import NewArrivalspages from "./Pages/NewArrivalspages";
import WeeklyDealsPages from "./Pages/WeeklyDealsPages";
import AddressBook from "./Pages/AddressBook";
import SearchDataPages from "./Pages/SearchDataPages";
// Seller Pages
import SellerHome from "./Pages/SellerPages/SellerHome";
// import SellerThem1 from "./Pages/SellerPages/SellerThem1";
// import SellerThem3 from "./Pages/SellerPages/SellerThem2";
// import SellerThem4 from "./Pages/SellerPages/SellerThem3";
import AllSellerPages from "./Pages/AllSellerPages";
// My Order
import MyOrder from "./Pages/MyOrder";
import OrderDetails from "./Pages/OrderDetails";

// User
import UserProfile from "./Pages/User/UserProfile";
import ForGotPassword from "./Pages/User/ForGotPassword";
import PasswordChange from "./Pages/PasswordChange";
import TopRankedProductPages from "./Pages/TopRankedProductPages";


import ReadyToShip from "./Pages/ReadyToShip";
import HelpPages from "./Pages/HelpPages";
import AboutUs from "./Pages/AboutUs";
import PrivacyPolicyPages from "./Pages/PrivacyPolicyPages";
import TermsConditions from "./Pages/TermsConditions";
import SupportCenter from "./Pages/SupportCenter";
import OrdersReturns from "./Pages/OrdersReturns";
import FaqPages from "./Pages/FaqPages";

function App() {
  const userData = AuthService.getCurrentUser();
  const [currentUser, setCurrentUser] = useState(true)
  useEffect(() => {
    if (Number(userData) === 0) {
      setCurrentUser(false)
    } else {
      setCurrentUser(true)
    }
  }, [userData])
  return (
    <div className="App">
      <Layout>
      {/* <ScrollToTop /> */}
      <ToastContainer />
        <Routes>
            <Route path="/" element={<Home />}></Route>
            {/* <Route path="/product-categories" element={<ProductCategories />}></Route> */}
            <Route path="/product-categories/:slug" element={<ProductCategories />}></Route>
            <Route path="/product-details/:slug" element={<ProductDetails />}></Route>
            <Route path="/product-details" element={<ProductDetails />}></Route>
            <Route path="/buy-now-shipping" element={<BuyNowShipping />}></Route>
            <Route path="/sunglasses" element={<SunglassesAll />}></Route>
            <Route path="/jewelry" element={<JewelryAll />}></Route>
            <Route path="/mackeup" element={<MakeupAll />}></Route>
            <Route path="/cart" element={<ViewCart />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/address-book" element={<AddressBook />}></Route>
            <Route path="/dashboard-2" element={<Dashboard2 />}></Route>
            <Route path="/wish-list" element={<WishlistPages />}></Route>
            <Route path="/user-profile" element={<UserProfile />}></Route>
            <Route path="/new-arrival" element={<NewArrivalspages />}></Route>
            <Route path="/weekly-deals" element={<WeeklyDealsPages />}></Route>
            <Route path="/top-ranked-product" element={<TopRankedProductPages />}></Route>

            <Route path="/ready-to-ship" element={<ReadyToShip />}></Route>
            <Route path="/help" element={<HelpPages />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicyPages />}></Route>
            <Route path="/terms-conditions" element={<TermsConditions />}></Route>
            <Route path="/support-center" element={<SupportCenter />}></Route>
            <Route path="/orders-returns" element={<OrdersReturns />}></Route>
            <Route path="/faq" element={<FaqPages />}></Route>
            <Route path="/search-pages" element={<SearchDataPages />}></Route>
            
            {/* Seller Pages  */}
            <Route path="/seller/home/:slug" element={<SellerHome />}></Route>
            {/* <Route path="/seller/theme-one" element={<SellerThem1 />}></Route>
            <Route path="/seller/theme-three" element={<SellerThem3 />}></Route>
            <Route path="/seller/theme-four" element={<SellerThem4 />}></Route> */}
            <Route path="/all-seller-pages" element={<AllSellerPages />}></Route>
            {/* My Order  */}
            <Route path="/my-order" element={<MyOrder />}></Route>
            <Route path="/order-details/:slug" element={<OrderDetails />}></Route>
            <Route path="/password-change" element={<PasswordChange />}></Route>

            {/* Unothorase Router  */}
            <Route element={<PrivateRoute isLogged={currentUser} />}>
                <Route path="/login" element={<LoginPages />}></Route>
                <Route path="/user-account-creact" element={<UserAccountCreact />}></Route>
                <Route path="/forgot-password" element={<ForGotPassword />}></Route>
            </Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
