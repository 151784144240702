import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import axios from '../helper/Api';
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import { Select, Form, Input, Modal, Switch, Radio, Empty, Badge, Card } from 'antd';
import { getCookie } from '../helper/Cookies';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { VscTrash } from "react-icons/vsc";
import Swal from "sweetalert2";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    padding: '30px',
};

function AddressBook() {
    let usertoken = getCookie("token");
    const [form] = Form.useForm();
    const [count, setCount] = useState(0);
    const [switchValue, setSwitchValue] = useState("false");
    const [statusId, setStatusId] = useState("");
    // Address 
    const [divisionName, setDivisionName] = useState([]);
    const [divisionId, setDivisionId] = useState([]);
    const [districtName, setDistrictName] = useState([]);
    const [districtId, setDistrictId] = useState([]);
    const [thanaName, setThanaName] = useState([]);
    const [ThanaId, setThanaId] = useState([]);
    const [countryListId, setCountryId] = useState([]);
    const [customarAddress, setCustomarAddress] = useState([]);
    const [userFullDetails, setUserFullDetails] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [activeAddress, setActiveAddress] = useState([]);
    const { Option } = Select;

    const showAddModal = () => {
        setIsModalAddOpen(true);
    };

    const handleAddCancel = () => {
        setIsModalAddOpen(false);
    };

    const showModal = (id) => {
        setIsModalOpen(true);
        const found = customarAddress?.find(item => item?.id === id)
        form.setFieldsValue({
            id: found?.id,
            division_id_edit: found?.division?.name,
            district_id_edit: found?.district?.name,
            detail_address_edit: found?.detail_address,
            thana_id_edit: found?.thana?.name,
            post_code_edit: found?.post_code,
            label_edit: found?.label,
            status_edit: "false",
            // delivery_charge: viewdeliverycharge,
        });
        setStatusId(found?.status)
        console.log(found)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Division Function
    const divisionFunction = (e) => {
        const obj = {
            country_id: countryListId
        }
        axios.post('/division/active/all', obj)
            .then((res) => {
                console.log(res)
                setDivisionName(res?.data?.data?.divisions)
            }).catch(err => {

            })
    }
    const divisionOnChangeFunction = (e) => {
        setDivisionId(e)
    }

    // District Function
    const districtFunction = () => {
        const obj = {
            division_id: divisionId
        }
        axios.post('/district/active/all', obj)
            .then((res) => {
                console.log(res)
                setDistrictName(res?.data?.data?.districts)
            }).catch(err => {

            })
    }

    const districtOnChangeFunction = (e) => {
        setDistrictId(e)
    }
    // districtId
    const thanaFunction = () => {
        const obj = {
            district_id: districtId
        }
        axios.post('/thana/active/all', obj)
            .then((res) => {
                console.log(res)
                setThanaName(res?.data?.data?.thanas)
            }).catch(err => {

            })
    }
    const thanaOnChangeFunction = (e) => {
        setThanaId(e)
    }
    useEffect(() => {
        axios.get("/common/country/active/all")
            .then(res => {
                const cId = res.data.data.countries.find(list => `${list?.iso}` === "BD")
                setCountryId(cId?.id)
            })
        axios.get("/customer/get-address", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                console.log(res, "9999999999999999")
                setCustomarAddress(res?.data?.data?.customerProfileAddressData)
            })
        axios.get("/customer/get-user-details", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                setUserFullDetails(res?.data?.data?.user_details)
            })
        axios.get("/customer/get-active-address", { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then(res => {
                console.log(res, "9999999")
                setActiveAddress(res.data.data.customerProfileAddressData)
            })
    }, [count])

    console.log(customarAddress, "customarAddress customarAddress")

    const addressSubmit = (value) => {
        console.log(value, "value value")
        const obj = {
            label: value.label,
            division_id: value.division_id,
            district_id: value.district_id,
            thana_id: value.thana_id,
            post_code: value.post_code,
            detail_address: value.detail_address,
            status: switchValue === "true" ? 1 : 0,
        }
        // console.log(value)
        axios.post('/customer/create-single-address', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then((res) => {
                console.log(res)
                if (res.data.code === 200) {
                    toast.success(res?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    form.resetFields();
                    setCount(count + 1)
                    setIsModalAddOpen(false)
                }
                if (res.data.code !== 200) {
                    toast.error(res?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setIsModalAddOpen(false)
                }
            }).catch(err => {

            })
    }
    const addressEditSubmit = (value) => {
        console.log(value, "value value")
        const obj = {
            id: value.id,
            label: value.label_edit,
            division_id: value.division_id_edit,
            district_id: value.district_id_edit,
            thana_id: value.thana_id_edit,
            post_code: value.post_code_edit,
            detail_address: value.detail_address_edit,
            status: value.status_edit === true ? 0 : 1,
        }
        // console.log(value)
        axios.post('/customer/update-single-address', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
            .then((res) => {
                console.log(res)
                if (res.data.code === 200) {
                    toast.success(res?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    form.resetFields();
                    setCount(count + 1)
                    setIsModalOpen(false)
                }
                if (res.data.code !== 200) {
                    toast.error(res?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setIsModalOpen(false)
                }
            }).catch(err => {

            })
    }
    const addressDelect = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to Delect this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const obj = {
                    id: id,
                }
                axios.post('/customer/delete-single-address', obj, { headers: { 'Authorization': `bearer ${usertoken}` } })
                    .then((res) => {
                        console.log(res)
                        if (res.data.code === 200) {
                            toast.success(res?.data?.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            form.resetFields();
                            setCount(count + 1)
                        }
                        if (res.data.code !== 200) {
                            toast.error(res?.data?.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                    }).catch(err => {

                    })

            }
        })
    }

    const switchStatus = (e) => {
        if (e === true) { setStatusId(1) } else { setStatusId(0) }
    }
    const switchFunction = (e) => {
        console.log(e, "99999999999999999")
        setSwitchValue(e)
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={12} className="mt-2">
                        <div className='addrees_book_hedaer'>
                            <p> Address Book <span onClick={showAddModal}> Add New Address  </span> </p>
                        </div>
                    </Col>
                    {customarAddress?.length > 0 ? <>
                        {customarAddress?.map((item, i) =>
                            <Col key={i} lg={4} className="d-flex align-items-stretch mt-4 mb-4">
                                <Badge.Ribbon text={`${item?.status}` === "1" ? <> Active </> : <> Inactive </>} color={`${item?.status}` === "1" ? "purple" : "red"}>
                                    <div className='card'>
                                        <div className='addrees_book_box'>
                                            <p style={{marginTop: '10px', fontWeight: '600'}}> Address Book <span onClick={() => showModal(item?.id)}> Edit </span> </p>
                                            <p> (+880) {userFullDetails?.phone_number}  </p>
                                            <p style={{height: '100px'}}> {item?.division?.name}, {item?.district?.name} - {item?.detail_address}, {item?.thana?.name} , {item?.post_code}  </p> <br />
                                            <h5 style={{background: '#722ed1'}}>
                                                {item?.label}
                                            </h5>
                                            <VscTrash onClick={() => addressDelect(item?.id)} className='address__delect' />
                                        </div>
                                    </div>
                                </Badge.Ribbon>
                            </Col>
                        )}
                    </> : <>

                        <Col lg={12} className="mt-4 mb-4">
                            <div className='dataEmtey'> <Empty /> </div>
                        </Col>
                    </>}

                </Row>
                {/* Data Add  */}
                <Modal className='address__change__from__area' title="Address From" visible={isModalAddOpen} onCancel={handleAddCancel}>
                    {/* <Box sx={style}> */}
                    <Form
                        name="basic"
                        layout="vertical"
                        className='from_home_area'
                        form={form}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={addressSubmit}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className='row'>

                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Division"
                                    name="division_id"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input First Name!',
                                        },
                                    ]}
                                >
                                    <Select className='select__width' onClick={(e) => divisionFunction(e)} onChange={(e) => divisionOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                        {divisionName?.map((item, i) =>
                                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='col-lg-6'>
                                <Form.Item
                                    label="District"
                                    name="district_id"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input First Name!',
                                        },
                                    ]}
                                >
                                    <Select className='select__width' onClick={() => districtFunction()} onChange={(e) => districtOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                        {districtName?.map((item, i) =>
                                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                        )}
                                    </Select>

                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Thana"
                                    name="thana_id"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Thana!',
                                        },
                                    ]}
                                >
                                    <Select className='select__width' onClick={() => thanaFunction()} onChange={(e) => thanaOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                        {thanaName?.map((item, i) =>
                                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Post Code"
                                    name="post_code"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Post Code!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Item
                                    label="Detail Address"
                                    name="detail_address"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Detail Address!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Item
                                    label="Select a label for effective delivery"
                                    name="label"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input label!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Item
                                    name="status"
                                    className="form-group form-box"
                                >
                                    <Switch onClick={(e)=>switchFunction(e)} checkedChildren="Active" unCheckedChildren="InActive" />
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn-md btn-theme address_change_btn">Submit</button>
                                {/* <p className='from__bottom_txt'>Don't have an account? <Link to="/login" className="thembo"> Login here</Link></p> */}
                            </div>
                        </div>
                    </Form>
                    {/* </Box> */}
                </Modal>

                <Modal className='address__change__from__area' title="Address From" visible={isModalOpen} onCancel={handleCancel}>
                    <Form
                        name="basic"
                        layout="vertical"
                        className='from_home_area'
                        form={form}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={addressEditSubmit}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className='row'>

                            <div className='col-lg-12 d-none'>
                                <Form.Item
                                    name="id"
                                    className="form-group form-box"
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>

                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Division"
                                    name="division_id_edit"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Division!',
                                        },
                                    ]}
                                >
                                    <Select className='select__width' onClick={(e) => divisionFunction(e)} onChange={(e) => divisionOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                        {divisionName?.map((item, i) =>
                                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='col-lg-6'>
                                <Form.Item
                                    label="District"
                                    name="district_id_edit"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input District!',
                                        },
                                    ]}
                                >
                                    <Select className='select__width' onClick={() => districtFunction()} onChange={(e) => districtOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                        {districtName?.map((item, i) =>
                                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                        )}
                                    </Select>

                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Thana"
                                    name="thana_id_edit"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Thana!',
                                        },
                                    ]}
                                >
                                    <Select className='select__width' onClick={() => thanaFunction()} onChange={(e) => thanaOnChangeFunction(e)} allowClear placeholder="Select your Division" >
                                        {thanaName?.map((item, i) =>
                                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    label="Post Code"
                                    name="post_code_edit"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Post Code!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Item
                                    label="Detail Address"
                                    name="detail_address_edit"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Detail Address!',
                                        },
                                    ]}
                                >
                                    <Input className="form-control" />
                                </Form.Item>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Item
                                    label="Select a label for effective delivery"
                                    name="label_edit"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Label!',
                                        },
                                    ]}
                                >
                                    <Radio.Group size="large">
                                        <Radio.Button value="Home">Home</Radio.Button>
                                        <Radio.Button value="Office">Office</Radio.Button>
                                        <Radio.Button value="Others">Others</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Item
                                    name="status_edit"
                                    className="form-group form-box"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input First Name!',
                                        },
                                    ]}
                                >
                                    <Switch onClick={(e) => switchStatus(e)} checked={Number(statusId) === 1 ? true : false} checkedChildren="Active" unCheckedChildren="InActive" />
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn-md btn-theme address_change_btn">Submit</button>
                                {/* <p className='from__bottom_txt'>Don't have an account? <Link to="/login" className="thembo"> Login here</Link></p> */}
                            </div>
                        </div>
                    </Form>
                </Modal>
            </Container>
        </div>
    )
}

export default AddressBook