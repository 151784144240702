import React, { useEffect, useState } from 'react'
import { RiBrush4Fill } from "react-icons/ri";
import { VscChromeRestore } from "react-icons/vsc";
import axios from '../../helper/Api';
import { Tabs, Empty } from 'antd';
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

// import DateCountdown from "react-date-countdown-timer";
import data from "./sellerData.json"

import { imagesUrl } from '../../helper/Url';
import SellerBanner from "../../Components/SellerComponents/SellerBanner"
import SellerLogo from "../../Components/SellerComponents/SellerLogo"
import SellerTab from "../../Components/SellerComponents/SellerTab"

import DiscountImg from "../../assets/images/seller/discountImg.png"

function SellerThemOne(props) {
  const { TabPane } = Tabs;
  console.log(props)
  console.log(props?.allData?.seller_details)

  const { slug } = useParams()
  useEffect(() => {
    axios.get(`/frontend/public-api/top-seller-product/${slug}`)
      .then(res => {
        console.log(res, "999999999")
      })
  }, [])
  return (
    <div>
      <div className='seller_banner_area'>
        <Container>
          <Row>
            <Col lg={12}>
              <img className='img-fit banner_seller' src={imagesUrl + props?.allData?.seller_details?.seller_cover} alt="ok" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='seller_body_width'>
        <Container>
          <Row>
            <Col lg={12}>
              <div className='seller_logo'>
                <img className='img-fit' src={imagesUrl + props?.allData?.seller_details?.seller_logo} alt="ok" />
              </div>
              <div className='seller_name'>
                <h3 style={{ textTransform: 'capitalize' }}> {props?.allData?.seller_details?.shop_name} </h3>
                <h5> 389 Followers </h5>
                <p> 85% Positive Seller Ratings </p>
              </div>
              <button className='follow_btn'> <RiBrush4Fill /> Follow </button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='seller_body_width'>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Home Pages" key="1">
            <div >
              <img className='img-fit' src={DiscountImg} alt="" />
            </div>
            <div className='mt-4 d-none'>
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className='seller_profile__header'>
                      <h3> FlashSale : </h3>
                      {/* <DateCountdown
                        dateTo="December 31, 2022 00:00:00 GMT-5"
                        callback={() => alert("Hello")}
                      /> */}
                    </div>
                  </Col>
                  {/* // Data Statck  */}
                  <Col lg={12}>
                    <div className='three_catagori_box'>
                      <Row>
                        {data?.products.map((item, i) =>
                          <Col lg={3} className="d-flex align-items-stretch mt-3" key={i}>
                            <Link className='card' to="/product-details">
                              <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                <img className='img-fit' src={item.images} alt="" />
                                <div className='product_hone_box_text'>
                                  <p> {item.description}</p>
                                </div>
                                <div className='price__'> ৳ {item.price} </div>
                                <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                              </div>
                            </Link>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className='mt-4'>
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className='three_catagori_box'>
                      <p> <VscChromeRestore /> Just For You </p>
                      <Row>
                        {props?.allData?.products?.data?.length > 0 ? <>
                          {props?.allData?.products?.data?.map((item, i) =>
                            <Col lg={2} className="d-flex align-items-stretch mt-3" key={i}>
                              <Link className='card' to={`/product-details/${item?.slug}`}>
                                <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                  <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                  <div className='product_hone_box_text'>
                                  <p> {item?.active_translation[0]?.name?.substring(0, 50)}</p>
                                  </div>
                                  <div className='price__'>
                                    {item?.feature_item[0]?.discount_price > 0 ? <>
                                      ৳{item?.feature_item[0]?.discount_price}
                                      <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                    </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                  </div>
                                  <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                </div>
                              </Link>
                            </Col>
                          )}
                        </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

          </TabPane>
          <TabPane tab="All Products" key="2">
            <div className='mt-4'>
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className='three_catagori_box'>
                      {/* <p> <VscChromeRestore /> Just For You </p> */}
                      <Row>
                        {props?.allData?.products?.data?.length > 0 ? <>
                          {props?.allData?.products?.data?.map((item, i) =>
                            <Col lg={2} className="d-flex align-items-stretch mt-3" key={i}>
                              <Link className='card' to={`/product-details/${item?.slug}`}>
                                <div className='e_one_s_box mt-2' style={{ "padding": "8px" }}>
                                  <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                  <div className='product_hone_box_text'>
                                  <p> {item?.active_translation[0]?.name?.substring(0, 50)}</p>
                                  </div>
                                  <div className='price__'>
                                    {item?.feature_item[0]?.discount_price > 0 ? <>
                                      ৳{item?.feature_item[0]?.discount_price}
                                      <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                    </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                  </div>
                                  <div className='min__order'> <b>1 Pair</b> (Min. Order)  </div>
                                </div>
                              </Link>
                            </Col>
                          )}
                        </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </TabPane>
        </Tabs>
      </div>
      {/* <SellerBanner sellerDetails={props?.allData?.seller_details} /> */}
      {/* <SellerLogo sellerDetails={props?.allData?.seller_details} /> */}
      {/* <SellerTab sellerData={props?.allData?.products} /> */}
    </div>
  )
}

export default SellerThemOne