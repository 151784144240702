import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { VscChromeRestore } from "react-icons/vsc";

import axios from '../helper/Api';
import { imagesUrl } from '../helper/Url';
// Images
// import SingleImg from "../assets/images/m01.png"
import EmaiLogo01 from "../assets/images/l01.jpeg"
import EmaiLogo02 from "../assets/images/l02.jpeg"
import EmaiLogo03 from "../assets/images/l03.jpeg"

import SingleImg01 from "../assets/images/seller/b01.png"
import SingleImg02 from "../assets/images/seller/b02.png"
import SingleImg03 from "../assets/images/seller/b03.png"

function EoneMail() {
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get('/frontend/public-api/top-seller-homepage')
            .then(res => {
                console.log(res, "999999999")
                setData(res.data.data.top_sellers)
            })
    }, [])
    return (
        <div className='mt-4'>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='three_catagori_box'>
                            <p> <VscChromeRestore /> Eome Mail <Link to="/all-seller-pages"> View All </Link> </p>
                            <Row className='justify-content-center'>
                            {/* .slice(0, 4) */}
                                {data?.slice(0, 6).map((item, i) =>
                                    <Col key={i} md={3} lg={3} xl={2} className="">
                                        <Link to={`/seller/home/${item?.seller_unique_id}`}>
                                            <div className='e_one_s_box mt-2'>
                                                <img style={{height: '113px'}} className='img-fit' src={imagesUrl + item?.seller_thumbnail} alt="" />
                                                <img className='logo__mail' src={imagesUrl + item?.seller_logo} alt="" />
                                                <div className="e_one_s_box_text">
                                                    <h3>{item?.shop_name}</h3>
                                                    <p> Click Here </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                )}
                                {/* <Col md={3} lg={3} xl={2} className="">
                                    <Link to="/seller/theme-one">
                                        <div className='e_one_s_box mt-2'>
                                            <img className='img-fit' src={SingleImg01} alt="" />
                                            <img className='logo__mail' src={EmaiLogo02} alt="" />
                                            <div className="e_one_s_box_text">
                                                <h3>  M.M Foods Ltd  </h3>
                                                <p>  M.M Foods Ltd   </p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md={3} lg={3} xl={2} className="">
                                    <Link to="/seller/theme-three">
                                        <div className='e_one_s_box mt-2'>
                                            <img className='img-fit' src={SingleImg02} alt="" />
                                            <img className='logo__mail' src={EmaiLogo03} alt="" />
                                            <div className="e_one_s_box_text">
                                                <h3>  M.M Foods Ltd  </h3>
                                                <p>  M.M Foods Ltd   </p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md={3} lg={3} xl={2} className="">
                                    <Link to="/seller/theme-four">
                                        <div className='e_one_s_box mt-2'>
                                            <img className='img-fit' src={SingleImg03} alt="" />
                                            <img className='logo__mail' src={EmaiLogo01} alt="" />
                                            <div className="e_one_s_box_text">
                                                <h3>  M.M Foods Ltd  </h3>
                                                <p>  M.M Foods Ltd   </p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md={3} lg={3} xl={2} className="">
                                    <Link to="/seller/home">
                                        <div className='e_one_s_box mt-2'>
                                            <img className='img-fit' src={SingleImg01} alt="" />
                                            <img className='logo__mail' src={EmaiLogo02} alt="" />
                                            <div className="e_one_s_box_text">
                                                <h3>  M.M Foods Ltd  </h3>
                                                <p>  M.M Foods Ltd   </p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md={3} lg={3} xl={2} className="">
                                    <Link to="/seller/home">
                                        <div className='e_one_s_box mt-2'>
                                            <img className='img-fit' src={SingleImg02} alt="" />
                                            <img className='logo__mail' src={EmaiLogo03} alt="" />
                                            <div className="e_one_s_box_text">
                                                <h3>  M.M Foods Ltd  </h3>
                                                <p>  M.M Foods Ltd   </p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col> */}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EoneMail