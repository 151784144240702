import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from "react-router-dom";
import { Input, Select, Space, Dropdown, Menu } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { motion } from "framer-motion";

import { getCookie } from '../../helper/Cookies';

import TopHeaderAnimishon from "./TopHeaderAnimishon"
import MenuCategories from "./MenuCategories"

import CartComponents from "../CartComponents"
// Select Lang
import { withTranslation } from 'react-i18next';
import LanguageSelect from "../../LanguageSelect";

// Img
// import Logo from "../../assets/images/seller/slogo.png"

// icons
import { VscChevronDown, VscListSelection } from "react-icons/vsc";
const { Option } = Select;
const selectBefore = (
    <Select defaultValue="http://" className="select-before">
        <Option value="http://">Products</Option>
        <Option value="https://">Suppliers</Option>
    </Select>
);
const selectAfter = (
    <span> Search </span>
);
let usertoken = getCookie("token");
console.log(usertoken, "usertokenusertoken")
const menu = (
    <Menu
        items={[
            {
                key: '1',
                label: (
                    <div className="sub-menu-container">
                        <p> Welcome back! </p>
                        <Link to="login" style={{ color: "#fff" }}>
                            <button className='header_sing_in_btn'> Sing in </button>
                        </Link>
                        <button className='header_sing_in_btn header_sing_in_btnjoin'> Join Free </button>
                        <div className='sc-hd-ms-login-title'>
                            <span className="sc-hd-ms-login-title-line"></span>
                            Continue with:
                            <span className="sc-hd-ms-login-title-line"></span>
                        </div>
                    </div>
                ),
            }
        ]}
    />
);

// BiCaretUp
function SellerHeader() {
    const [isMouse, toggleMouse] = React.useState(false);
    const location = useLocation()
    var string = location.pathname.includes("seller");
    console.log(string, "string string stringstring")
    const toggleMouseMenu = () => {
        toggleMouse(!isMouse);
    };

    const [isMousecatagory, toggleMousecatagory] = React.useState(false);
    const toggleMouseMenuCatagori = () => {
        toggleMousecatagory(!isMousecatagory);
    };
    const subMenuAnimate = {
        enter: {
            opacity: 1,
            rotateX: 0,
            transition: {
                duration: 0.5
            },
            display: "block"
        },
        exit: {
            opacity: 0,
            rotateX: -15,
            transition: {
                duration: 0.5,
                delay: 0.3
            },
            transitionEnd: {
                display: "none"
            }
        }
    };
    return (
        <div className='header__area'>
            <TopHeaderAnimishon />
            <div className='hedaer_medial_area pt-2 pb-2'>
                <Container>
                    <Row>
                        <Col lg={2}>
                            {/* <Link to="/"> <img className='img-fit logo_header' src={Logo} alt="ok" /> </Link> */}
                            <Link className="animate-charcter" to="/"> Back To Eone </Link>
                        </Col>
                        <Col md={12} lg={7}>
                            <div className='header_search'>
                                <Space direction="vertical">
                                    <Input addonBefore={selectBefore} addonAfter={selectAfter} placeholder="What are you looking for..." />
                                </Space>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='header_right_area'>
                                <div className='header_right_area_item'>
                                    <div className='login-icon login-icon-padding login-icon-unsignavatar'></div>
                                    {usertoken?.length === 0 ? <>
                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            {/* <a onClick={(e) => e.preventDefault()}> */}
                                            <p className='login__h_'> Hello <br></br> <span className='down__icon'> Sign In <DownOutlined style={{ float: 'right', fontSize: '15px' }} /> </span>  </p>
                                            {/* </a> */}
                                        </Dropdown>
                                    </> : <>
                                    <h3> hhhh </h3>
                                    </>}
                                </div>{" "}
                                <div className='header_right_area_item header_right_area_item_order'>
                                    <div className='login-icon order-icon-unsignavatar'></div>
                                    <p>  Orders </p>
                                </div>
                                <CartComponents />

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='header_bottom_area'>
                <Container>
                    <Row>
                        <Col lg={2}>
                            <div className='header_b_left'>

                                <div className='header_right_area_item'>
                                    <motion.div
                                        className="menu-item"
                                        onMouseEnter={toggleMouseMenuCatagori}
                                        onMouseLeave={toggleMouseMenuCatagori}
                                    >
                                        <VscListSelection />
                                        <span> Categories </span>
                                        <VscChevronDown className='c_down_arrao' />
                                        <motion.div
                                            className="sub-menu"
                                            initial="exit"
                                            animate={isMousecatagory ? "enter" : "exit"}
                                            variants={subMenuAnimate}
                                        >
                                            <div className="sub-menu-background" />
                                            <div className="sub-menu-container">
                                                <MenuCategories />
                                            </div>
                                        </motion.div>
                                    </motion.div>

                                </div>{" "}
                            </div>
                        </Col>
                        <Col md={9} lg={7}>
                            <div className='header_b_midell'>
                                <ul>
                                    <li> <Link to="/"> Ready to Ship </Link> </li>
                                    {/* <li> <Link to="/"> Personal Protective Equipment </Link> </li> */}
                                    <li> <Link to="/"> Trade Shows </Link> </li>
                                    <li> <Link to="/"> Sell on Eone </Link> </li>
                                    <li> <Link to="/"> Help </Link> </li>
                                    <li> <Link to="/dashboard"> Dashboard01 </Link> </li>
                                    <li> <Link to="/dashboard-2"> Dashboard2 </Link> </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='header_b_midell'>
                                <ul>
                                    <li> <LanguageSelect /> </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default withTranslation()(SellerHeader)