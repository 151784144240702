import { createStore, compose } from "redux";
import rootReducer from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default initialState => {
    initialState = JSON.parse(window.localStorage.getItem('state')) || initialState;
    const store = createStore(
        rootReducer, 
        initialState,
        composeEnhancers()
    );
    store.subscribe(() => {
        const state = store.getState();
        console.log(state, "999999999999999999")
        window.localStorage.setItem('state', JSON.stringify(state));
    });
    return store;
}
