import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import { VscChromeRestore } from "react-icons/vsc";
import axios from '../helper/Api';
import LanguageContext from "../context/LanguageProvider";
import { imagesUrl } from '../helper/Url';
// Images
import SingleImg from "../assets/images/ts01.png"
import SingleImg2 from "../assets/images/ts02.png"
import SingleImg3 from "../assets/images/ts03.png"
import SingleImg4 from "../assets/images/ts04.png"
import SingleImg5 from "../assets/images/ts05.png"
import SingleImg6 from "../assets/images/ts06.png"
function ThreeCategoriesBox() {
    const { language } = useContext(LanguageContext);
    const [data, setData] = useState([]);
    const [newArrData, setNewArrData] = useState([]);
    const [topRankData, setTopRankData] = useState([]);

    useEffect(() => {
        newArriver()
        weeklyDeals()
        topRankedProduct()
    }, [language])

    const newArriver = () => {
        axios.get(`/frontend/public-api/new-arrival-products?active_language=${language}`)
            .then(res => {
                console.log(res)
                setData(res?.data?.data?.products?.data)
            })
    }

    const weeklyDeals = () => {
        axios.get(`/frontend/public-api/weekly-deals-products?active_language=${language}`)
            .then(res => {
                setNewArrData(res?.data?.data?.products?.data)
            })
    }

    const topRankedProduct = () => {
        axios.get(`/frontend/public-api/top-ranked-products?active_language=${language}`)
            .then(res => {
                setTopRankData(res?.data?.data?.products?.data)
            })
    }

    console.log(data, "data data")
    return (
        <div>
            <Container>
                <Row>
                    <Col lg={4}>
                        <div className='three_catagori_box'>
                            <p> <VscChromeRestore /> New Arrivals <Link to="/new-arrival"> View All </Link> </p>
                            <div className='three_catagori_box__full'>
                                {data?.length > 0 ? <>
                                    <Row>
                                        {data?.slice(0, 3).map((item, i) =>
                                            <Col key={i} lg={4}>
                                                <Link to={`/product-details/${item?.slug}`}>
                                                    <div className='three_catagori_single_images__all__s'>
                                                        <div className='three_catagori_single_images'>
                                                            <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                                        </div>
                                                        <div className='three_catagori_single_images_text'>
                                                            <h5>
                                                                {item?.feature_item[0]?.discount_price > 0 ? <>
                                                                    ৳{item?.feature_item[0]?.discount_price}
                                                                    <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                                                </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                                            </h5>
                                                            <h4> {item?.active_translation[0]?.name?.substring(0, 9)} </h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Col>
                                        )}
                                    </Row>
                                </> : <> </>}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='three_catagori_box'>
                            <p> <VscChromeRestore /> Weekly Deals <Link to="/weekly-deals"> View All </Link> </p>
                            <div className='three_catagori_box__full'>
                                {newArrData?.length > 0 ? <>
                                    <Row>
                                        {newArrData?.slice(0, 3).map((item, i) =>
                                            <Col key={i} lg={4}>
                                                <Link to={`/product-details/${item?.slug}`}>
                                                    <div className='three_catagori_single_images__all__s'>
                                                        <div className='three_catagori_single_images'>
                                                            <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                                        </div>
                                                        <div className='three_catagori_single_images_text'>
                                                            <h5>
                                                                {item?.feature_item[0]?.discount_price > 0 ? <>
                                                                    ৳{item?.feature_item[0]?.discount_price}
                                                                    <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                                                </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                                            </h5>
                                                            <h4> {item?.active_translation[0]?.name?.substring(0, 9)} </h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Col>
                                        )}
                                    </Row>
                                </> : <> </>}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='three_catagori_box'>
                            <p> <VscChromeRestore /> Top-ranked Products <Link to="/top-ranked-product"> View All </Link> </p>
                            <div className='three_catagori_box__full'>
                                {topRankData?.length > 0 ? <>
                                    <Row>
                                        {topRankData?.slice(0, 3).map((item, i) =>
                                            <Col key={i} lg={4}>
                                                <Link to={`/product-details/${item?.slug}`}>
                                                    <div className='three_catagori_single_images__all__s'>
                                                        <div className='three_catagori_single_images'>
                                                            <img className='img-fit' src={imagesUrl + item?.thumbnail_image} alt="" />
                                                        </div>
                                                        <div className='three_catagori_single_images_text'>
                                                            <h5>
                                                                {item?.feature_item[0]?.discount_price > 0 ? <>
                                                                    ৳{item?.feature_item[0]?.discount_price}
                                                                    <del style={{ fontSize: '12px', color: '#d51c22', marginLeft: '5px' }}> ৳{item?.feature_item[0]?.selling_price} </del>
                                                                </> : <>  ৳{item?.feature_item[0]?.selling_price} </>}
                                                            </h5>
                                                            <h4> {item?.active_translation[0]?.name?.substring(0, 9)} </h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Col>
                                        )}
                                    </Row>
                                </> : <> </>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ThreeCategoriesBox