export const ActionTypes = {
  SET_PRODUCTS: "PRODUCT",
  ADD_CART: "ADD_CART",
  ADD_ITEM_CART: "ADD_ITEM_CART",
  INCREASE: "INCREASE",
  DECREASE: "DECREASE",
  GET_TOTALS: "GET_TOTALS",
  BUY_NOW: "BUY_NOW",
  REMOVE: "REMOVE",
  SEARCH_DATA: "SEARCH_DATA",
};
