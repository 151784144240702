
import { combineReducers } from 'redux';
import {ProductsReducer} from "./ProductsReducers";
import {CartReducer} from "./CartReducer";
import {SearchProductsReducer} from "./SearchDataReducers";
const rootReducer = combineReducers({
    allProducts: ProductsReducer,
    cartItems: CartReducer,
    searchAllData: SearchProductsReducer,
});
export default rootReducer;