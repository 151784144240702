import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SellerBannerImg from "../../assets/images/seller/slogo.png" 
import { RiBrush4Fill } from "react-icons/ri";
import { imagesUrl } from '../../helper/Url';

function SellerLogo(props) {
  console.log(props?.sellerDetails)
  return (
    <div className='seller_body_width'>
      <Container>
        <Row>
            <Col lg={12}>
              <div className='seller_logo'>
                 <img className='img-fit' src={imagesUrl + props?.sellerDetails?.seller_logo} alt="ok" />
              </div>
              <div className='seller_name'>
                    <h3> {props?.sellerDetails?.shop_name} </h3> 
                    <h5> 389 Followers </h5>
                    <p> 85% Positive Seller Ratings </p>
              </div>
             <button className='follow_btn'> <RiBrush4Fill /> Follow </button>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SellerLogo