import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import MUIDataTable from "mui-datatables";
import { Link } from 'react-router-dom';
import ActivePurches from "../Components/Dashboard2Chart/ActivePurches"
import MonthlyStatus from "../Components/Dashboard2Chart/MonthlyStatus"
import { Empty } from 'antd';
import axios from '../helper/Api';
import { imagesUrl } from '../helper/Url';
import { getCookie } from '../helper/Cookies';
import Badge from 'react-bootstrap/Badge';

function Dashboard2() {
  let usertoken = getCookie("token");
  const [userFullDetails, setUserFullDetails] = useState([]);
  const [userAddress, setAddress] = useState([]);
  const [recentOrder, setRecentOrder] = useState([]);
  const [repoartData, setRepoartData] = useState([]);
  const columns = [
    {
      name: "order",
      label: "Order #",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "placedon",
      label: "Placed On",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "items",
      label: "Items",
      options: {
        customBodyRender: () => {
          return (
            <img style={{ width: "50px", height: "50px" }} src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80" alt="" />
          )
        }
      }
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Manage",
      label: "Total",
      options: {
        filter: true,
        sort: false,
      }
    },
  ];
  const data = [
    { order: "629348309910456", placedon: "03/07/2022", items: "Yonkers", total: "৳ 340" },
    { order: "628999369910456", placedon: "25/06/2022", items: "Yonkers", total: "৳ 86" },
    { order: "628995180110456", placedon: "25/06/2022", items: "Yonkers", total: "৳ 100" }
  ]


  useEffect(() => {
    // Country List
    axios.get(`/customer/order/recent-order`, { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        console.log(res)
        setRecentOrder(res?.data?.data?.recent_orders)
      })
    axios.get(`/customer/order/dashboard-statistical-data`, { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        setRepoartData(res?.data?.data)
      })

    axios.get("/customer/get-address", { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        setAddress(res?.data?.data?.customerProfileAddressData)
      })

    axios.get("/customer/get-user-details", { headers: { 'Authorization': `bearer ${usertoken}` } })
      .then(res => {
        setUserFullDetails(res?.data?.data?.user_details)
      })

  }, []);
  const options = {
    filterType: 'checkbox',
    // responsive: 'stacked',
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30, 50, 100]
  }
  console.log(repoartData)
  return (
    <div className='dashboard_area'>
      <Container>
        <Row>
          <Col lg="12">
            <div className='banner banner-color'>
              {/* <Row> */}
              <Col lg={2}>
                <img src="https://www.spruko.com/demo/dashlot/Dashlot/assets/images/svg/new_message.svg" alt="Okk" className="image_" />
              </Col>
              <Col className='page-content' lg={7}>
                <h3 class="mb-1">Welcome back! Seller Dashboard</h3>
                <p class="mb-0 fs-16">Want to be the first to know about Seller Dashboard updates? Subscribe Now</p>
              </Col>
              <Col className='text-right d-flex d-block' lg={3}>
                <button className='btn btn-success mr-2'> Skip </button>
                <button className='btn btn-primary'> Subscribe Now </button>
              </Col>
              {/* </Row> */}
            </div>
            <Row>
              <Col lg={12}>
                <div className='dashBordMenu_box'>
                  <ul>
                    <li> <Link to="/user-profile"> Manage My Account </Link> </li>
                    <li> <Link to="/my-order"> My Orders </Link> </li>
                    <li> <Link to="/address-book"> Address Book </Link> </li>
                    <li> <Link to="/wish-list"> My Wishlist & Followed Stores </Link> </li>
                    <li> <Link to="/"> My Reviews </Link> </li>
                    <li> <Link to="/"> My Returns & Cancellations </Link> </li>
                    <li> <Link to="/"> Logout </Link> </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3}>
                <div className='active__box_area'>
                  <div className='active__box_text'>
                    <p> WishList </p>
                    <h3> {repoartData?.total_wishlisted_product} </h3>
                    <p className="mb-0 text-muted">
                      <span className="text-success" style={{ marginRight: '10px' }}>(+0.35%)
                      </span>Increase
                    </p>
                  </div>
                  <div className='active__box_chrt'><ActivePurches /></div>
                </div>
              </Col>
              <Col lg={3}>
                <div className='active__box_area'>
                  <div className='active__box_text'>
                    <p> Pendding Orders </p>
                    <h3> {repoartData?.total_pending_order} </h3>
                    <p className="mb-0 text-muted">
                      <span className="text-success" style={{ marginRight: '10px' }}>(+0.35%)
                      </span>Increase
                    </p>
                  </div>
                  <div className='active__box_chrt'><ActivePurches /></div>
                </div>
              </Col>
              <Col lg={3}>
                <div className='active__box_area'>
                  <div className='active__box_text'>
                    <p> Delivered Orders </p>
                    <h3> {repoartData?.total_delivered_order} </h3>
                    <p className="mb-0 text-muted">
                      <span className="text-success" style={{ marginRight: '10px' }}>(+0.35%)
                      </span>Increase
                    </p>
                  </div>
                  <div className='active__box_chrt'><ActivePurches /></div>
                </div>
              </Col>
              <Col lg={3}>
                <div className='active__box_area'>
                  <div className='active__box_text'>
                    <p> Total Orders </p>
                    <h3> {repoartData?.total_order} </h3>
                    <p className="mb-0 text-muted">
                      <span className="text-success" style={{ marginRight: '10px' }}>(+0.35%)
                      </span>Increase
                    </p>
                  </div>
                  <div className='active__box_chrt'><ActivePurches /></div>
                </div>
              </Col>
              <Col lg={12}>
                <div className='active__box_area'>
                  <div className='active__box_text' style={{ width: "100%" }}>
                    <p> Address Book </p>
                    <div className='d_address_box'>
                      <span> DEFAULT SHIPPING ADDRESS </span>
                      <h5> {userFullDetails?.first_name} {userFullDetails?.middle_name} {userFullDetails?.last_name} </h5>
                      <p> {userAddress?.detail_address} {userFullDetails?.phone_number} </p>
                    </div>
                    <div className='d_address_box'>
                      <h5> {userFullDetails?.first_name} {userFullDetails?.middle_name} {userFullDetails?.last_name} </h5>
                      <h5>Customer Name </h5>
                      <p> {userAddress?.detail_address} {userFullDetails?.phone_number} </p>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col lg={6}>
                <div className='active__box_area'>
                  <MonthlyStatus />
                </div>
              </Col> */}
              <Col lg={12}>
                <div className='active__box_area active__box_area__table ' style={{ padding: "0px" }}>
                  {/* <MUIDataTable
                    className="mui_table_dashboard"
                    title={"Recent Orders"}
                    data={data}
                    columns={columns}
                    options={options}
                  /> */}
                  {recentOrder?.length > 0 ? <>
                    <div className="card-block theme-form">
                      <h5 style={{paddingTop: "20px"}} className='add_to_cart__title'>Recent Orders</h5>
                      <div className="table-responsive">
                        <table className="table table-hover m-b-0">
                          <thead>
                            <tr>
                              <th>Sl</th>
                              <th>Name</th>
                              <th>Images</th>
                              <th>Quantity</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentOrder?.map((item, i) =>
                              <tr key={i} >
                                <td>
                                  {i + 1}
                                </td>
                                <td > {item?.product?.active_translation[0]?.name} </td>
                                <td><img src={imagesUrl + item?.product_item?.image_path} alt="" className="img-fluid img__width" /></td> 
                                <td > {item?.quantity} </td>
                                <td > {item?.total_payable_amount} </td>
                                {/* <td><img src={imagesUrl + item?.product_item?.image_path} alt="" className="img-fluid img__width" /></td> */}
                                {/* <td> <Badge bg="primary">{item?.total_quantity}</Badge></td>
                                <td>৳ {item?.total_item_amount}</td>
                                <td>
                                </td> */}

                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Dashboard2