import { ActionTypes } from "../actions/action-types";
import { toast } from "react-toastify";
const INIT_STATE = {
    carts: [],
    buyNowProduct: [],
    totalAmount: 0,
    totalCount: 0,
    totalItem: 0,
};

export const CartReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        // CARD ITEM ADD
        case ActionTypes.ADD_ITEM_CART:
            return { ...state, carts: action.payload }

        // CARD ITEM ADD
        case ActionTypes.BUY_NOW:
            return { ...state, buyNowProduct: action.payload }

        // CARD ADD
        case ActionTypes.ADD_CART:
            const check = state.carts.find((item) =>
                item.id === action.payload.id ? true : false
            );
            if (check) {
                toast.clearWaitingQueue();
                toast.error("Products Allready Exis...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return {
                    ...state
                }
            } else {
                // const temp = { ...action.payload, qnty: 1 }
                const temp = action.payload.qnty > 1 ? { ...action.payload } : { ...action.payload, qnty: 1 }
                console.log(temp, "temp temp")
                const totalitem = state.carts?.length + 1
                // const totalAmount5555 = (state.carts?.length > 0 ? state.carts?.length + 1 : 0);
                // const totalAmount = ((temp?.discount_price > 0) ? temp?.discount_price : temp?.selling_price) * temp?.qnty;
                toast.success("Welcome...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return {
                    ...state,
                    carts: [...state.carts, temp],
                    totalItem: totalitem,
                    // totalAmount: totalAmount,

                }
            }

        // TOTRAL COUNT
        case ActionTypes.GET_TOTALS:
            let totalAmount = state?.carts?.reduce(
                (cartTotal, cartItem) => {
                    console.log(cartTotal)
                    const { quantity } = cartItem;
                    const itemTotal = ((cartItem?.product_item?.discount_price > 0) ? cartItem?.product_item?.discount_price : cartItem?.product_item?.selling_price) * quantity;
                    console.log(itemTotal, "itemTotal itemTotal")
                    cartTotal.totalAmount += itemTotal;
                    return cartTotal;
                },
                {
                    totalAmount: 0
                }
            )
            // console.log({...state, totalAmount: totalAmount})
            // totalAmount = parseFloat(totalAmount);
        return { ...state, totalAmount: totalAmount };

        // ITEM INCRIMENT
        case ActionTypes.INCREASE:
            console.log(state.carts, "state.carts state.carts")
            console.log(action.payload, "state.carts state.carts")
            let tempCartInc = state.carts.map((cartItem) => {
                if (cartItem.product_id === action.payload) {
                    return { ...cartItem, qnty: cartItem.quantity += 1 };
                }
                return cartItem;
            });
            console.log(tempCartInc, "tempCartInc")
            return { ...state, carts: tempCartInc };

        // ITEM DECRIMENT
        case ActionTypes.DECREASE:
            const totalitem = state.carts?.length - 1
            let tempCartDec = state.carts?.map((cartItem) => {
                if (cartItem.product_id === action.payload) {
                    return { ...cartItem, qnty: cartItem.quantity - 1 };
                }
                return cartItem;
            })
                .filter((cartItem) => cartItem.quantity !== 0);
            return { ...state, carts: tempCartDec, totalItem: totalitem };

        // ITEM REMOVE
        case ActionTypes.REMOVE:
            return {
                ...state,
                carts: state.carts.filter((cartItem) => cartItem.id !== action.payload),
            };

        default:
            return state
    }

}