import React from 'react'
import MUIDataTable from "mui-datatables";
const columns = [
    {
        name: "order",
        label: "Full name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "placedon",
        label: "Address",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "items",
        label: "Region",
        options: {
            customBodyRender: () => {
                return (
                    <img style={{width: "50px", height: "50px"}} src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80" alt="" />
                )
            }
        }
    },
    {
        name: "total",
        label: "Phone Number",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "process",
        label: "Process",
        options: {
            customBodyRender: () => {
                return (
                    <button className='edit__btn'> Edit</button>
                )
            }
        }
    },
];

function AddressBook() {
    const data = [
        { order: "629348309910456", placedon: "03/07/2022", items: "Yonkers", total: "৳ 340" },
        { order: "628999369910456", placedon: "25/06/2022", items: "Yonkers", total: "৳ 86" },
        { order: "628995180110456", placedon: "25/06/2022", items: "Yonkers", total: "৳ 100" }
    ]

    const options = {
        filterType: 'checkbox',
        // responsive: 'stacked',
        filter: false,
        download: false,
        print: false,
        selectableRows: "none",
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30, 50, 100]
    }
    return (
        <div>
            <MUIDataTable
                title={"Address Book"}
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    )
}

export default AddressBook